import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { FileDescriptionModel } from '../../models/requests/file-description.model';
import * as hash from 'js-sha256';
import { TopicModel } from '../../models/requests/topic.model';
import { HelperService } from '../../services/helper.service';

@Component({
  selector: 'app-file-loader',
  templateUrl: './file-loader.component.html',
  styleUrls: ['./file-loader.component.scss']
})
export class FileLoaderComponent implements OnInit {

  @Input() maxFileSize: number;
  @Input() maxFilesSize: number;
  @Input() uploadedFiles: FileDescriptionModel[];
  @Input() limits: TopicModel;

  @Output() loadedEvent = new EventEmitter<FileDescriptionModel>();
  @Output() maxSingleSizeEvent = new EventEmitter<boolean>();
  @Output() isDuplicateEvent = new EventEmitter();
  @Output() spinnerEvent = new EventEmitter<number>();

  @ViewChild('files', { static: false }) files: ElementRef;

  constructor(
    private apiService: ApiService,
    private helperService: HelperService
  ) { }

  ngOnInit() {
  }

  onFileSelected() {
    const files = this.files.nativeElement.files;
    let totalSize = 0;
    this.uploadedFiles.map((item) => totalSize += item.size);

    for (let i = 0; i < files.length; i++) {
      totalSize += files[i].size;
    }

    if (totalSize > +this.limits.max_total_file_size && (this.uploadedFiles.length >= 1 || files.length > 1)) {
      this.helperService.alert(`Размер файлов не должен превышать ${Math.round(
        +(this.limits.max_total_file_size / 1048576).toFixed(3)
      )} Мб.`);
    } else {
      if ((files.length + this.uploadedFiles.length) <= +this.limits.max_amount_of_files) {
        for (let i = 0; i < files.length; i++) {
          const reader = new FileReader();
          reader.readAsArrayBuffer(files[i]);
          reader.onload = () => {
            if (files[i].size < this.limits.max_single_file_size) {
              const sha256_hash = hash.sha256(reader.result);

              if (!this.uploadedFiles.some((item) => item.hash_sha256 === sha256_hash)) {
                this.uploadFile(files[i], sha256_hash, files.length + this.uploadedFiles.length);
              } else {
                this.helperService.alert(`Файл ${files[i].name} уже прикреплен`);
              }
            } else {
              this.helperService.alert(`Размер файла не должен превышать ${Math.round(
                +(this.limits.max_single_file_size / 1048576).toFixed(3)
              )} Мб.`);
            }
          };
        }
      } else {
        this.helperService.alert(`Количество файлов не должно быть больше ${this.limits.max_amount_of_files} шт.`);
      }
    }
  }

  uploadFile(file: File, sha256: string, filesLength: number) {
    this.spinnerEvent.emit(filesLength);

    const formData = new FormData();
    formData.append('file', file, file.name);

    this.apiService.uploadFile(formData).subscribe((loadedFile: FileDescriptionModel) => {
      loadedFile.filename = file.name;
      loadedFile.size = file.size;
      loadedFile.hash_sha256 = sha256;
      this.loadedEvent.emit(loadedFile);
    });
  }
}
