<div
  class="form__download"
  (dragover)="onFileDropped($event)"
  (drop)="onFileSelectDrop($event)">
  <div class="form__download-container">
    <span class="form__download-title">{{ title }}</span>
    <button
      *ngIf="photo !== ''"
      class="btn btn--close"
      type="button"
      (click)="deleteImage()">
      <svg height="10" viewBox="0 0 25 25" width="10" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fill-rule="evenodd" stroke="#323A3F" stroke-linecap="round" stroke-opacity=".697">
          <path d="M.688.688l23.625 23.625M24.313.688L.688 24.313"></path>
        </g>
      </svg>
    </button>
  </div>
  <div class="form__download-box error">
    <label class="form__download-label">
      <input #imageFile
             (change)="onFileChange();imageFile.value=''"
             class="form__download-input"
             type="file" name="first_page_photo"
             accept=".jpg,.jpeg,.png,.gif,.bmp,.tiff">
    </label>
    <span class="form__download-text" *ngIf="photo === ''">
      Загрузите файл <br/> или перетащите их мышью
      <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27">
        <path fill="none" fill-rule="evenodd" stroke="#81898E"
              d="M21.006 9.166h-1.4a2.213 2.213 0 0 1-1.857-1.02l-.13-.2c-.505-.781-1.73-1.446-2.663-1.446h-2.912c-.929 0-2.158.666-2.663 1.446l-.13.2a2.213 2.213 0 0 1-1.857 1.02h-1.4c-.825 0-1.494.672-1.494 1.502v9.662a1.5 1.5 0 0 0 1.497 1.502h15.006c.828 0 1.497-.671 1.497-1.502v-9.662c0-.83-.67-1.502-1.494-1.502zm-4.138 5.772a3.36 3.36 0 1 1-6.719 0 3.36 3.36 0 0 1 6.72 0z"/>
      </svg>
    </span>
    <div class="form__download-inner">
      <img class="form__download-img" [src]="photo" alt="">
    </div>
  </div>
</div>
