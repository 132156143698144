import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { debounceTime, delay, takeUntil, tap } from 'rxjs/operators';
import { ProlongationApiService } from '../../services/prolongation-api.service';
import { WebsocketService } from '../../services/websocket.service';
import { SmsService } from '../../services/sms.service';
import { ErrorService } from '../../services/error.service';
import { fromEvent, merge, Observable, of, Subject } from 'rxjs';
import {
  ProlongationWebsocketResponseModel
} from '../../models/prolongation/prolongation-websocket-response.model';
import {
  ProlongationConfirmationModel
} from '../../models/prolongation/prolongation-confirmation.model';
import { HelperService } from '../../services/helper.service';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnDestroy, AfterViewInit {

  @Input() confirmationModel: ProlongationConfirmationModel;
  @Input() buttonTitle: string;

  @Output() confirmed = new EventEmitter<boolean>();
  @Output() delay = new EventEmitter<void>();
  @Output() waiter = new EventEmitter<{ status: boolean, event: string }>();
  @Output() errorEvent = new EventEmitter<any>();

  @ViewChild('resend', {static: false}) resendButton: ElementRef;

  ngUnsubscribe$ = new Subject();
  isShowedSpinner = false;
  code: string;
  serverError: string;
  isDisabled = false;
  timerForConfirmationResponse = 105000;
  private takeProlongation$: Observable<any>;

  ngAfterViewInit(): void {
    fromEvent(this.resendButton.nativeElement, 'click')
      .pipe(
        takeUntil(this.ngUnsubscribe$),
        tap(() => {
          this.resend();
          this.isDisabled = true;
        }),
        debounceTime(15000))
      .subscribe(() => this.isDisabled = false);
  }

  constructor(
    private helperService: HelperService,
    private prolongationService: ProlongationApiService,
    private socket: WebsocketService,
    private errorService: ErrorService,
    public smsService: SmsService,
  ) {
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next('');
    this.ngUnsubscribe$.complete();
  }

  preventAlpha($event: KeyboardEvent): boolean {
    return this.helperService.preventAlpha($event);
  }

  /**
   * Отправяет запрос на проверку правильности введенного кода
   * @return {void}
   */
  checkConfirmation() {
    this.prolongationService.smsConfirmation(this.confirmationModel.request_id, this.code)
      .then((res: any) => {
        this.code = '';

        if (res.error) {
          this.errorEvent.emit(res.error);
          this.serverError = this.errorService.getHttpErrorMsg(res).toString();
        } else {
          this.serverError = '';
          this.isShowedSpinner = true;
          this.waiter.emit({
            status: true,
            event: 'main',
          });

          this.takeProlongation$ = this.socket.createObservableSocket();

          const timer$ = of({some: true}).pipe(delay(this.timerForConfirmationResponse));
          merge(this.takeProlongation$, timer$).subscribe((data: ProlongationWebsocketResponseModel | { some: boolean }) => {
            if (data['some']) {
              this.delay.emit();
              return;
            }

            if (typeof data === 'string') {
              const result: ProlongationWebsocketResponseModel = JSON.parse(String(data));
              if (result.type === 'CREDIT_PROLONGATION_INIT' || result.type === 'LOAN_LIMIT_TRANSH' || result.type === 'AUTH_RESPONSE') {
                if (result.success) {
                  this.confirmed.emit(true);
                } else {
                  this.confirmed.emit(false);
                }
              }
            }
          });
        }
      });
  }

  /**
   * Отправяет запрос на повторную отправку смс кода
   * @return {void}
   */
  resend() {
    this.waiter.emit({
      status: true,
      event: 'resend',
    });

    this.prolongationService.smsResend(this.confirmationModel.request_id)
      .finally(() => {
        this.waiter.emit({
          status: false,
          event: 'resend',
        });
      });
  }
}
