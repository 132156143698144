import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { BusinessProcessLogService } from '../../services/business-process-log.service';
import { AccountClosureService } from '../../services/account-closure/account-closure.service';
import { AuthService } from '../../services/auth/auth.service';
import { UserService } from '../../services/auth/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss']
})
export class AppHeaderComponent implements OnInit, OnDestroy {

  @ViewChild('dropMenuElem', {static: false}) dropMenuElem: ElementRef;

  isActiveRequest: boolean;
  isEsingRequired: boolean;
  userBrands: string[];
  isOpenDropMenu = false;
  showAccountClosure = false;

  private unsubscribeObserver$: Subject<void> = new Subject();

  constructor(
    private router: Router,
    private authService: AuthService,
    private userService: UserService,
    private bpLogService: BusinessProcessLogService,
    private accountClosureService: AccountClosureService
  ) {
  }

  ngOnInit() {
    this.getCurrentBrands();
    this.isEsingRequired = this.userService.userInfo.is_esign_required;

    this.authService.$hasPendingPdp.pipe(takeUntil(this.unsubscribeObserver$))
      .subscribe(status => this.isActiveRequest = status);

    // this.accountClosureService.getAppFeature().subscribe((feature: AppFeatureModel) => {
    //   this.showAccountClosure = Object.values(feature).some((item) => item === true);
    // });
  }

  ngOnDestroy() {
    this.unsubscribeObserver$.next();
    this.unsubscribeObserver$.complete();
  }

  getCurrentBrands() {
    this.userBrands = this.authService.userBrands;
  }

  isInactive(url: string, exact = false): boolean {
    return !this.router.isActive(url, exact);
  }

  logout() {
    if (this.bpLogService.dealId) {
      this.bpLogService.sendBpLogEvent('BP_END', this.bpLogService.activeStep);
    }

    this.authService.logout(true);
  }

  goToCredits() {
    const url = '/credits';
    this.router.navigate([url]);
  }

  dropMenu() {
    // this.accountClosureService.getAppFeature().subscribe((feature: AppFeatureModel) => {
    //   this.showAccountClosure = Object.values(feature).some((item) => item === true);
    // });

    this.isOpenDropMenu = !this.isOpenDropMenu;
  }

  @HostListener('document:click', ['$event'])
  resolveClickFromOutside(event: MouseEvent) {
    const isClickedInside = this.dropMenuElem.nativeElement.contains(event.target as HTMLElement);
    if (!isClickedInside) {
      this.isOpenDropMenu = false;
    }
  }
}
