import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class CaptchaService {

  constructor(private readonly http: HttpClient) {}

  /**
   * Отправляет запрос подтверждения капчи
   * @param token - токен полученный от капчи
   * @param captchaId - идентификатор капчи на сервере
   */
  confirmCaptchaToken(token: string, captchaId: string) {
    const url = '/auth/captcha';

    return this.http.post(url, { id: captchaId, captcha_token: token });
  }
}
