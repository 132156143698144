<div class="warning" *ngIf="currentScreen != 'NONE'">
  <div class="warning__wrapper">
    <div class="warning__logo">
      <mat-icon style="width:312px; height: 48px" svgIcon="logo-auto"></mat-icon>
    </div>
    <div class="warning__content">
      <div *ngIf="dataValue.icon" class="warning__icon">
        <mat-icon style="width:150px; height: 150px" [svgIcon]="dataValue.icon"></mat-icon>
      </div>
      <div class="warning__info">
        <div *ngIf="dataValue.title" class="warning__info-title">{{ dataValue.title }}</div>
        <div *ngIf="dataValue.dscrOne" class="warning__info-dscr">{{ dataValue.dscrOne }}</div>
        <div *ngIf="dataValue.dscrTwo" class="warning__info-dscr">{{ dataValue.dscrTwo }}</div>
      </div>
      <div class="warning__buttons">
        <ng-container *ngIf="dataValue.hasButton">
          <button
            class="btn"
            [ngClass]="dataValue.btnClass"
            (click)="performAction()"
          >
            {{ dataValue.btnText }}
          </button>
        </ng-container>
      </div>
    </div>
    <div class="warning__phone">
      <mat-icon svgIcon="phone"></mat-icon>
      <span>8 (800) 770 - 77 - 49</span>
    </div>
  </div>
</div>

