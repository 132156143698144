import { EventsInterface, ValidateRule } from '../../services/callback-validator.service';

export class PromoEventModel {
  constructor(
    public event?: string,
    public rule?: ValidateRule,
    public isCompleted?: boolean
  ) {
  }
}
