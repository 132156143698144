<div class="message-auth">
  <div *ngIf="!isShowedSpinner"
       class="message-auth__icon">
    <mat-icon style="width: 190px; height: 190px;" svgIcon="smscode"></mat-icon>
  </div>
  <app-spinner *ngIf="isShowedSpinner" [type]="'big'"></app-spinner>

  <ng-container *ngIf="!isShowedSpinner">
    <span class="message-auth__heading">Введите код, который мы прислали на номер
      <span *ngIf="confirmationModel?.phone">{{ confirmationModel?.phone }}</span>
    </span>
    <div class="message-auth__code">
      <div class="underline-dashed center length length6">
        <input class="form__input form__input--indent input-4"
               name="number"
               #number="ngModel"
               maxlength="6"
               minlength="6"
               required=""
               [(ngModel)]="code"
               (keydown)="preventAlpha($event)"
        >
        <mat-icon class="underline-short"  style="width: 132px;" svgIcon="input-numbers-6"></mat-icon>
      </div>
      <div *ngIf="serverError" class="error">
        {{ serverError }}
      </div>
    </div>
    <div class="message-auth__buttons">
      <button class="btn btn-main"
              (click)="checkConfirmation()"
              [disabled]="number.invalid"
      >
        {{ buttonTitle }}
      </button>
      <button class="btn btn-secondary"
              #resend
              [disabled]="isDisabled || smsService.resendTimeout > 0"
      >
        {{ smsService.resendBtnText }}
      </button>
    </div>
  </ng-container>
</div>
