export class PaymentFeeModel {
  constructor(
    public autopayment_processing_disclaimer?: string,
    public disclaimer?: any,
    public fee?: number,
    public payment_processing_disclaimer?: string
  ) {}
}



