import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { RecurringPaymentModel } from '../../models/payments/recurring-payment.model';

@Injectable()
export class PaymentsContainerService {

  private recurringSource = new Subject<RecurringPaymentModel>();
  private cardsSource = new Subject();

  recurringUpdate$ = this.recurringSource.asObservable();
  cardsUpdate$ = this.cardsSource.asObservable();

  updateRecurringForm(recurring: RecurringPaymentModel) {
    this.recurringSource.next(recurring);
  }

  updateCardsForm() {
    this.cardsSource.next(true);
  }
}
