export class CardConditionsModel {
  constructor(
    public id?: number,
    public conditions_page_link?: string,
    public monthly_payment?: number,
    public insurance_limit?: number,
    public enable_by_default?: boolean,
    public key_document_link?: string
  ) {}
}
