import { Component, Input } from '@angular/core';

@Component({
  selector: 'money-splitter',
  templateUrl: 'money-splitter.component.html',
  styleUrls: ['money-splitter.component.scss']
})
export class MoneySplitterComponent {
  @Input() amount: string;

  get _amount() {
    return this.amount ? this.amount.toString().split('.')[0] : ['0'];
  }

  get remainder() {
    const numbers: string[] = this.amount ? this.amount.toString().split('.') : [];
    return numbers.length > 1 ? numbers[1] + ' ' : '';
  }
}
