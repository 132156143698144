import { Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { BusinessProcessLogService } from '../../services/business-process-log.service';
import { CardInsuranceService } from '../../services/payments/card-insurance.service';

@Component({
  selector: 'insurance-slider',
  templateUrl: './insurance-slider.component.html',
  styleUrls: ['./insurance-slider.component.scss'],
  animations: [
    trigger('fade', [
      state('start', style({
        opacity: '0.6'
      })),
      state('end', style({
        opacity: '0'
      })),
      transition('start <=> end', animate(500))
    ])
  ]
})
export class InsuranceSliderComponent implements OnInit {

  @ViewChildren('slideImg') slideImgs: QueryList<ElementRef>;

  @Input() insurance_limit: number;
  @Input() amount: number;
  @Output() closeSliderEvent = new EventEmitter();
  @Output() cardProtectionEvent = new EventEmitter();

  currentSlide = 0;
  fadeState = 'start';

  slideConfig = {
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    draggable: true,
    dots: true,
    variableWidth: true,
    focusOnSelect: true,
    arrows: true
  };

  slides = []

  constructor(
    private cardInsuranceService: CardInsuranceService,
    private bpLogService: BusinessProcessLogService
  ) {
  }

  ngOnInit() {
    this.slides = [
      {
        src: '/assets/images/gif/shield.gif',
        caption: 'Страхуем сразу все карты',
        description: 'Полис работает сразу для всех ваших карт. Мы возвратим до ' + this.insurance_limit + ' ₽ в случае кражи денег с карты'
      },
      {
        src: '/assets/images/gif/laptop.gif',
        caption: 'Страхуем от кражи данных',
        description: 'Деньги в безопасности, даже если мошенники узнали данные через банкомат, терминал или интернет'
      },
      {
        src: '/assets/images/gif/stolen.gif',
        caption: 'Страхуем от кражи карт и наличных',
        description: 'Вы вернете деньги, даже если их похитят в момент снятия наличных в банкомате'
      },
      {
        src: '/assets/images/gif/atm.gif',
        caption: 'Заплатим за перевыпуск карты',
        description: 'Если карта испортится или потеряется, мы заплатим вашему банку за выпуск новой'
      }
    ];

    this.slides[0].description = 'Полис работает сразу для всех ваших карт. Мы возвратим до ' + (this.insurance_limit / 100)
      + ' ₽ в случае кражи денег с карты';
  }

  close() {
    this.bpLogService.sendBpLogEvent('NEXT_STEP', 'CARD_INSURANCE_PAYMENT_SCREEN');

    this.closeSliderEvent.emit();
  }

  afterChange(e: any) {
    this.currentSlide = e.currentSlide;
    const slideImages = this.slideImgs.toArray();
    const index = this.currentSlide;

    if (slideImages.length && slideImages[index]) {
      slideImages[index].nativeElement.src = '';
      slideImages[index].nativeElement.src = this.slides[index].src + '?a=' + Math.random();
    }
  }

  changeFadeState() {
    this.fadeState = 'end';
    setTimeout(() => {
      this.fadeState = 'start';
    }, 500);
  }

  getSlideCaption() {
    const index = this.currentSlide;
    return this.slides[index].caption;
  }

  getSlideDiscription() {
    const index = this.currentSlide;
    return this.slides[index].description;
  }

  enabledCardProtection() {
    this.bpLogService.sendBpLogEvent('NEXT_STEP', 'CARD_INSURANCE_PAYMENT_SCREEN');

    this.cardInsuranceService.protection = true;
    this.closeSliderEvent.emit();
  }

  isDisabled() {
    return this.amount <= 36000;
  }
}
