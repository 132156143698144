import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable, Subscriber } from 'rxjs';
import { AuthService } from './auth/auth.service';
import { LocalStorageConstant } from '../constants/local-storage.constant';
import { ServerTypeEnum } from '../models/storage';


@Injectable()
export class WebsocketService {

  constructor(private authService: AuthService) {}

  private ws: WebSocket;
  private observer: Observable<any>;

  createObservableSocket(url: string  = environment.socketHost): Observable<any> {
    if (this.observer) {
      return this.observer;
    }

    switch (localStorage.getItem(LocalStorageConstant.SERVER_TYPE) ?? ServerTypeEnum.DEMO) {
      case ServerTypeEnum.DEMO:
        url = environment.socketHostDemo;
        break;
      case ServerTypeEnum.UAT:
        url = environment.socketHostUat;
        break;
      default:
        break;
    }

    this.ws = new WebSocket(url);
    const authData = { type: 'AUTH_REQUEST', token: this.authService.getToken() };
    const openSubscriber = Subscriber.create(() => this.send(authData));
    this.observer = new Observable(observer => {
      this.ws.onmessage = event => observer.next(event.data);
      this.ws.onerror = event => observer.error(event);
      this.ws.onclose = () => {
        observer.complete();
        this.observer = undefined;
      };
      this.ws.onopen = () => {
        openSubscriber.next();
        openSubscriber.complete();
      };

      return () => {
        this.ws.close();
        this.observer = undefined;
      };
    });

    return this.observer;
  }

  send(message: any) {
    this.ws.send(JSON.stringify(message));
  }

  close() {
    this.ws.close();
  }
}
