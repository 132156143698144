import {Component, Input, OnInit} from '@angular/core';
import {IframeInfoModel} from '../../models/iframe-info.model';

@Component({
  selector: 'app-iframe-preview',
  templateUrl: './iframe-preview.component.html',
  styleUrls: ['./iframe-preview.component.scss']
})
export class IframePreviewComponent implements OnInit {

  @Input() iframeInfoModel: IframeInfoModel;

  constructor() { }

  ngOnInit() {
  }

}
