import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SendRequestModel } from '../../models/requests/send-request.model';
import { R2bankModel } from '../../models/requests/r2bank.model';
import { UserCreditsModel } from '../../models/requests/user-credits.model';
import { DeviceMetadataService } from '../device-metadata.service';
import { SuccessFormModel } from '../../models/success-form.model';

@Injectable()
export class RequestsService {

  constructor(
    private http: HttpClient,
    private deviceMetadataService: DeviceMetadataService
  ) {
  }

  getR2Bank() {
    const url = `/r2bank`;
    return this.http.get<R2bankModel>(url);
  }

  getCreditsList() {
    const url = `/user/credits`;
    return this.http.get<UserCreditsModel>(url);
  }

  sendR2Bank(entity: SendRequestModel) {
    const url = `/r2bank`;
    entity.metadata = this.deviceMetadataService.getDeviceMetadata();

    return this.http.put(url, entity);
  }

  getR2BankFinalize(id: string) {
    const url = `/r2bank/finalize`;

    return this.http.post(url, {
      id,
      metadata: this.deviceMetadataService.getDeviceMetadata()
    });
  }

  getRequestsHistory() {
    const url = `/r2bank/history`;
    return this.http.get(url);
  }

  getSuccessModelForSmsConfirmation(status: 'overdue' | 'waiter' | 'waiter-resend' | boolean, id: string): SuccessFormModel {
    switch (status) {
      case true:
        return new SuccessFormModel(
          'success',
          '',
          // tslint:disable-next-line:max-line-length
          `Запрос №${id} принят банком`,
          null,
          null,
          'Продолжить работу',
        );
      case false:
        return new SuccessFormModel(
          'error',
          'Не удалось отправить запрос в банк',
          `Попробуйте позже`,
          null,
          null,
          'Продолжить работу',
        );
    }
  }

  downloadFile(id: string) {
    const url = `/documents/file`;
    const params = new HttpParams()
      .append('id', id);

    return this.http.get(url, { params, responseType: 'arraybuffer' });
  }
}
