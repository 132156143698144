import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { TokenLifecycleModalEnum } from './models/token-lifecycle-modal.enum';

@Component({
  selector: 'app-token-lifecycle-modal',
  templateUrl: './token-lifecycle-modal.component.html',
  styleUrls: ['./token-lifecycle-modal.component.scss']
})
export class TokenLifecycleModalComponent implements OnInit {

  time: any;

  constructor(
    private matDialogRef: MatDialogRef<TokenLifecycleModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Observable<any>,
  ) {
  }

  ngOnInit(): void {
    this.data?.subscribe(res => {
      this.time = res;
    })

  }

  public close() {
    this.matDialogRef.close();
  }


  goToDashboard() {
    this.matDialogRef.close(TokenLifecycleModalEnum.REDIRECT_TO_DASHBOARD)
  }
}
