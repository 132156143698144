export class ProcessPaymentModel {
  public sector: string;
  public id: string;
  public action = 'pay';
  public amount: string;
  public fee: string;
  public currency = '643';
  public pan: string;
  public name = 'RN Bank';
  public cvc: string;
  public is_repeatable = false;
  public repeatable_day: number;
  public repeatable_end_month: number;
  public repeatable_end_year: number;
  public month: string;
  public year: string;
  public get_token = '1';
  public signature: string;
  public token: string;
  public email: string;
  public doesChangeCard: boolean;
  public url: string;
  public failurl: string;
  public card_insurance_condition_id: number;

  constructor() {}
}
