import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash';

const formErrors = {
  pan: '',
  cvc: '',
  validity: '',
  amount: '',
  email: ''
};

export const validationMessages = {
  pan: {
    required: 'Номер карты не заполнен',
    pan: 'Неверный номер карты'
  },
  cvc: {
    required: 'CVC не заполнен',
    pattern: 'CVC не заполнен'
  },
  validity: {
    required: 'Срок действия карты не заполнен',
    dateExpired: 'Неверный срок действия карты.',
    pattern: 'Неверный формат даты.'
  },
  amount: {
    required: 'Сумма не заполнена',
    pattern: 'Неверный размер суммы'
  },
  email: {
    required: 'Укажите адрес электронной почты',
    pattern: 'Укажите корректный адрес электронной почты'
  }
};

@Injectable()
export class CardListFormService {
  constructor() {}

  freshFormErrors() {
    return cloneDeep(formErrors);
  }
}
