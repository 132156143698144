import { Injectable } from '@angular/core';
import { LoginReasonEnum } from '../../../enums/login-reason.enum';
import { WarningMessageModel } from '../models';


@Injectable()
export class WarningMessagesService {

  constructor() {
  }


  showScreen(screen: LoginReasonEnum)
  {
    let dataFields: WarningMessageModel = {
      btnClass: '',
      btnText: '',
      hasButton: true,
    }
    switch (screen) {
      // 90 days
      case LoginReasonEnum.INACTIVITY:
        dataFields.icon = 'icon-warning'
        dataFields.title = ''
        dataFields.dscrOne = 'В целях безопасности ваша сессия автоматически завершается после 90 дней неактивности.'
        dataFields.dscrTwo = 'Пройдите активацию повторно.'
        dataFields.btnClass = 'btn-main'
        dataFields.btnText = 'Продолжить'
        break;
      // parallel sessions
      case LoginReasonEnum.PARALLEL_LOGIN:
        dataFields.icon = 'icon-warning'
        dataFields.title = ''
        dataFields.dscrOne = 'Ваша сессия принудительно завершена по причине входа в аккаунт с нового устройства.'
        dataFields.dscrTwo = 'Если вход выполнен не вами, рекомендуем завершить все подозрительные сессии в разделе "Настройки" -> "Сеансы и устройства".'
        dataFields.btnClass = 'btn-main'
        dataFields.btnText = 'Продолжить'
        break;
      // add pin
      case LoginReasonEnum.NEEDS_PIN_LOGIN:
        dataFields.icon = 'icon-warning'
        dataFields.title = ''
        dataFields.dscrOne = ''
        dataFields.dscrTwo = ''
        dataFields.btnClass = 'btn-main'
        dataFields.btnText = 'Продолжить'
        break;
      // check param can_continue = false
      case LoginReasonEnum.OTHER:
        dataFields.icon = 'icon-warning'
        dataFields.title = ''
        dataFields.dscrOne = ''
        dataFields.dscrTwo = ''
        dataFields.btnClass = 'btn-main'
        dataFields.btnText = 'Продолжить'
        break;
      case LoginReasonEnum.NONE:
        dataFields.icon = 'icon-warning'
        dataFields.title = ''
        dataFields.dscrOne = ''
        dataFields.dscrTwo = ''
        dataFields.btnClass = 'btn-main'
        dataFields.btnText = 'Продолжить'
        break;
      case LoginReasonEnum.PERMANENT_BAN:
        dataFields.icon = 'stop';
        dataFields.title = 'Доступ к интернет-банку заблокирован';
        dataFields.dscrOne = 'Для разблокировки обратитесь в службу поддержки';
        dataFields.hasButton = false;
        break;
      default:
        break;
    }
    return dataFields;
  }
}

