import {Injectable, Injector} from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SmsService} from '../services/sms.service';
import {catchError, tap} from 'rxjs/operators';

@Injectable()
export class LockoutsInterceptor implements HttpInterceptor {

  private smsService: SmsService;

  constructor(private injector: Injector) {}

  private _lockBehaviour(body: {lockouts: { sms_code_resend }}) {
    if (body?.lockouts && 'sms_code_resend' in body?.lockouts) {
      this.smsService.initTimer();
      this.smsService.resendTimeoutReset();
      this.smsService.resendTimeout = body.lockouts.sms_code_resend;
    }
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.smsService = this.injector.get(SmsService);

    return next.handle(req).pipe(
      tap(event => {
        if (event instanceof HttpResponse && event.body) {
          this._lockBehaviour(event.body);
        }

        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        this._lockBehaviour(error.error);

        throw error;
      })
    );
  }
}
