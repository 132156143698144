export class PromoModel {
    constructor(
        public is_empty = false,
        public is_card_insurance_enabled: boolean = null,
        public allowed_card_prefixes?: string[],
        public allowed_payment_methods?: 'DIRECT' | 'APPLE_PAY' | 'ANDROID_PAY' | 'SAMSUNG_PAY',
        public is_card_insurance_visible?: boolean,
        public allowed_payment_type?: 'RECURRING' | 'SINGLE' | '',
        public error_text?: string,
        public remaining_promo_operations?: number,
        public icon_link?: string,
        public promo_text?: string,
    ) {}
}
