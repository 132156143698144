import { Subject } from 'rxjs';

export class AutocompleteService {

  errorCode$: Subject<number> = new Subject<number>();
  clear$: Subject<boolean> = new Subject<boolean>();

  constructor() {
  }
}
