import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DashboardModel } from '../../models/credits/dashboard.model';
import { HelperService } from '../helper.service';
import { FileDownloaderService } from '../file-downloader.service';

@Injectable()
export class CreditsService {

  constructor(
    private http: HttpClient,
    private helperService: HelperService,
    private downloader: FileDownloaderService
  ) {
  }

  loadDashboard(): Observable<DashboardModel> {
    const url = `/web/dashboard`;
    const headers = new HttpHeaders().append('api-version', '4');

    return this.http.get<DashboardModel>(url, {headers});
  }

  getScheduleReceipt(scheduleId: number) {
    const url = `/user/schedules/pdf?schedule_id=${scheduleId}`;

    this.http.get(url)
      .toPromise()
      .then((res: { link: string }) => {
        this.downloader.download(res.link, {
          defaultFilename: 'receipt',
          defaultResponseFileType: 'application/pdf',
        });
      })
      .catch((error) => this.helperService.error('Не удалось загрузить квитанцию', error));
  }

  reloadBanners() {
    const url = '/user/banners';

    const headers = new HttpHeaders().append('Api-Version', '5');
    return this.http.get(url, {headers});
  }
}
