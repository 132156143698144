import { Injectable, Injector } from '@angular/core';
import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { get } from 'lodash';
import { environment } from '../../environments/environment';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../services/auth/auth.service';
import { LoginReasonEnum } from '../enums/login-reason.enum';
import { ScreenServiceService } from '../services/screen-service.service';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {

  private apiUrl = '';

  private auth: AuthService;

  constructor(private injector: Injector,
              private screenService: ScreenServiceService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {

    // Workaround for cyclic dependency error with HttpInterceptor, see https://github.com/angular/angular/issues/18224
    this.auth = this.injector.get(AuthService);

    if (!this.auth.apiHost && this.auth.isLoggedIn) {
      this.auth.logout()
        .finally(() => {
          return next.handle(req);
        });
    }

    if (req.url.indexOf(environment.best2payHost) > -1) {
      return next.handle(req);
    }

    if (req.url.indexOf('http') === -1) {
      this.apiUrl = `${this.auth.apiHost}/api`;
    } else {
      this.apiUrl = '';
    }

    if (req.url.indexOf('/web/auth/password') > -1) {
      const httpHeaders = req.headers
        .set('X-Requested-With', 'XMLHttpRequest')
        .set('Content-Type', 'application/json')
        .append('Accept', 'application/json');

      return next.handle(req.clone({
        url: `${this.apiUrl}${req.url}`,
        headers: httpHeaders
      }));
    }

    const token = this.auth.getToken();

    let headers = req.headers
      .set('X-Requested-With', 'XMLHttpRequest')
      .set('X-Client-Token', token);

    if (req.url.indexOf('/documents/file') !== -1) {
      headers.append('Content-Type', 'multipart/form-data');
      headers.append('Accept', 'application/json');
    } else {
      headers = headers.set('Content-Type', 'application/json');
      headers = headers.append('Accept', 'application/json');
    }

    // Clone the request to add the new header.
    const apiRequest = req.clone({
      url: req.url.indexOf('.svg') === -1 ? `${this.apiUrl}${req.url}` : req.url,
      headers
    });

    if (req.method === 'POST' && req.url.match(/check|activate|confirm$/gi)) {
      const { required_features } = environment;
      const body = JSON.parse(apiRequest.body);

      Object.assign(
        apiRequest,
        {
          body: JSON.stringify(Object.assign(body, { required_features })),
        }
      );
    }

    return next.handle(apiRequest).pipe(
      catchError(async (err: HttpErrorResponse) => {
        const error_code = get(err, 'error.error_code', '');
        const logout_reason = get(err, 'error.logout_reason', LoginReasonEnum.NONE);
        if (error_code && (error_code === 1019)) { //  || error === 1015
          if (this.auth.isLoggedIn) {
            await this.auth.logout(true, false);
          }

          if (logout_reason === LoginReasonEnum.INACTIVITY) {
            this.screenService.setStatus(LoginReasonEnum.INACTIVITY)
          }
          if (logout_reason === LoginReasonEnum.PARALLEL_LOGIN) {
            this.screenService.setStatus(LoginReasonEnum.PARALLEL_LOGIN)
          }
          if (logout_reason === LoginReasonEnum.NEEDS_PIN_LOGIN) {
            this.screenService.setStatus(LoginReasonEnum.NEEDS_PIN_LOGIN)
          }
          if (logout_reason === LoginReasonEnum.OTHER) {
            this.screenService.setStatus(LoginReasonEnum.OTHER)
          }
          if (logout_reason === LoginReasonEnum.NONE) {
            const status = this.screenService.status;
            if (status !== LoginReasonEnum.NONE) {
              this.screenService.setStatus(LoginReasonEnum.NONE)
            }
          }
          return of(err);
        }

        throw err;
      }));
  }
}
