import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CreditsResolver } from './modules/credits/credits.resolver';
import { BrowserModule } from '@angular/platform-browser';
import { MatComponentsModuleModule } from './modules/mat-components-module/mat-components-module.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CaptchaService } from './services/captcha.service';
import { SmsService } from './services/sms.service';
import { ErrorService } from './services/error.service';
import { DeviceMetadataService } from './services/device-metadata.service';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { environment } from '../environments/environment';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from './components/shared.module';
import { CreditsService } from './services/credits/credits.service';
import { HelperService } from './services/helper.service';
import { FileDownloaderService } from './services/file-downloader.service';
import { AppHeaderComponent } from './components/app-header/app-header.component';
import { AuthGuard } from './guards/auth-guard.service';
import { AuthService } from './services/auth/auth.service';
import { ErrorFormService } from './services/error-form.service';
import { EsignRequiredGuard } from './guards/esign-required-guard.service';
import { UserService } from './services/auth/user.service';
import { LockoutsInterceptor } from './interceptors/lockouts-interceptor.service';
import { ApiInterceptor } from './interceptors/api-interceptor.service';
import { SetTokenInterceptor } from './interceptors/set-token-interceptor.service';
import { TokenExpirationInterceptor } from './interceptors/token-expiration-interceptor.service';
import { ScreenServiceService } from './services/screen-service.service';
import { WarningMessagesService } from './components/warning-screen/services/warning-messages.service';

@NgModule({
  declarations: [
    AppComponent,
    AppHeaderComponent
  ],
  imports: [
    BrowserModule,
    RecaptchaV3Module,
    AppRoutingModule,
    MatComponentsModuleModule,
    HttpClientModule,
    SharedModule,
    NgbModule
  ],
  bootstrap: [
    AppComponent
  ],
  providers: [
    // core
    AuthGuard,
    AuthService,
    ErrorFormService,
    EsignRequiredGuard,
    UserService,

    { provide: HTTP_INTERCEPTORS, useClass: LockoutsInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: SetTokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TokenExpirationInterceptor, multi: true },

    // app
    CreditsResolver,
    CreditsService,
    HelperService,
    FileDownloaderService,
    CaptchaService,
    SmsService,
    DeviceMetadataService,
    ErrorService,
    ScreenServiceService,
    WarningMessagesService,

    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.SITE_KEY_V3 },
  ]
})
export class AppModule {}
