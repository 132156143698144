import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateParser'
})
export class DateParserPipe implements PipeTransform {

  transform(value: any, formatIn: string, formatOut: string): string {
    if (!value) {
      return '';
    }

    return moment(value, formatIn).format(formatOut);
  }
}
