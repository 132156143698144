import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription, timer } from 'rxjs';

@Injectable()
export class TimerService {
  DEFAULT_TIMEOUT = 60;

  public timeoutSubject$ = new BehaviorSubject<number>(this.DEFAULT_TIMEOUT);
  public timeoutTimerSubscriber$: Subscription;

  /**
   * Задаёт новое значение для таймаута
   * @param value - значение для таймаута
   */
  set timeoutValue(value: number) {
    this.timeoutSubject$.next(value);
  }

  /**
   * Возвращает текущее значение таймаута
   * @return {Number}
   */
  get timeoutValue(): number {
    return this.timeoutSubject$.value;
  }

  /**
   * Декремент таймаута в единицу времени
   */
  timerTick() {
    if (this.timeoutValue === 0) {
      return;
    }

    this.timeoutValue -= 1;
  }

  /**
   * Инициализация таймера на основе значения таймаута
   */
  initTimer() {
    if (this.timeoutTimerSubscriber$) {
      this.timeoutTimerSubscriber$.unsubscribe();
      this.timeoutTimerSubscriber$ = null;
    }

    this.timeoutTimerSubscriber$ = timer(
      this.timeoutValue,
      1000
    ).subscribe(() => {
      this.timerTick();
    });
  }
}
