import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { TransferInitModel } from '../../models/account-closure/transfer-init.model';
import { DeviceMetadataModel } from '../../models/device-metadata.model';

@Injectable({
  providedIn: 'root'
})
export class AccountClosureService {

  constructor(
    private http: HttpClient
  ) { }

  getAppFeature() {
    const url = `/app/features`;

    return this.http.get(url);
  }

  getTransferInfo() {
    const url = `/transfer/info`;

    return this.http.get(url);
  }

  getTransferSuggestBank(bic: string, bank_name: string) {
    const url = `/transfer/suggest_bank`;
    let params = new HttpParams();
    params = bic ? params.append('bic', bic) : params;
    params = bank_name ? params.append('bank_name', bank_name) : params;

    return this.http.get(url, { params });
  }

  transferInit(entity: TransferInitModel, metadata: DeviceMetadataModel) {
    const url = `/transfer/init`;

    return this.http.post(url, { ...entity, metadata });
  }

  transferSendSms(transfer_id: number) {
    const url = `/transfer/send_sms`;

    return this.http.post(url, { transfer_id });
  }

  getTransferHistory() {
    const url = `/transfer/history`;

    return this.http.get(url);
  }

  sendTransferEmail(transfer_id: number) {
    const url = `/transfer/email`;

    return this.http.post(url, { transfer_id });
  }

  getCloseAccountInfo() {
    const url = `/close_account/info `;

    return this.http.get(url);
  }

  sendCloseAccount(ACC_ID: number, metadata: DeviceMetadataModel) {
    const url = `/close_account/send`;

    return this.http.post(url, { ACC_ID, metadata });
  }
}
