import { HttpErrorResponse } from "@angular/common/http";
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from "@angular/router";
import { BusinessProcessLogModel } from "../../models/business-process-log.model";
import { ProlongationConfirmationModel } from "../../models/prolongation/prolongation-confirmation.model";
import { ApiService } from "../../services/api.service";
import { BusinessProcessLogService } from "../../services/business-process-log.service";
import { AcquiringService } from "../../services/insurance/acquiring.service";

type screenType = 'main' | 'doc' | 'sms' | 'loading' | 'process' | 'conditionsError' | 'signError' | 'final'

@Component({
  selector: 'app-additional-agreement',
  templateUrl: './additional-agreement.component.html',
  styleUrls: ['./additional-agreement.component.scss']
})
export class AdditionalAgreementComponent implements OnInit{
  @Output() closeEvent: EventEmitter<any> = new EventEmitter();
  @Input() id: string;

  confirmationModel: ProlongationConfirmationModel;

  constructor(
    private apiService: ApiService,
    private bpLogService: BusinessProcessLogService,
    private router: Router,
  ) {}

  screen: screenType = 'loading'
  agreement = false;
  document: any = null;

  goToMainPage(screen: 'confirmation' | 'extra_agree' | 'default') {
    const logEventType = {
      'confirmation': 'EXTRA_AGREEMENT_CONFIRMATION',
      extra_agree: 'EXTRA_AGREEMENT',
      default: 'EXTRA_AGREEMENT_CONDITIONS'
    }
    this.bpLogService.sendBpLogEvent('BP_END', logEventType[screen])
    this.router.navigate(['/']);
  }

  prepareConfirmedResponse(status: boolean) {
    this.screen = 'final';

    // if (status) {
    //   this.bpLogService.sendBpLogEvent('NEXT_STEP', 'EXTRA_AGREEMENT_CONFIRMATION');
    // } else {
    //   this.bpLogService.sendBpLogEvent('ERROR', 'EXTRA_AGREEMENT_CONFIRMATION');
    // }
    // this.confirmationModel = this.acquiringService.getSuccessModelForSmsConfirmation(status);
  }

  showWaiter($event: {status: boolean, event: string}) {
    if ($event.status) {
      // this.bpLogService.sendBpLogEvent('NEXT_STEP', 'KASKO_LOAN_LIMIT_LOADER_COMPLETION');

      // const type = $event.event === 'main' ? 'waiter' : 'waiter-resend';
      // this.confirmedModel = this.acquiringService.getSuccessModelForSmsConfirmation(type);
      this.screen = 'process';
      return;
    }

    this.screen = 'sms';
  }

  logError(error: HttpErrorResponse) {
    this.bpLogService.sendBpLogEvent('ERROR', 'EXTRA_AGREEMENT_CONFIRMATION', error.error);
    this.screen = 'signError'
  }

  goMain() {
    this.bpLogService.sendBpLogEvent('NEXT_STEP', 'EXTRA_AGREEMENT_CONDITION');
    this.screen = 'main';
  }

  goToSmsVerification() {
    this.apiService.signExtraAgreement(this.id).subscribe(
(res: any) => {
          this.bpLogService.sendBpLogEvent('NEXT_STEP', 'EXTRA_AGREEMENT_CONFIRMATION');
          this.confirmationModel = res
          this.screen = 'sms'
      },
(error) => {
        this.bpLogService.sendBpLogEvent('ERROR', 'EXTRA_AGREEMENT_CONFIRMATION', error);
        this.screen = 'conditionsError';
      }
    )
  }

  ngOnInit() {
    this.apiService.getExtraAgreement(this.id).subscribe(res => {
      const logModel = new BusinessProcessLogModel(
        'SIGN_EXTRA_AGREEMENT', Number(this.id)
      );

      this.businessProcessLogInit(logModel);
      this.document = res;
      this.screen = 'main';
    }, () => { this.screen = 'conditionsError' })
    return
  }

  businessProcessLogInit(logModel: BusinessProcessLogModel) {
    this.bpLogService.bpLogInit(logModel).subscribe((res: { deal_id: number }) => {
      this.bpLogService.dealId = res.deal_id;
    });
  }

  goToAgreement() {
    this.bpLogService.sendBpLogEvent('NEXT_STEP', 'EXTRA_AGREEMENT');
    if(!this.document) {
      this.apiService.getExtraAgreement(this.id).subscribe(res => {
        this.document = res;
        this.screen = 'doc';
      })
    } else {
      this.screen = 'doc';
    }
  }
}
