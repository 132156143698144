import { DeleteInformationModel } from '../delete-information.model';

export class CardModel {
  constructor(
    public short_pan?: string,
    public id?: number,
    public pan?: string,
    public token?: string,
    public amount?: string,
    public is_repeatable?: boolean,
    public repeatable_day?: number,
    public repeatable_end_year?: number,
    public repeatable_end_month?: number,
    public email?: string,
    public name?: string,
    public internal_name?: string,
    public logo?: string,
    public delete_information?: DeleteInformationModel
  ) {}
}
