import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { EsignDocumentModel } from '../../models/esign-document.model';
import { ApiService } from '../../services/api.service';
import { NgForm } from '@angular/forms';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'esign-condition-document',
  templateUrl: './esign-condition-document.component.html',
  styleUrls: ['./esign-condition-document.component.scss']
})
export class EsignConditionDocumentComponent implements OnInit {

  @Input() document: EsignDocumentModel;
  @Input() id: string;
  @Input() confirmation: boolean;
  @Input() isEsign = false;

  @Output() closeCondition = new EventEmitter<void>();

  @ViewChild('form', {static: true}) form: NgForm;

  email: string;

  constructor(private apiService: ApiService) { }

  ngOnInit() {
    this.document = !this.document ? new EsignDocumentModel() : this.document;
  }

  async confirm() {
    await firstValueFrom(this.apiService.sendEsignDocument(1, ''));
  }

  close() {
    this.closeCondition.emit();
  }

  onSubmit() {
    this.apiService.sendEsignDocument(+this.id, this.email).subscribe(() => {
      this.email = '';
      this.form.control.markAsPristine();
    });
  }
}
