import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { PromoModel } from '../../models/payments/promo.model';
import { CallbackValidator } from '../callback-validator.service';
import { HelperService } from '../helper.service';

export enum PromoRules {
    PAN  = 'promo-pan-rule',
    TYPE = 'promo-type-rule',
}

@Injectable()
export class PromoService {

    private readonly $promoSubject: BehaviorSubject<PromoModel> = new BehaviorSubject<PromoModel>(new PromoModel(true));
    private readonly validateRules = [
        {
            rule: PromoRules.PAN,
            callback(model: PromoModel, cardPan: string) {
              if (
                !model.allowed_card_prefixes
                || model.allowed_card_prefixes.length <= 0
                || cardPan.length <= 0
              ) {
                return true;
              }

              return model.allowed_card_prefixes.filter(
                prefix => (new RegExp(`^${prefix}+.*`, 'g')).test(cardPan)
              ).length > 0;
            },
            status: false,
        },
        {
            rule: PromoRules.TYPE,
            callback(model: PromoModel, paymentType: string) {
              if (paymentType === '' || !model.allowed_payment_type) {
                return true;
              }

              return model.allowed_payment_type === paymentType;
            },
            status: false,
        },
    ];

    isLoaded: boolean;

    constructor(
        private readonly http: HttpClient,
        private readonly helperService: HelperService,
        private readonly promoValidate: CallbackValidator,
    ) {
        this.promoValidate.events = {
            COMPLETED: 'promo-rule-completed',
            FINISH: 'promo-rule-finish',
            PUSH: 'promo-rule-push',
        };
    }

    set promo(promo: PromoModel) {
        this.$promoSubject.next(promo);
    }

    get promo(): PromoModel {
        return this.$promoSubject.value;
    }

    get $promo(): BehaviorSubject<PromoModel> {
      return this.$promoSubject;
    }

    get validateEmitter(): CallbackValidator {
      return this.promoValidate;
    }

    /**
     * Возвращает статус
     * @return {boolean}
     */
    get isCardProtection(): boolean {
        return this.validateEmitter.isCompleted && this.promo.is_card_insurance_visible
            ? this.promo.is_card_insurance_enabled
            : false;
    }

    /**
     * Возвращает id баннера
     * @return null|string
     */
    get bannerId(): string {
      return this.validateEmitter.isCompleted && !this.promo.is_empty && this.isLoaded
          ? localStorage.getItem('sf_banner_id')
          : null;
    }

    /**
     * Получить информацию о промо-акции
     *
     * @param sf_id - id баннера или пуша
     * @param only_return
     * @return {Promise<any>}
     */
    async loadPromo(sf_id: string, only_return?: boolean): Promise<any> {
      const url = `/payment/promo`;

      try {
        const promo = await this.http.get<PromoModel>(url, { params: { sf_id } }).toPromise();

        if (only_return) {
          return promo;
        }

        this.promo = promo;
        this.isLoaded = true;
        return this.promo;
      } catch (e) {
        return this.helperService.error('Не удалось получить информацию о промо-акции', e);
      }
    }

    initValidate(promo: PromoModel, options: any) {
      this.validateRules.forEach(rule => {
          const args = [];
          let isValid = false;

          switch (rule.rule) {
              case PromoRules.PAN:
                  isValid = 'pan' in options;
                  args.push(promo, options.pan || '');
                  break;
              case PromoRules.TYPE:
                  isValid = 'type' in options;
                  args.push(promo, options.type || '');
                  break;
          }

          if (!isValid) {
            return;
          }

          this.promoValidate.addRule(Object.assign({}, rule, { arguments: args }));
      });
    }

    reset() {
      this.$promoSubject.next(new PromoModel(true));
      this.isLoaded = false;
      this.validateEmitter.isCompleted = false;
    }

    resetValidate(status = true) {
      this.validateEmitter.isCompleted = status;
    }

    setEmpty(full = false) {
      if (full) {
        this.isLoaded = false;
      }

      this.$promoSubject.next(Object.assign({}, this.promo, { is_empty: true }));
    }

    setNormal(full = false) {
      if (full) {
        this.isLoaded = true;
      }

      this.$promoSubject.next(Object.assign({}, this.promo, { is_empty: false }));
    }
}
