import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { CardListFormService, validationMessages } from './card-list-form.service';
import { panValidator } from './pan-card.validator';
import { Subject, Subscription } from 'rxjs';
import { skipWhile, takeUntil } from 'rxjs/operators';
import { ValidateRule } from '../../services/callback-validator.service';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { PromoRules, PromoService } from '../../services/payments/promo.service';
import { PromoModel } from '../../models/payments/promo.model';
import { PaymentModel } from '../../models/payments/payment.model';
import { CardModel } from '../../models/payments/card.model';
import { HelperService } from '../../services/helper.service';
import { ValidatorService } from '../../services/validator.service';


@Component({
  selector: 'app-card-list',
  templateUrl: './card-list.component.html',
  styleUrls: ['./card-list.component.scss'],
  providers: [CardListFormService]
})
export class CardListComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('slickModal', {static: false}) slickModal: SlickCarouselComponent;

  @Input('form') public cardsGroup: FormGroup;
  @Input() amount: string;
  @Input() currentSlide: number;
  @Input() isAutopayId: boolean;
  @Input() payment: PaymentModel;
  @Input() isAuto: boolean;

  @Output() cardEditEvent = new EventEmitter<CardModel>();
  @Output() showSliderEvent = new EventEmitter();
  @Output() lastSlide = new EventEmitter<boolean>();
  @Output() selectedCardEvent = new EventEmitter<number>();

  private $unsubscribe = new Subject();
  private $promoSubscribe: Subscription;

  private _fitForPromo = true;

  set fitForPromo(status: boolean) {
    this._fitForPromo = status;
  }

  get fitForPromo(): boolean {
    return this._fitForPromo && !this.promoService.promo.is_empty;
  }

  get isPromoLoaded(): boolean {
    return this.promoService.isLoaded;
  }

  cards = [new CardModel('Другая карта')];
  formErrors: ValidationErrors;

  selectedCard: CardModel;
  amountInvalid: number;
  amountTouched: number;

  constructor(
    public helperService: HelperService,
    private formService: CardListFormService,
    private validatorService: ValidatorService,
    private promoService: PromoService,
  ) {
  }

  lastSlideControls = [
    {
      name: 'pan',
      validator: [panValidator(), Validators.required],
    },
    {
      name: 'validity',
      validator: [Validators.pattern(/^(0?[1-9]|1[0-2])\/(\d{2})/)]
    },
    {
      name: 'cvc',
      validator: [Validators.pattern(/^(\d{3})$/)]
    }
  ];

  slideConfig = {
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 6,
    centerMode: true,
    draggable: true,
    dots: false,
    variableWidth: true,
    focusOnSelect: true,
    arrows: true,
    touchMove: true
  };

  ngOnInit() {
    this.formErrors = this.formService.freshFormErrors();
    this.cards.unshift(...this.payment.cards);

    if (this.isAutopayId) {
      this.slideConfig = {
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 6,
        centerMode: true,
        draggable: false,
        dots: false,
        variableWidth: true,
        focusOnSelect: true,
        arrows: false,
        touchMove: false
      };
    }

    this.$promoSubscribe = this.cardsGroup
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe),
        skipWhile(() => this.promoService.promo.is_empty),
      ).subscribe(async () => {
        this.promoService.resetValidate();

        if (this.cardPan.length <= 0) {
          this.fitForPromo = true;
        }

        this.promoService.initValidate(
          this.promoService.promo,
          { pan: this.cardPan }
        );

        const rule: ValidateRule | boolean = await this.promoService
          .validateEmitter
          .validate(PromoRules.PAN);

        if (typeof rule !== 'object') { return; }

        this.fitForPromo = rule.status;
      });

  }

  ngOnDestroy() {
    this.$unsubscribe.next('');
    this.$unsubscribe.complete();
  }

  ngAfterViewInit() {
    this.initCard();
  }

  initCard() {
    if (this.currentSlide === -1) {
      this.currentSlide = 0;
      this.selectedCardEvent.emit(0);
    }

    this.slickModal.slickGoTo(this.currentSlide);
    this.selectCard(this.cards[this.currentSlide]);

    this.updateFormControls();
  }

  isLastSlide(): boolean {
    const isLastSlide = this.currentSlide === this.cards.length - 1;
    this.lastSlide.emit(isLastSlide);
    return isLastSlide;
  }

  afterChange(e: any) {
    this.currentSlide = e.currentSlide;
    this.selectedCardEvent.emit(this.currentSlide);
    this.selectCard(this.cards[this.currentSlide]);

    this.updateFormControls();
  }

  selectCard(card: CardModel) {
    this.promoService.validateEmitter.reset();
    this.selectedCard = card;
    this.cardsGroup.get('token').setValue(card.token);
  }

  formIsValid() {
    this.validatorService.validateDeep(this.cardsGroup, this.formErrors, validationMessages);

    this.getAmountInvalid();
    this.getAmountTouched();

    return this.cardsGroup.valid;
  }

  checkControlInvalid(control: string): boolean {
    return !this.cardsGroup.controls[control].valid && !!this.cardsGroup.controls[control].value;
  }

  getAmountInvalid() {
    let amount = 0;

    this.lastSlideControls.forEach((control) => {
      if (!this.cardsGroup.controls[control.name].valid) {
        amount++;
      }
    });

    this.amountInvalid = amount;
  }

  getAmountTouched() {
    let amount = 0;

    this.lastSlideControls.forEach((control) => {
      if (this.cardsGroup.controls[control.name].touched) {
        amount++;
      }
    });

    this.amountTouched = amount;
  }

  goToCardEdit(card: CardModel) {
    this.cardEditEvent.emit(card);
  }

  enteringDate(event: KeyboardEvent) {
    return this.helperService.preventAlpha(event);
  }

  get cardPan(): string {
     return this.isLastSlide() ? this.cardsGroup.controls['pan'].value : this.selectedCard.pan;
  }

  get promo(): PromoModel {
    return this.promoService.promo;
  }

  updateFormControls() {
    if (this.isLastSlide()) {
      this.lastSlideControls.forEach((control) => {
        this.cardsGroup.controls[control.name].setValidators(control.validator);
        this.cardsGroup.controls[control.name].updateValueAndValidity();
      });
    } else {
      this.lastSlideControls.forEach((control) => {
        this.amountInvalid = 0;
        this.cardsGroup.controls[control.name].clearValidators();
        this.cardsGroup.controls[control.name].updateValueAndValidity();
      });
    }
  }
}
