import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AcquiringService } from '../../services/insurance/acquiring.service';
import { BusinessProcessLogService } from '../../services/business-process-log.service';
import {
  ProlongationConfirmationModel
} from '../../models/prolongation/prolongation-confirmation.model';

@Component({
  selector: 'payment-confirm',
  templateUrl: './payment-confirm.component.html',
  styleUrls: ['./payment-confirm.component.scss']
})
export class PaymentConfirmComponent implements OnInit {

  @Input() id: string;
  @Input() confirmationModel: ProlongationConfirmationModel;

  @Output() backEvent = new EventEmitter();
  @Output() confirmEvent = new EventEmitter();

  private optionSelected: number;
  public screen: 'code' | 'waiter' = 'code';

  constructor(private acquiringService: AcquiringService,
              private bpLogService: BusinessProcessLogService) {
  }

  ngOnInit() {
    this.optionSelected = this.acquiringService.getTrackOptionFromStorage();
  }

  returnToSend() {
    this.bpLogService.sendBpLogEvent('NEXT_STEP', 'CARD_INSURANCE_PAYMENT_SCREEN');

    this.backEvent.emit();
  }

  /**
   * Задаёт параметры для отображение вейтера
   * @return {void}
   */
  showWaiter($event: {status: boolean, event: string}) {
    if ($event.event === 'main') {
      this.bpLogService.sendBpLogEvent('NEXT_STEP', 'CARD_INSURANCE_COMPLETION');

      this.confirmEvent.emit();
    }
  }

  errorProcessing(error: any) {
    this.bpLogService.sendBpLogEvent('ERROR', 'CARD_INSURANCE_CONFIRMATION', error.error);
  }
}
