<div class="caption-wrapper" *ngIf="deposits?.length > 0">
  <h3 >Прогресс по депозитам</h3>
</div>
<div class="content-after credit-progress">
  <div class="progress-wrap">

<!--    <ng-container *ngIf="!deposits?.length && !loading">-->
<!--      <p>У вас пока не открыто ни одного депозита</p>-->
<!--    </ng-container>-->
    <ng-container *ngIf="deposits?.length > 0">
      <div class="row progress-row-wrap">
        <div class="col-md-8 table-cell-left">
          <p>Общая сумма депозитам</p>
        </div>
        <div class="col-md-4 table-cell-right"><p>{{ depositTotalSum | moneySpaces: 'kopecks': false }} ₽</p></div>
      </div>
      <div class="table-row-progress table-row-wrap">
        <progress id="progress-bar" class="progress-bar"
                  [value]="depositProgress.value"
                  [max]="depositProgress.max"></progress>
      </div>
      <div class="row progress-row-wrap">
        <div class="col-md-8 table-cell-left"><p>Уже начислено</p></div>
        <div class="col-md-4 table-cell-right"><p class="color-green">+{{ percentEarned | moneySpaces: 'kopecks': false }} ₽</p></div>
      </div>
    </ng-container>
  </div>
</div>



