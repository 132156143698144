import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';

@Injectable()
export class ValidatorService {
  validate(form: FormGroup, ignoredFields: string[] = [], result = true): boolean {
    Object.keys(form.controls).forEach(control => {
      const controlGroup = form.get(control);

      if (result && !ignoredFields.find((value) => value === control)) {
        if (controlGroup instanceof FormControl && !controlGroup.valid) {
          result = false;
        }
      }

      return result;
    });

    return result;
  }

  validateDeep(form: AbstractControl, errorsBag: any, validationMessages: any) {
    for (const field in errorsBag) {
      if (errorsBag.hasOwnProperty(field)) {
        const control = form.get(field);

        if (control instanceof FormGroup) {
          this.validateDeep(control, errorsBag[field], validationMessages[field]);
        }

        if (control instanceof FormControl) {
          errorsBag[field] = '';

          if (control && !control.valid) {
            const messages = validationMessages[field];

            for (const key in control.errors) {
              if (control.errors.hasOwnProperty(key)) {
                errorsBag[field] += messages[key] + ' \n';
              }
            }
          }
        }
      }
    }
  }
}
