import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CardInsuranceModel } from '../../models/payments/card-insurance.model';
import { PaymentsContainerService } from '../../services/payments/payments-container.service';
import { Subject } from 'rxjs';
import { skipWhile, takeUntil } from 'rxjs/operators';
import { BusinessProcessLogService } from '../../services/business-process-log.service';
import { PromoModel } from '../../models/payments/promo.model';
import { PromoService } from '../../services/payments/promo.service';
import { CardInsuranceService } from '../../services/payments/card-insurance.service';

@Component({
  selector: 'insurance-panel',
  templateUrl: './insurance-panel.component.html',
  styleUrls: ['./insurance-panel.component.scss']
})
export class InsurancePanelComponent implements OnInit, OnDestroy {

  @Input() cardInsurance: CardInsuranceModel;
  @Input() amount: number;
  @Input() spinner: boolean;

  @Output() showSliderEvent = new EventEmitter<number>();
  private $unsubscribe = new Subject();

  constructor(
    private paymentsContainer: PaymentsContainerService,
    private promoService: PromoService,
    private cardInsuranceService: CardInsuranceService,
    private bpLogService: BusinessProcessLogService
  ) {
  }

  ngOnInit() {
    this.paymentsContainer.cardsUpdate$.pipe(
        takeUntil(this.$unsubscribe),
        skipWhile(() => this.promoService.validateEmitter.isCompleted)
      ).subscribe(() => {
        this.cardInsuranceService.protection = true;
    });
  }

  ngOnDestroy() {
    this.$unsubscribe.next('');
    this.$unsubscribe.complete();
    // todo: this.promoService.validateEmitter.removeAllListeners();
  }

  toggleCardProtection() {
    if (!this.cardProtection) {
      this.bpLogService.sendBpLogEvent('NEXT_STEP', 'CARD_INSURANCE_PAYMENT_SCREEN');
    } else {
      this.bpLogService.sendBpLogEvent('BP_END', 'CARD_INSURANCE_PAYMENT_SCREEN');
    }

    this.cardProtection = !this.cardProtection;
  }

  set cardProtection(status) {
    this.cardInsuranceService.protection = status;
  }

  get cardProtection(): boolean {
    return this.cardInsuranceService.protection;
  }

  get promo(): PromoModel {
    return new PromoModel(); // todo this.promoService.promo;
  }

  get isVisible(): boolean {
    return this.promoService.validateEmitter.isCompleted
      && !this.promoService.promo.is_empty
      && this.promoService.isLoaded
      ? this.promoService.promo.is_card_insurance_visible
      : true;
  }

  showSlider() {
    this.bpLogService.sendBpLogEvent('NEXT_STEP', 'CARD_INSURANCE_SPLASH');

    this.showSliderEvent.emit(this.cardInsurance.conditions.insurance_limit);
  }

  checkCardStatus(value: string): boolean {
    return this.cardInsurance.status === value;
  }

  showWarning(): boolean {
    if (this.amount > 3600000) {
      this.cardInsuranceService.protection = false; // todo this.promoService.isLoaded ? this.promoService.isCardProtection : false;
    }
    return this.amount > 3600000;
  }

  showSpinner(): boolean {
    return this.spinner;
  }

  getInsuranceLimit(): number {
    return this.cardInsurance.conditions && this.cardInsurance.conditions.insurance_limit
      ? this.cardInsurance.conditions.insurance_limit / 100
      : 0;
  }
 }
