<div class="wrap wrap--auto">
  <div class="info">
      <div class="info__container">
        <div>
  <!--        <div [svg]="'back-arrow'" class="btn-back&#45;&#45;icon"></div>-->
          <div class="info__title">
            <span *ngIf="!isEsign">{{ document.mark_and_model }}</span>
            <span *ngIf="isEsign">{{ 'Кредитная документация' }}</span>
          </div>
        </div>
      </div>
    </div>
  <button class="btn btn--close slider__close" type="button" (click)="close()">
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
      <g fill="none" fill-rule="evenodd" stroke="#323A3F" stroke-linecap="round" stroke-opacity=".697">
        <path d="M.688.688l23.625 23.625M24.313.688L.688 24.313"/>
      </g>
    </svg>
  </button>

  <div>
    <div class="info info--scroll">
      <div class="info__items">
        <pdf-viewer [src]="document.link"
                    [render-text]="true"
                    style="display: block; height: 592px; overflow: auto;"
        ></pdf-viewer>
      </div>
    </div>
    <form #form="ngForm"
          class="info__buttons info__buttons--center"
          (ngSubmit)="onSubmit()"
    >
      <span class="info__title">Отправка на почту</span>
      <div class="form">
        <div class="form__row">
          <div class="form__elem">
            <div class="underline">
              <input class="form__input form__input--noindent"
                     type="email"
                     name="email"
                     [(ngModel)]="email"
                     underline-focus
                     required
                     pattern="^\S+@\S+\.\S+$"
              >
            </div>
            <div *ngIf="!form.pristine && !form.valid">
              <span class="error-text">Некорректный e-mail</span>
            </div>
          </div>
          <div class="form__elem">
            <div class="info__buttons">
              <button class="btn-main" type="submit" [disabled]="form.pristine || !form.valid">Отправить</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
