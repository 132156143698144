import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ExpiresTokenTimerService } from '../services/expires-token-timer.service';

@Injectable()
export class TokenExpirationInterceptor implements HttpInterceptor {

  constructor(private expiresTokenTimer: ExpiresTokenTimerService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // Извлечение токена из заголовков
          const tokenExpiresIn = event.headers?.get('X-Token-Expires-In');

          // Обработка токена
          if (tokenExpiresIn && tokenExpiresIn != '-1') {
            this.expiresTokenTimer.setTimer(tokenExpiresIn);
          }
          return event;
        }
        return event;
      })
    );
  }
}
