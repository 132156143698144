<div class="success">
  <div *ngIf="type === 'success'"
       class="success__icon">
    <mat-icon style="width: 190px; height: 190px;" svgIcon="icon-complete"></mat-icon>
  </div>

  <app-spinner *ngIf="type === 'waiting'" [type]="'big'"></app-spinner>

  <div class="success__icon" *ngIf="type === 'error'">
    <mat-icon style="width: 190px; height: 190px;" svgIcon="icon-reject"></mat-icon>
  </div>
  <div class="success__icon" *ngIf="type === 'await'">
    <mat-icon style="width: 190px; height: 190px;" svgIcon="clock"></mat-icon>
  </div>
  <div class="success__icon" *ngIf="type === 'phone'">
    <mat-icon style="width: 190px; height: 190px;" svgIcon="call"></mat-icon>
  </div>

  <span class="success__heading">{{ successHeading }}</span>

  <div class="success__text" *ngIf="successText">
    <span class="success__text-info">{{ successText }}</span>
    <span *ngIf="successAddress" class="success__text-info">{{ successAddress }}</span>
    <a *ngIf="link" [routerLink]="link" class="success__link">{{ textForLink }}</a>
  </div>

  <div class="success__buttons">
    <button *ngIf="!!firstMainButtonText" type="button" class="btn-main" (click)="firstMainButtonClick()">{{ firstMainButtonText }}</button>
    <button *ngIf="!!secondMainButtonText" type="button" class="btn-main" (click)="secondMainButtonClick()">{{ secondMainButtonText }}</button>
    <button *ngIf="!!buttonText" type="button" class="btn-secondary" (click)="clickEvent()">{{ buttonText }}</button>
    <button *ngIf="!!secondButtonText" type="button" class="btn-secondary" (click)="secondClickEvent()">{{ secondButtonText }}</button>
  </div>
</div>
