<div class="insurance-panel-wrap" *ngIf="cardInsurance.status !== 'NOT_APPROVED' && isVisible">
  <div class="insurance-inner">
    <div class="insurance-checkbox">
      <input type="checkbox"
             class="form__input-checkbox"
             id="insurance-switch"
             [checked]="cardProtection"
             [disabled]="showWarning() || this.spinner"
             (change)="toggleCardProtection()"
      >
      <label for="insurance-switch" class="form__label-checkbox"></label>
      <ng-container *ngIf="!cardProtection">
        <p>Защита карт от кражи</p>
      </ng-container>
      <ng-container *ngIf="cardProtection">
        <p>Карты защищены</p>
      </ng-container>
    </div>
    <div class="insurance-warning" *ngIf="showWarning()">
      К сожалению, включить защиту карт и обнулить комиссию можно только при пополнении меньше
      {{ cardInsurance.transaction_upper_bound / 100| moneySpaces }} ₽
    </div>
    <div class="insurance__items">
      <div class="insurance__item">
        <div class="insurance__spinner" *ngIf="showSpinner()">
          <div class="insurance__spinner-small"></div>
        </div>
        <span class="text text--gray" *ngIf="!showSpinner()">
          До {{ getInsuranceLimit() | moneySpaces }} ₽
        </span>
        <span class="insurance-premium">возместим в случае кражи</span>
      </div>
      <div class="insurance__item">
        <span class="text text--gray">Без комиссии</span>
        <span class="insurance-free">отменим комиссию за платеж</span>
      </div>
    </div>
    <button type="button" (click)="showSlider()">Как это работает ></button>
  </div>
</div>
