import { ProlongationConditionModel } from './prolongation-condition.model';
import { ConditionGroupModel } from './condition-group.model';
import { CreditInfoModel } from '../credits/credit-info.model';

export class ProlongationConditionResModel {
  constructor(
    public is_allowed: boolean,
    public conditions?: ProlongationConditionModel,
    public credit?: CreditInfoModel,
    public condition_group?: ConditionGroupModel[]
  ) {
  }
}
