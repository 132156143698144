import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  NgbDateParserFormatter,
  NgbDatepickerI18n,
  NgbDateStruct,
  NgbInputDatepicker
} from '@ng-bootstrap/ng-bootstrap';

import * as moment from 'moment';

import { DateParserFormatterMonths } from './date-parser-formatter-months';
import { RusDatepickerI18nMonths } from './i18n/rus-datepicker-i18n-months.service';

@Component({
  selector: 'datepicker-months',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker-months.component.scss'],
  providers: [
    {provide: NgbDatepickerI18n, useClass: RusDatepickerI18nMonths},
    {provide: NgbDateParserFormatter, useClass: DateParserFormatterMonths}
  ]
})
export class DatepickerMonthsComponent implements OnInit, OnChanges {
  @Input() form: FormGroup;
  @Input() controlName: string;
  @Input() cssClass: string;
  @Input() initValue: string;
  @Input() years = true;
  @Input() minDate: string;
  @Input() maxDate: string;
  @Input() disabled: boolean;

  @Output() selectedChange = new EventEmitter();

  model: NgbDateStruct;
  el: HTMLElement;
  val = '';

  @ViewChild('datepicker', {static: false}) datepickerInput: NgbInputDatepicker;

  constructor(private hostRef: ElementRef) {
  }

  ngOnInit(): void {
    this.el = this.hostRef.nativeElement;
    this.val = this.initValue;
    this.initDateModels();
  }

  ngOnChanges(): void {
    if (this.val !== this.initValue) {
      this.val = this.initValue;
      this.initDateModels();
    }
  }

  @HostListener('document:click', ['$event'])
  resolveClickFromOutside(event: MouseEvent) {
    const isClickedInside = this.el.contains(event.target as HTMLElement);
    if (!isClickedInside) {
      this.datepickerInput.close();
    }
  }

  initDateModels() {
    const time = moment(this.val, 'YYYY-MM-DD');
    this.model = {
      year: time.year(),
      month: time.month() + 1,
      day: time.date()
    };
  }

  onChange(date: NgbDateStruct) {
    if (!date) {
      return;
    }
    const datePayload = moment(`${date.month}-${date.day}`, 'MM-DD').format(
      'MM-DD'
    );
    this.selectedChange.emit(datePayload);
  }

  getDateStruct(value: string): NgbDateStruct {
    const date = moment(value, 'MM-DD');
    return {year: date.year(), month: date.month() + 1, day: date.date()};
  }
}
