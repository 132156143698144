import { Injectable } from '@angular/core';
import { EmailVerificationStatusEnum } from '../../enums/email-verification-status.enum';
import { Subject } from 'rxjs';
import { UserInfoModel } from '../../models/user-info.model';
import { AccountModel } from '../../models/account.model';
import { CreditInfoModel } from '../../models/credits/credit-info.model';
import { TokenModel } from '../../models/login/token.model';

@Injectable()
export class UserService {

  private _userInfo: UserInfoModel;
  private _creditInfo: CreditInfoModel;
  private _account: AccountModel;
  private _insuranceType: '' | 'kasko' | 'osago';
  private _CRED_ID: string;
  private _prolongation_id: string;
  private singlePaymentSource = new Subject();
  private _isCardInsurEnabled = false;
  private _tokenModel: TokenModel;

  callSinglePaymentUpdateEvent(value: number) {
    this.singlePaymentSource.next(value);
  }

  get creditInfo() {
    return this._creditInfo;
  }

  setCreditInfo(credit: CreditInfoModel) {
    this._creditInfo = credit;
  }

  get insuranceType() {
    return this._insuranceType;
  }

  setInsuranceType(type: '' | 'kasko' | 'osago') {
    this._insuranceType = type;
  }

  setCredId(id: string) {
    this._CRED_ID = id;
  }

  setProlId(id: string) {
    this._prolongation_id = id;
  }

  get userInfo(): UserInfoModel {
    return this._userInfo;
  }

  get account(): AccountModel {
    return this._account;
  }

  set isCardInsurEnabled(status: boolean) {
    this._isCardInsurEnabled = status;
  }

  get isCardInsurEnabled(): boolean {
    return this._isCardInsurEnabled;
  }

  setUserInfo(user: UserInfoModel) {
    if ('last_unconfirmed_email' in user && user.last_unconfirmed_email !== null) {
      Object.assign(user, {
        EMAIL: user.last_unconfirmed_email,
        is_email_confirmed: false,
      });
    }

    this._userInfo = user;
  }

  setAccount(account: AccountModel) {
    this._account = account;
  }

  /**
   * Возвращает адрес фактического проживания
   * @returns {string}
   */
  get userResidence(): string {
    const address = [
      this.userInfo.LIFE_INDEX,
      this.userInfo.LIFE_REGION,
      this.userInfo.LIFE_CITY,
      this.userInfo.LIFE_STREET
    ];

    return address.filter(Boolean).join(', ');
  }

  get isEmailFill(): boolean {
    return this.userInfo && this.userInfo.EMAIL && this.userInfo.EMAIL.length > 0;
  }

  setTokenModel(value: TokenModel) {
    this._tokenModel = value;
  }

  get tokenModel() {
    return this._tokenModel;
  }

  isEmailConfirmed() {
    const status = this.tokenModel.email_verification_status;

    return status === EmailVerificationStatusEnum.SKIP_VERIFICATION ||
           status === EmailVerificationStatusEnum.WAITING_FOR_OPTIONAL_CONFIRMATION  ||
           status === EmailVerificationStatusEnum.CONFIRMED;
  }
}
