import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateParserPipe } from './date-parser.pipe';
import { MoneySpacesPipe } from './money-spaces.pipe';
import { FilesizePipe } from './filesize.pipe';
import { HighlighterPipe } from './highlighter.pipe';

@NgModule({
  declarations: [
    DateParserPipe,
    MoneySpacesPipe,
    FilesizePipe,
    HighlighterPipe,
  ],
  exports: [
    DateParserPipe,
    MoneySpacesPipe,
    FilesizePipe,
    HighlighterPipe,
  ],
  imports: [
    CommonModule
  ],
})
export class PipesModule { }
