<div>
  <div class="info__file-items">
    <div class="info__file-item">
      <a class="info__file-doc" (click)="downloadFile()">
        <mat-icon  style="width: 17px; height: 21px" svgIcon="see-doc"></mat-icon>
      </a>
      <div class="info__file-name">
        {{ file.filename }}
      </div>
      <div class="info__file-size">
        {{ file.size | filesize }} МБ
      </div>
      <div class="info__file-nav" *ngIf="isCanDelete">
        <button class="btn btn--del"
                type="button"
                (click)="delete()"
        ></button>
      </div>
    </div>
  </div>
</div>
