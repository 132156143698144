import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

import * as moment from 'moment';
import { Injectable } from '@angular/core';

@Injectable()
export class DateParserFormatterMonths extends NgbDateParserFormatter {

  parse(): NgbDateStruct {
    return null;
  }

  format(date: NgbDateStruct): string {
    if (! date) {
      return '';
    }

    const month = `${date.month}`.toLowerCase();
    // tslint:disable-next-line
    const _date = `${date.year}-${month}-${date.day}`;
    return moment(_date, 'YYYY-MM-DD').format('D MMMM');
  }
}
