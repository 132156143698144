import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BusinessProcessLogModel } from '../models/business-process-log.model';
import { BusinessProcessEventModel } from '../models/business-process-event.model';

@Injectable({
  providedIn: 'root'
})
export class BusinessProcessLogService {

  private _activeStep: string;
  private _dealId: number;

  constructor(private http: HttpClient) {
  }

  set activeStep(value: string) {
    this._activeStep = value;
  }

  get activeStep() {
    return this._activeStep;
  }

  set dealId(value: number) {
    this._dealId = value;
  }

  get dealId() {
    return this._dealId;
  }

  bpLogInit(entity: BusinessProcessLogModel): Observable<any> {
    const url = `/bp_log/init`;

    return this.http.post(url, entity);
  }

  bpLogEvent(entity: any) {
    const url = `/bp_log/event`;

    return this.http.post(url, entity);
  }

  sendBpLogEvent(type: string, step: string, error?: string) {
    const eventModel = new BusinessProcessEventModel(this.dealId, type, step, error);

    this.activeStep = step;

    this.bpLogEvent(eventModel).subscribe(() => {});
  }
}
