import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CreditsResolver } from './modules/credits/credits.resolver';
import { EsignRequiredGuard } from './guards/esign-required-guard.service';
import { AuthGuard } from './guards/auth-guard.service';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/credits'
  },
  {
    path: 'auth',
    loadChildren: () => import('./modules/login/login.module').then((module) => module.LoginModule),
  },
  {
    path: 'accounts',
    loadChildren: () => import('./modules/accounts/accounts.module').then((module) => module.AccountsModule),
    canLoad: [AuthGuard, EsignRequiredGuard]
  },
  {
    path: 'certificates',
    loadChildren: () => import('./modules/certificates/certificates.module').then((module) => module.CertificatesModule),
    canLoad: [AuthGuard, EsignRequiredGuard]
  },
  {
    path: 'credits',
    loadChildren: () => import('./modules/credits/credits.module').then((module) => module.CreditsModule),
    canLoad: [AuthGuard],
    resolve: { dash: CreditsResolver }
  },
  {
    path: 'deposits',
    loadChildren: () => import('./modules/deposits/deposits.module').then((module) => module.DepositsModule),
  },
  {
    path: 'payments',
    loadChildren: () => import('./modules/payments/payments.module').then((module) => module.PaymentsModule),
    canLoad: [AuthGuard, EsignRequiredGuard]
  },
  {
    path: 'offers',
    loadChildren: () => import('./modules/offers/offers.module').then((module) => module.OffersModule),
    canLoad: [AuthGuard, EsignRequiredGuard]
  },
  {
    path: 'insurance',
    loadChildren: () => import('./modules/insurance/insurance.module').then((module) => module.InsuranceModule),
    canLoad: [AuthGuard, EsignRequiredGuard]
  },
  {
    path: 'repayments',
    loadChildren: () => import('./modules/repayments/repayments.module').then((module) => module.RepaymentsModule),
    canLoad: [AuthGuard, EsignRequiredGuard]
  },
  {
    path: 'settings',
    loadChildren: () => import('./modules/settings/settings.module').then((module) => module.SettingsModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'error',
    loadChildren: () => import('./modules/errors/errors.module').then((module) => module.ErrorsModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'documents',
    loadChildren: () => import('./modules/documents/documents.module').then((module) => module.DocumentsModule),
    canLoad: [AuthGuard, EsignRequiredGuard]
  },
  {
    path: 'prolongation',
    loadChildren: () => import('./modules/prolongation/prolongation.module').then((module) => module.ProlongationModule),
    canLoad: [AuthGuard, EsignRequiredGuard],
    data: { isProlongation: true }
  },
  {
    path: 'restructuring',
    loadChildren: () => import('./modules/prolongation/prolongation.module').then((module) => module.ProlongationModule),
    canLoad: [AuthGuard, EsignRequiredGuard],
    data: { isProlongation: false }
  },
  {
    path: 'request',
    loadChildren: () => import('./modules/requests/requests.module').then((module) => module.RequestsModule),
    canLoad: [AuthGuard, EsignRequiredGuard]
  },
  {
    path: 'restriction',
    loadChildren: () => import('./modules/restriction/restriction.module').then((module) => module.RestrictionModule),
    canLoad: [AuthGuard, EsignRequiredGuard]
  },
  {
    path: 'esign',
    loadChildren: () => import('./modules/esign/esign.module').then((module) => module.EsignModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'notifications',
    loadChildren: () => import('./modules/notifications/notifications.module').then((module) => module.NotificationsModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'account-closure',
    loadChildren: () => import('./modules/account-closure/account-closure.module').then((module) => module.AccountClosureModule),
    canLoad: [AuthGuard, EsignRequiredGuard]
  },
  {
    path: 'additional-agreements',
    loadChildren: () => import('./modules/additional-agreements/additional-agreements.module').then((module) => module.NotificationsModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'confirm',
    loadChildren: () => import('./modules/email-confirmation/email-confirmation.module').then((module) => module.EmailConfirmationModule)
  },
  {
    path: 'reset_password',
    loadChildren: () => import('./modules/reset-password/reset-password.module')
      .then((module) => module.ResetPasswordModule),
  },
  {
    path: '**',
    redirectTo: 'credits',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
