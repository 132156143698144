import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { BehaviorSubject, merge, Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { AcquiringOptionModel } from '../../models/insurance/acquiring-option.model';
import { AcquiringOptionsModel } from '../../models/insurance/acquiring-options.model';
import { WebsocketService } from '../websocket.service';
import { UserService } from '../auth/user.service';
import { SuccessFormModel } from '../../models/success-form.model';
import {
  ProlongationWebsocketResponseModel
} from '../../models/prolongation/prolongation-websocket-response.model';
import { HelperService } from '../helper.service';

@Injectable({
  providedIn: 'root'
})
export class AcquiringService {
  static fromBanner = false;

  /**
   * Сокет соединение для транша
   * @type {Observable<any>}
   */
  trancheSocket: Observable<any>;

  /**
   * Флаг характиризующий статус платежа
   * @type {boolean}
   */
  isPaymentInProgress: boolean;

  /**
   * Наблюдатель для списка опций
   * false - Если платёж создан
   * @type {BehaviorSubject}
   */
  options: BehaviorSubject<AcquiringOptionModel[]> = new BehaviorSubject<AcquiringOptionModel[]>(null);

  /**
   * Наблюдатель для выбранной опции
   * @type {BehaviorSubject}
   */
  selectedOption: BehaviorSubject<AcquiringOptionModel> = new BehaviorSubject<AcquiringOptionModel>(null);

  _options: AcquiringOptionModel[];

  /**
   * Ключи для localStorage
   * @type {Object}
   */
  public localStorage = {
    trackOption: 'acquiring_track_option',
    selectedOption: 'acquiring_selected_option'
  };

  /**
   * Цена продукта в копейках, заполняется при получении опций
   * @type {number}
   */
  public productPrice: number;

  constructor(
    private http: HttpClient,
    private helperService: HelperService,
    private userService: UserService,
    private socket: WebsocketService
  ) {}

  reset() {
    this.isPaymentInProgress = false;
  }

  /**
   * @return {void}
   */
  async getOptionsWithTranche(id: string, timerDelay = 60000, product: 'CASCO' = 'CASCO', successCallback?: CallableFunction): Promise<void> {
    try {
      const { product_price }: {
        product_price: number
      } = await this.sendOptionRequest(Number(id), product).toPromise();

      this.trancheSocket = this.socket.createObservableSocket();

      const timer$ = of({some: true}).pipe(delay(timerDelay));

      merge(this.trancheSocket, timer$)
        .subscribe(async (data: ProlongationWebsocketResponseModel | { some: boolean }) => {
        if (typeof data === 'string') {
          data = JSON.parse(data);
        }

        const isOverdue = typeof data === 'object' && 'some' in data;
        // @ts-ignore
        const isAcquiringReady = isOverdue ? false : data.type === 'ACQUIRING_OPTIONS_INFO' && data.status === 'ready';
        // @ts-ignore
        const isTrancheSuccess = isOverdue ? false : data.type === 'LOAN_LIMIT_TRANSH' && data.success === 'true';

        if (isOverdue || isAcquiringReady || isTrancheSuccess) {
          await this.getOptions(id, product);
          if (successCallback) { successCallback(product_price); }
        }
      });
    } catch (e) {
      throw new Error(e);
    }
  }

  /**
   * Запрос на получение эмуляции транша от ЦФТ
   * @param id - Идентификатор сущности
   * @param product - Идентификатор продукта
   * @return {Observable<any>}
   */
  sendOptionRequest(id: number, product: 'CASCO' = 'CASCO'): Observable<any> {
    const url = `/acquiring/options/request`;
    return this.http.post(url, {
      id,
      product
    });
  }

  /**
   * Отправляет запрос на получение данных об опции
   * @return {Promise}
   */
  getOption(id: number) {
    const url = `/acquiring/option`;
    const params = new HttpParams()
      .append('option_id', String(id));

    return this.http.get(url, {
      params,
    }).toPromise()
      .catch((e) => this.helperService.error('Не удалось загрузить выбранную опцию', e));
  }

  /**
   * Отправляет запрос на получение возможных вариантов оплаты страхового полиса
   * @param prolongationId - Идентификатор пролонгации
   * @param product - Идентификатор продукта
   * @return {Promise}
   */
  getOptions(prolongationId: string, product = 'CASCO', successCallback?: CallableFunction, errorCallback?: CallableFunction, queryParams = null) {
    const url = `/acquiring/options`;

    const params = new HttpParams()
      .append('id', prolongationId)
      .append('product', product);

    if (queryParams !== null && 'sf_id' in queryParams) {
      params.append('sf_id', localStorage.getItem('sf_banner_id') || queryParams.sf_id);
    }

    return this.http.get(url, {params})
      .toPromise()
      .then((res: AcquiringOptionsModel) => {
        if (res.payment_in_progress) {
          this.setTrackOptionToLocalStorage(res.payment_in_progress.option_id);
          this.isPaymentInProgress = true;
        }

        if (successCallback) {
          return successCallback(res);
        }

        this.setExistingOptions(res.options);
        this.productPrice = res.product_price;
      })
      .catch((error: HttpErrorResponse) => {
        if (error.status === 403) {
          this.setExistingOptions([]);
          errorCallback();
          return;
        }
        this.helperService.error('Не удалось получить возможные варианты оплаты страхового продукта.', error);
      });
  }

  /**
   * Отправляет информацию о выбранном варианте оплаты страхового полиса
   * @param trackTo - Идентификатор опции
   * @return {Promise}
   */
  sendTrackForOption(trackTo: number) {
    const url = `/acquiring/track`;
    return this.http.post(url, {
      option_id: trackTo,
    }).toPromise()
      .catch((error) => this.helperService.error('Не удалось выбрать опцию', error));
  }

  /**
   * Отправляет запрос на получение данных для пополнения с карты
   * @param id - Идентификатор опции
   * @return {Promise}
   */
  getPaymentSignature(id: number) {
    const url = `/acquiring/loan_limit/best2pay`;
    return this.http.post(url, {
      option_id: id,
    }).toPromise()
      .catch((error) => this.helperService.error('', error));
  }

  /**
   * Отправляет запрос на получение новых условий
   * @param id - Идентификатор опции
   * @return {void}
   */
  getLoanLimitConditions(id: number) {
    const url = `/acquiring/loan_limit/conditions`;
    const params = new HttpParams()
      .append('option_id', String(id));

    return this.http.get(url, { params })
      .toPromise()
      .catch((error) => this.helperService.error('', error));
  }

  /**
   * Отправляет запрос на получение данных для подтверждения по смс
   * @param id - Идентификатор опции
   * @return {void}
   */
  getSmsConfirmation(id: number, medata?: any) {
    const url = `/acquiring/loan_limit/finish`;
    return this.http.post(url, {
      option_id: id,
      metadata: medata
    }).toPromise()
      .catch((error) => this.helperService.error('', error));
  }

  /**
   * Отправяет список доступных опций слушателям
   * @param options - Массив с объектами опций
   * @return {void}
   */
  setExistingOptions(options: AcquiringOptionModel[]): void {
    this._options = options;
    this.options.next(options);
  }

  /**
   * Отправляет выбранную опцию слушателям
   * @param option - Объект опции
   * @return {void}
   */
  setSelectedOption(option: AcquiringOptionModel) {
    this.selectedOptionToLocalStorage = option;
    this.selectedOption.next(option);
  }

  set selectedOptionToLocalStorage(option: AcquiringOptionModel) {
    localStorage.setItem(this.localStorage.selectedOption, String(option.option_id));
  }

  get selectedOptionFromLocalStorage(): AcquiringOptionModel {
    const optionId = localStorage.getItem(this.localStorage.selectedOption);

    return this._options.find((option: AcquiringOptionModel) => {
      return option.option_id === Number(optionId);
    });
  }

  /**
   * Сохраняет идентификатор выбранной опции в localStorage
   * @param id - Идентификатор опции
   * @return {void}
   */
  setTrackOptionToLocalStorage(id: number) {
    localStorage.setItem(this.localStorage.trackOption, String(id));
  }

  /**
   * Возвращает значение выбранной опции
   * @return {number}
   */
  getTrackOptionFromStorage(): number {
    return Number(localStorage.getItem(this.localStorage.trackOption));
  }

  getSuccessModelForSmsConfirmation(status: 'overdue' | 'waiter' | 'waiter-resend' | boolean): SuccessFormModel {
    switch (status) {
      case true:
        return new SuccessFormModel(
          'success',
          'Полис КАСКО оформлен',
          // tslint:disable-next-line:max-line-length
          `Полис будет отправлен на вашу электронную почту ${this.userService.userInfo.EMAIL} в ближайшее время. \n График платежей обновлен`,
          null,
          null,
          'Продолжить работу',
        );
      case false:
        return new SuccessFormModel(
          'error',
          'Не удалось оформить полис КАСКО',
          `Оплаченная сумма будет возвращена на ваш счёт`,
          null,
          null,
          'Продолжить работу',
        );
      case 'overdue':
        return new SuccessFormModel(
          'await',
          'Полис КАСКО оформляется',
          'Результаты сообщим баннером и письмом',
          null,
          null,
          'Продолжить работу',
        );
      case 'waiter':
        return new SuccessFormModel(
          'waiting',
          'Производится оформление полиса КАСКО',
        );
      case 'waiter-resend':
        return new SuccessFormModel(
          'waiting',
          'Отправка смс',
        );
    }
  }
}
