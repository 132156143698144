import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PdpDetailsService } from '../../services/pdp-details.service';
import { ActiveRequestModel } from '../../models/active-application.model';
import { OperationsTypeEnum } from '../../enums/operations-type-enum';
import { HelperService } from '../../services/helper.service';

@Component({
  selector: 'app-active-application',
  templateUrl: './active-application.component.html',
  styleUrls: ['./active-application.component.scss']
})
export class ActiveApplicationComponent implements OnInit {

  @Input() requests: ActiveRequestModel[];

  @Output() callEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() newRequestEvent: EventEmitter<void> = new EventEmitter<void>();

  operationTypes = OperationsTypeEnum;

  constructor(
    private helpService: HelperService,
    private pdpService: PdpDetailsService
  ) { }

  ngOnInit() {
  }

  getBeatifyDate(date: string) {
    return this.helpService.beautifyDate(date, 0, 'YYYY-MM-DD', 'DD MMMM Y');
  }

  createListOfMonth(schedules: { date: Date; amount: number }[]) {
    return this.pdpService.createListOfMonth(schedules);
  }

  calculateSumForMonths(schedules: { date: Date; amount: number }[]) {
    return this.pdpService.calculateSumForMonths(schedules);
  }

  showCallInformation() {
    this.callEvent.emit();
  }

  newRequest() {
    this.newRequestEvent.emit();
  }

  getStringOfMonth(schedules: { date: Date; amount: number }[]) {
    return this.pdpService.getStringOfMonth(schedules);
  }
}
