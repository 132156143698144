import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { find, get, range } from 'lodash';
import * as moment from 'moment';
import { FormGroup } from '@angular/forms';
import { AppState } from '../enums/app-status.type';
import { BehaviorSubject } from 'rxjs';
import { v4 as uuid } from 'uuid';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ErrorFormService } from './error-form.service';
import { errorCodesConstant } from '../constants/error-codes.constant';
import { AlertModal } from '../components/alert-modal/alert-modal.component';
import { InitPayModel } from '../models/payments/init-pay.model';

const BACKSPACE_CODE = 8;
const NUMPAD_0 = 96;
const NUMPAD_9 = 105;
const DELETE_CODE = 46;
const TAB_CODE = 9;
const NUM_CODES = range('0'.charCodeAt(0), '9'.charCodeAt(0) + 1).concat(
  range(NUMPAD_0, NUMPAD_9 + 1)
);
const ARROW_CODES = range(37, 41);

@Injectable()
export class HelperService {

  private $appStatusSubject = new BehaviorSubject<AppState>(AppState.NORMAL);

  constructor(
    private router: Router,
    private formErrorService: ErrorFormService,
    private readonly dialog: MatDialog,
  ) {}

  public beautifyDate(date: string, slice = 0, srcFormat = 'YYYY-MM-DD', destFormat = 'DD MMMM'): string {
    if (!date) {
      return '';
    }

    return moment(date, srcFormat).format(destFormat).slice(0, slice > 0 ? slice : undefined);
  }

  public error(title: string, error: HttpErrorResponse) {
    const errorCode = get(error, 'error.error_code', '');
    const errorMessage = error && errorCode ? this.getErrorDescription(errorCode) : 'Проблема с интернет-соединением';

    this.formErrorService.errorMsg = { title, descr: errorMessage };

    this.router.navigate(['/error']);
  }

  getErrorDescription(code: string) {
    const result = find(errorCodesConstant, (value: Array<string>) => value[0] === code);
    return result && result.length > 0 ? result[1].toString() : '';
  }

  public alert(msg: string, title = 'Внимание', closeCallback: any = (e) => e) {
    this.dialog.open(AlertModal, {
      ...new MatDialogConfig(),
      data: { msg, title, closeCallback }
    });
  }

  /**
   * Склоняет существительные в зависимости от числа.
   * Например 1 месяц, 2 месяца, 5 месяцев.
   * @param {number} value число для проверки.
   * @param {string[]} nouns набор существительных в нужных формах: ['месяц', 'месяца', 'месяцев']
   * @returns {string} существительное нужного склонения.
   */
  public caseOfNoun(value: number, nouns: string[]) {
    let result = '';
    const hundreds = value % 100;
    if (hundreds >= 11 && hundreds <= 19) {
      result = nouns[2];
    } else {
      const tenths = value % 10;
      switch (tenths) {
        case (1): result = nouns[0]; break;
        case (2):
        case (3):
        case (4): result = nouns[1]; break;
        default: return nouns[2];
      }
    }
    return result;
  }

  strongPreventAlphaWithDots($event: KeyboardEvent) {
    const code = $event.keyCode;
    if (code === 190) {
      return true;
    }
    return this.preventAlpha($event);
  }

  preventAlpha($event: KeyboardEvent) {
    const code = $event.keyCode;
    if (code === TAB_CODE || code === BACKSPACE_CODE || ARROW_CODES.indexOf(code) > -1 || code === 13
      || code === DELETE_CODE || ($event.ctrlKey && code === 86) || ($event.metaKey && code === 86)) {
      return true;
    }
    if (NUM_CODES.indexOf(code) === -1) {
      $event.preventDefault();
      return false;
    }
    return true;
  }

  removeLettersFromAmount(event: any, control: string, group: FormGroup) {
    let value = event.target.value;
    value = value.replace(/,/g, '.')
                 .replace(/[^0-9.]/g, '')
                 .replace(/^([^.]*\.)(.*)$/, (_: any, b: string, c: string) => b + c.replace(/\./g, ''));

    if (value.length === 1 && value.indexOf('.') > -1) {
      value = '0.';
    }

    group.get(control).setValue(value);
  }

  fakeSuccessPayment(pay: InitPayModel) {
    return this.router.navigate(['/', 'payments', 'success'], {
      queryParams: {
        amount: pay.payment_money,
        day_of_month: pay.repeatable_day,
        condition_id: pay.card_insurance_condition_id
      }
    });
  }

  set appState(state: AppState) {
    this.$appStatusSubject.next(state);
  }

  get appState(): AppState {
    return this.$appStatusSubject.value;
  }

  generateUUID() {
    return uuid();
  }
}
