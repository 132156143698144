import {
  Component,
  EventEmitter,
  OnInit,
  Output
} from '@angular/core';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { Subject } from 'rxjs';
import { ScreenServiceService } from '../../services/screen-service.service';
import { WarningMessagesService } from './services';
import { WarningMessageModel } from './models';

@Component({
  selector: 'app-warning-screen',
  templateUrl: './warning-screen.component.html',
  styleUrls: ['./warning-screen.component.scss']
})
export class WarningScreenComponent implements OnInit {

  currentScreen: string;


  dataValue : WarningMessageModel = {
    icon: '',
    title: '',
    dscrOne: '',
    dscrTwo: '',
    btnClass: '',
    btnText: '',
    hasButton: true,
  }

  @Output() clickButton = new EventEmitter<void>();

  ngUnsubscribe$: Subject<void> = new Subject<void>();

  constructor(public screenService: ScreenServiceService,
              public warningMessageService: WarningMessagesService) {
  }

  ngOnInit(): void {
     this.getCurrentState();
  }

  getCurrentState() {
    this.screenService.status$
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(
        (screen) => {
        this.currentScreen = screen;
        this.dataValue = this.warningMessageService.showScreen(screen);
      });
  }


  performAction() {
    this.clickButton.emit();
  }
}
