import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { ErrorComponentModel } from './models';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

  @Input() caption?: string;
  @Input() text?: string;
  @Input() buttonText?: string;
  @Input() isBackButton?: boolean;
  @Input() isButton?: boolean;
  @Input() errorModel?: ErrorComponentModel;

  @Output() buttonEvent = new EventEmitter();
  @Output() backEvent = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  buttonHandler() {
    this.buttonEvent.emit();
  }

  back() {
    this.backEvent.emit();
  }
}
