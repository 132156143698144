import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { PaymentParamsModel } from '../../models/payments/payment-params.model';
import { BehaviorSubject } from 'rxjs';
import { PaymentFeeModel } from '../../models/payments/payment-fee.model';
import { PaymentModel } from '../../models/payments/payment.model';
import { InitPayModel } from '../../models/payments/init-pay.model';
import { SignatureModel } from '../../models/payments/signature.model';
import { ChangeCardNameModel } from '../../models/payments/change-card-name.model';
import { ProcessPaymentModel } from '../../models/payments/process-payment.model';
import { HelperService } from '../helper.service';

export interface PaymentDisclaimer {
  header: string;
  text: string;
}

@Injectable()
export class PaymentsService {
  paymentSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  disclaimer$: BehaviorSubject<PaymentDisclaimer> = new BehaviorSubject<PaymentDisclaimer>(null);
  // disclaimerSuccess$: BehaviorSubject<SignatureModel> = new BehaviorSubject<SignatureModel>(null);

  constructor(
    private http: HttpClient,
    private helperService: HelperService
  ) { }

  get disclaimer(): PaymentDisclaimer|null {
    return this.disclaimer$.value;
  }

  // get disclaimerSuccess(): SignatureModel|null {
  //   return this.disclaimerSuccess$.value;
  // }

  loadPayment(): Promise<PaymentModel> {
    const url = `/web/payments`;

    return this.http
      .get(url)
      .toPromise()
      .then((res: PaymentModel) => res)
      .catch((error) => {
        this.helperService.error('Не удалось загрузить информацию о картах', error);
        return Promise.reject(error);
      });
  }

  initPayment(pay: InitPayModel): Promise<SignatureModel> {
    const url = '/payment/init';

    return this.http
      .post(url, JSON.stringify(pay))
      .toPromise()
      .then(async (res: SignatureModel) => {
        if (res.provider !== 'DEMO') {
          return res;
        }
        // this.disclaimerSuccess$.next(res);
        await this.helperService.fakeSuccessPayment(pay);
      })
      .catch((error) => {
        this.helperService.error('Не удалось создать автоплатёж', error);
        return Promise.reject(error);
      });
  }

  cancelAutopay(id: number) {
    const url = '/payment/cancel';
    return this.http
      .post(url, JSON.stringify({id}))
      .toPromise()
      .then(() => this.helperService.alert('Автоплатёж отменён'))
      .catch((error) => this.helperService.error('Не удалось отменить автоплатёж', error));
  }

  deleteCard(id: number) {
    const url = '/payment/cards';
    const params = new HttpParams().append('id', id.toString());
    return this.http.delete(url, {params})
      .toPromise()
      .then(() => this.helperService.alert('Карта удалена'))
      .catch((error) => this.helperService.error('Не удалось удалить карту', error));
  }

  getCardInsurance(amount: number, referrer = 'MENU') {
    const url = '/card_insurance/conditions';
    const params = new HttpParams()
      .append('amount', amount.toString())
      .append('referrer', referrer);

    return this.http.get(url, { params });
  }

  changeCardName(id: number, name: string) {
    const url = '/payment/cards';
    return this.http
      .put(url, {id, name})
      .toPromise()
      .then(() => {
        this.helperService.alert('Имя карты сохранено');
        return new ChangeCardNameModel(name, id);
      })
      .catch((error) => this.helperService.error('Не удалось переименовать карту', error));
  }

  getPaymentParams(): Promise<PaymentParamsModel> {
    const url = `/payment/params`;

    return this.http
      .get(url)
      .toPromise()
      .then((res: PaymentParamsModel) => res)
      .catch((error) => {
        this.helperService.error('Не удалось получить параметры платежа', error);
        return Promise.reject(error);
      });
  }

  /**
   * Отправляет одноразовый платёж в best2pay
   * @param pay - Объект с данными для оплаты
   * @param blank - Открывать страницу best2pay в новом окне
   * @return {void}
   */
  processSinglePayment(pay: ProcessPaymentModel, blank = false) {
    const cardForm = document.createElement('form');
    cardForm.method = 'POST';
    cardForm.action = `${environment.best2payHost}/Purchase`;

    if (blank) {
      cardForm.target = '_blank';
    }

    this.generateInput('sector', pay.sector, cardForm);
    this.generateInput('id', pay.id, cardForm);
    this.generateInput('action', pay.action, cardForm);
    this.generateInput('fee', this.multiplyHundred(pay.fee), cardForm);
    this.generateInput('amount', this.multiplyHundred(pay.amount), cardForm);
    this.generateInput('currency', pay.currency, cardForm);
    this.generateInput('name', pay.name, cardForm);
    if (pay.token) {
      this.generateInput('token', pay.token, cardForm);
    } else {
      this.generateInput('pan', pay.pan, cardForm);
      this.generateInput('cvc', pay.cvc, cardForm);
      this.generateInput('month', pay.month, cardForm);
      this.generateInput('year', pay.year, cardForm);
    }
    this.generateInput('get_token', pay.get_token, cardForm);
    this.generateInput('signature', pay.signature, cardForm);

    document.body.appendChild(cardForm);
    cardForm.submit();
    document.body.removeChild(cardForm);
  }

  generateInput(name: string, value: string, form: HTMLElement) {
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = name;
    input.value = value;
    form.appendChild(input);
  }

  multiplyHundred(value: string) {
    return (parseFloat(value) * 100).toString();
  }

  getPaymentFee(is_repeatable: string,
                amount: string,
                payment_id?: string,
                sf_id?: string,
                card_insurance_condition_id?: string) {
    const url = `/payment/fee`;
    let params = {
      is_repeatable,
      amount,
    };

    if (payment_id !== 'undefined') {
      params = Object.assign({}, params, { payment_id });
    }

    if (sf_id) {
      params = Object.assign({}, params, { sf_id });
    }

    if (card_insurance_condition_id && +card_insurance_condition_id > 0) {
      params = Object.assign({}, params, { card_insurance_condition_id });
    }

    return this.http.get(url, { params })
      .toPromise()
      .then((fee: PaymentFeeModel) => {
        this.disclaimer$.next(fee.disclaimer);
        return fee;
      })
      .catch(e => {
        this.helperService.error('Не удалось получить информацию о комиссии', e);
        return Promise.reject(e);
      });
  }

  async activityLog() {
    const url = '/activity/log';
    await this.http.post(url, {
      DEVICE_OS: 'web',
      DEVICE_BUILD: 2003,
      DEVICE_TIMESTAMP: (new Date()).getTime(),
      ACTION: 'payment-disclaimer'
    }).toPromise();
  }

  cardInsuranceVerifySms(id: number) {
    const url = '/card_insurance/verify_sms';

    return this.http.post(url, { id });
  }
}
