import { AbstractControl, ValidatorFn } from '@angular/forms';
import * as luhn from 'luhn';

export function panValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const length = control.value.trim().length;
      return luhn.validate(control.value.trim()) && (length === 19 || length === 22 || length === 23 || length === 0)
              ? null : {pan: true};
    };
}
