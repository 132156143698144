import { Injectable } from '@angular/core';

@Injectable()
export class ErrorFormService {

  private _errorMsg: {title: string, descr: string};

  get errorMsg() {
    return this._errorMsg;
  }

  set errorMsg(error: {title: string, descr: string}) {
    this._errorMsg = error;
  }
}
