<ng-container *ngIf="extraServices.length > 0">
  <button class="right-bar-close" (click)="close()">
    <mat-icon svgIcon="closeBig"></mat-icon>
  </button>
  <div class="wrap">
    <div class="close-notation">
      <div class="close-notation__header">
        <div class="close-notation__title">Уведомление о приобретенных дополнительных услугах, работах, товарах</div>
        <div class="close-notation__title-sub">(далее – Уведомление)</div>
      </div>
      <div class="close-notation__info">
        Настоящим АО «Авто Финанс Банк» (далее - «Банк») уведомляет Вас о том, что Вы вправе отказаться от услуги (работы) либо от товара, не бывшего в употреблении (далее – «Сервис»/ «Сервисы») в течение 30 календарных дней со дня выражения Вами согласия на получение соответствующего Сервиса (далее – «Период охлаждения»), посредством обращения к лицу, предоставляющему такую услугу (выполняющего работу, реализующего товар) (далее – «ТСП»), с заявлением об отказе от такого Сервиса с учетом информации, указанной ниже
      </div>
      <div class="close-notation__items">
        <div *ngFor="let service of extraServices"
             class="close-notation__item"
        >
          <div class="close-notation__item-info">
            <div class="close-notation__item-title">
              Наименование Сервиса и ТСП
            </div>
            <div class="close-notation__item-dscr">
              {{ service.name }} {{ service.company_name }}
            </div>
          </div>
          <div class="close-notation__item-info">
            <div class="close-notation__item-title">
              Предельная дата для отказа от Сервиса
            </div>
            <div class="close-notation__item-dscr">
              {{ service.deadline_date | dateParser: 'YYYY-MM-DD' : 'DD.MM.YYYY' }}
            </div>
          </div>
          <div class="close-notation__item-info">
            <div class="close-notation__item-title">
              Информация о влиянии отказа от Сервиса на условия Кредитного договора
            </div>
            <div class="close-notation__item-dscr">
              {{ service.cancel_disclaimer ? service.cancel_disclaimer : 'Не применимо' }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

