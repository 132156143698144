<div class="notice">
  <div class="notice__header">
    <span class="notice__title">
      Внимание
    </span>
    <div>
      <button class="notice__close" (click)="close()">
        <img class="notice__close-icon" src="../../../../../assets/images/svg/cross.svg" alt="">
      </button>
    </div>
  </div>
  <div class="notice__content">
    <div class="notice__content-info">
      <div class="notice__content-info-dscr notice__content-info-dscr--gray">

      </div>
      <div class="notice__content-info-dscr">
        Ваша сессия истечет через {{ time ? time : 59 }} сек.
      </div>
      <div class="notice__content-info-dscr">
        Скоро мы разлогиним вас, чтобы аккаунтом не смогли воспользоваться посторонние.
      </div>
    </div>
    <div class="notice__answers">
      <button class="btn-secondary"
              (click)="goToDashboard()"
      >
        Продолжить работу
      </button>
    </div>
  </div>
</div>

