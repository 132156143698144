import { Directive, ElementRef, HostListener, OnInit, Renderer2 } from '@angular/core';

@Directive({ selector: 'input[autocomplete-off]' })
export class AutocompleteOffDirective implements OnInit {
  constructor(private _elRef: ElementRef, private _renderer: Renderer2) { }

  ngOnInit() {
    this._renderer.setAttribute(this._elRef.nativeElement, 'readOnly', 'true');
  }

  @HostListener('mouseenter', ['$event.target'])
  onMouseEnter(target: any) {
    target.readOnly = false;
  }

  @HostListener('focus', ['$event.target'])
  onFocus(target: any) {
    target.readOnly = false;
  }
}

