import { Injectable } from '@angular/core';
import { HelperService } from './helper.service';
import { DeviceMetadataModel } from '../models/device-metadata.model';

@Injectable()
export class DeviceMetadataService {
  constructor(private helperService: HelperService) {}

  getDeviceMetadata() {
    let uuid: string;
    if (localStorage.getItem('unique_device_id')) {
      uuid = localStorage.getItem('unique_device_id');
    } else {
      uuid = this.helperService.generateUUID();
      localStorage.setItem('unique_device_id', uuid);
    }
    return new DeviceMetadataModel(uuid);
  }
}
