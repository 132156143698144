<div>
  <input [class]="cssClass"
         ngbDatepicker
         #datepicker="ngbDatepicker"
         [ngModel]="model"
         (ngModelChange)="onChange($event)"
         [readonly]="true"
         [maxDate]="getDateStruct(maxDate)"
         [minDate]="getDateStruct(minDate)"
         [value]="val"
         [disabled]="disabled"
         (click)="datepicker.toggle()">
  <button class="input-group-addon btn btn--calendar"
          type="button"
          tabindex="-1"
          [disabled]="disabled"
          (click)="datepicker.toggle()"
  >
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21">
      <g fill="none" fill-rule="evenodd" stroke="#81898E" transform="translate(3 3)">
        <rect width="14" height="14" x=".5" y=".5" rx="2"/>
        <path stroke-linecap="square" d="M.5 3.5h14.036M4.292 7.5h.166M4.292 10.5h.166M7.292 7.5h.166M7.292 10.5h.166M10.292 7.5h.166M10.292 10.5h.166"/>
      </g>
    </svg>
  </button>
</div>
