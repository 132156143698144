import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UploadImageService } from './upload-image.service';
import { PassportPhotoResponseModel } from '../../models/documents/passport-photo-response.model';

@Component({
  selector: 'upload-image',
  templateUrl: './upload-image.component.html',
  styleUrls: ['./upload-image.component.scss']
})
export class UploadImageComponent implements OnInit {

  @Input() title: string;
  @Input() photo: string;

  @Output() imageLoaded = new EventEmitter<PassportPhotoResponseModel>();
  @Output() imageDeleted = new EventEmitter();

  @ViewChild('imageFile', { static: false }) imageFile: ElementRef;

  constructor(private uploadService: UploadImageService) { }

  ngOnInit() {
  }

  treatmentFiles(file: File) {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = () => {
      this.uploadService.loadImage(reader.result)
        .toPromise()
        .then((data: PassportPhotoResponseModel) => {
          this.imageLoaded.emit(data);
        });
    };
    reader.onerror = () => {
    };

  }

  onFileChange() {
    if (this.imageFile.nativeElement.files.length) {
      const file = this.imageFile.nativeElement.files[0];
      this.treatmentFiles(file);
    }
  }

  onFileDropped(event: DragEvent) {
    event.stopPropagation();
    event.preventDefault();
    event.dataTransfer.dropEffect = 'copy';
  }

  onFileSelectDrop(event: DragEvent) {
    event.stopPropagation();
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    this.treatmentFiles(file);
  }

  deleteImage() {
    this.photo = '';
    this.imageDeleted.emit();
  }
}
