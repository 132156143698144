import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { DepositModel } from "../../models/credits/deposit.model";

@Component({
  selector: 'app-sh-deposit-progress',
  templateUrl: './sh-deposit-progress.component.html',
  styleUrls: ['./sh-deposit-progress.component.scss']
})
export class ShDepositProgressComponent implements OnChanges {
  @Input() deposits: DepositModel[];
  @Input() loading?: boolean;

  depositTotalSum: number;
  depositProgress: { value: number, max: number };
  percentEarned: number;

  ngOnChanges(changes: SimpleChanges): void {
    this.depositTotalSum = this.deposits?.reduce((acc, currentValue) => (acc + currentValue.contract_amount), 0)
    this.percentEarned = this.deposits?.reduce((acc, curr) => (acc + curr.percents_earned), 0);
    const depositsIncomeSum = this.deposits?.reduce((acc, cur) => (acc + cur.deposit_income), 0);
    this.depositProgress = { value: this.percentEarned, max: depositsIncomeSum };
  }

}
