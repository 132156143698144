import { AfterViewChecked, Directive, ElementRef, HostListener } from '@angular/core';

@Directive({ selector: 'input[currency-input]' })
export class CurrencyInputDirective implements AfterViewChecked {
  el: HTMLInputElement;

  constructor(private _elRef: ElementRef) {}

  ngAfterViewChecked(): void {
    this.el = this._elRef.nativeElement;
    this.onInput();
  }

  @HostListener('input')
  onInput(): void {
    this.el.value = this.transform(this.el.value);
  }

  @HostListener('focus')
  onFocus() {
    this.el.value = this.parse(this.el.value);
  }

  @HostListener('focusout')
  onFocusOut() {
    this.el.value = this.checkEmpty(this.el.value);
  }

  transform(value: string) {
    const str = value
      .replace(',', '.')
      .replace(/[^\d\.]/g, '')
      .replace(/[\s]/g, '').split('.');

    if (str[0].length >= 4) {
      str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1 ');
    }

    if (str[1] && str[1].length >= 3) {
      str[1] = str[1].slice(0, 2);
    }

    const result = str.join('.');

    return result ? result : '';
  }

  parse(value: string) {
    return value === '0' ? '' : value;
  }

  checkEmpty(value: string) {
    return value === '' ? '0' : value;
  }
}
