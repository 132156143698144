export class EsignDocumentModel {
  constructor(
    public file_type?: string,
    public type?: string,
    public name?: string,
    public link?: string,
    public documents_version?: string,
    public request_id?: string,
    public mark_and_model?: string
  ) {}
}
