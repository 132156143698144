<div class="info__buttons">
  <label class="btn btn-secondary">
    Прикрепить файл
    <input class="form__download-input"
           type="file"
           multiple="multiple"
           name="file[]"
           accept="*/*"
           #files
           (change)="onFileSelected()"
    >
  </label>
</div>
