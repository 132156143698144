import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { AuthModel, ConfirmModel, MasterPassVerifyResponseModel } from '../../models';
import { ServerConstantsModel } from '../../models/server-constants.model';
import { DeviceMetadataService } from '../device-metadata.service';
import { AuthDeviceModel } from '../../modules/settings/components/session/models';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthApiService {

  constructor(
    private http: HttpClient,
    private deviceMetadataService: DeviceMetadataService,
  ) {
  }

  // Master pass
  // /auth/master_password/reset/step1
  // /auth/master_password/reset/step2_1
  // /auth/master_password/reset/step2_2
  createMasterPass(password: string): Promise<void> {
    const url = '/auth/master_password/create';

    return firstValueFrom(this.http.post<void>(url, { master_password: password }));
    // добавить обработку 400 мб
  }

  changeMasterPass(new_password: string, old_password: string): Promise<void> {
    const url = '/auth/master_password/change';

    return firstValueFrom(this.http.post<void>(url, { new_password, old_password }));
    // добавить обработку 400 мб
  }

  verifyMasterPass(password: string, operationId: string): Promise<MasterPassVerifyResponseModel> {
    const url = '/auth/master_password/verify';

    return firstValueFrom(this.http.post<MasterPassVerifyResponseModel>(url, { master_password: password, verify_operation_id: operationId }));
    // добавить обработку 400 мб
  }

  resetMasterPassword(account: string, client: string): Promise<void> {
    const url = '/auth/master_password/reset/step1';

    return firstValueFrom(this.http.post<void>(url, { CLIENT: client, contract_number: account }));
  }

  getServerConstants(): Promise<ServerConstantsModel> {
    const url = `/app/server_constants`;

    return firstValueFrom(this.http.get<ServerConstantsModel>(url));
  }

  logout() {
    const url = '/auth/logout';
    return firstValueFrom(this.http.post(url, {
      metadata: this.deviceMetadataService.getDeviceMetadata()
    }))
  }

  setPassword(password: string, token: string) {
    const url = '/web/auth/password';
    const headers = new HttpHeaders()
      .set('X-Client-Token', token);

    return firstValueFrom(this.http.post(url, JSON.stringify({
      password,
      metadata: this.deviceMetadataService.getDeviceMetadata()}
    ), { headers }));
  }


  init(login: string, deviceCookie: string) {
    const url = '/web/auth/init';
    const metadata = this.deviceMetadataService.getDeviceMetadata() ;

    return firstValueFrom(this.http
      .post(url, JSON.stringify({
        CLIENT: login,
        device_cookie: deviceCookie,
        metadata: metadata,
      })));
  }

  authCheck() {
    const url = '/web/auth/check';

    return firstValueFrom(this.http.post(url, JSON.stringify({})));
  }

  login(auth: AuthModel) {
    const url = '/web/auth/login';

    return firstValueFrom(this.http.post(url, JSON.stringify(auth)));
  }

  confirm(confirm: ConfirmModel) {
    const url = '/web/auth/confirm';

    return firstValueFrom(this.http.post(url, JSON.stringify(confirm)));
  }

  activateLogin(confirm: ConfirmModel) {
    const url = '/web/auth/activate';

    confirm.required_features = environment.required_features;

    return firstValueFrom(this.http.post(url, JSON.stringify(confirm)));
  }

  // todo: унести в собственный сервис апи
  /**
   * Получение списка сессий и девайсов
   */
  getDevices(): Promise<AuthDeviceModel[]> {
    const url = '/auth/devices';

    return firstValueFrom(
      this.http.get(url)
        .pipe(
          // @ts-ignore
          map(({devices}: AuthDeviceModel[]) => {
              return devices;
            }
          ),
        )
    );
  }

  /**
   * Закрытие сессии пользователя
   */
  closeSession(id: string) {
    const url = '/auth/devices/terminate';

    return firstValueFrom(
      this.http.post(url, JSON.stringify({id}))
    );
  }

  /**
   * Закрытие сессии пользователя
   */
  closeAllSessions() {
    const url = '/auth/devices/terminate_all';

    return firstValueFrom(
      this.http.post(url, JSON.stringify({}))
    );
  }
}
