import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'moneySpaces', pure: false })
export class MoneySpacesPipe implements PipeTransform {
  transform(value: any, format: 'rubles' | 'kopecks' = 'rubles', digitsGrouping = false) {
    let sumString = '0';
    if (!isNaN(value)) {
      let amount = value;
      if (format === 'kopecks') {
        amount = amount / 100; // Конвертация копеек в рубли
      }

      if(digitsGrouping) {
        const formatter = new Intl.NumberFormat('ru');
        sumString = amount !== 0 ? formatter.format(amount) : '0';
      } else {
        sumString = amount !== 0
          ? amount.toLocaleString('ru', {style: 'currency', currency: 'RUB'})
            .replace('₽', '')
            .replace('.', ',')
            .trim()
          : '0';
      }
    }
    return sumString;
  }
}
