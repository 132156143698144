import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-inform-notification',
  templateUrl: './inform-notification.component.html',
  styleUrls: ['./inform-notification.component.scss']
})
export class InformNotificationComponent implements OnInit {

  @Input() type: 'success' | 'waiting' | 'error' | 'await' | 'phone' = 'success';
  @Input() successHeading: string;
  @Input() successText: string;
  @Input() successAddress: string;
  @Input() link: string;
  @Input() textForLink: string;
  @Input() buttonText: string;
  @Input() secondButtonText: string;
  @Input() firstMainButtonText: string;
  @Input() secondMainButtonText: string;

  @Output() clickEventEmitter = new EventEmitter<void>();
  @Output() clickSecondEventEmitter = new EventEmitter<void>();
  @Output() firstMainButtonEvent = new EventEmitter<void>();
  @Output() secondMainButtonEvent = new EventEmitter<void>();

  ngOnInit(): void {
  }

  clickEvent() {
    this.clickEventEmitter.emit();
  }

  secondClickEvent() {
    this.clickSecondEventEmitter.emit();
  }

  firstMainButtonClick() {
    this.firstMainButtonEvent.emit();
  }

  secondMainButtonClick() {
    this.secondMainButtonEvent.emit();
  }
}
