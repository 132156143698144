import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import * as moment from 'moment';
import { AppState } from './enums/app-status.type';
import { IconsService } from './services/icon/icon.service';
import { AuthService } from './services/auth/auth.service';
import { UserService } from './services/auth/user.service';
import { HelperService } from './services/helper.service';

@Component({
  // tslint:disable-next-line
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
  constructor(
    public authService: AuthService,
    private readonly helperService: HelperService,
    private readonly userService: UserService,
    private iconsService: IconsService
  ) {
    this.iconsService.initialIcons();
  }

  async ngOnInit() {
    moment.locale('ru');

    this.authService.tempToken = '';

    this.checkAuth();
    this.checkClient();

    await this.authService.getServerConstants();
  }

  checkAuth() {
    this.authService.check(true);
  }

  get isLoggedIn() {
    return this.authService.isLoggedIn && this.userService.isEmailConfirmed();
  }

  private checkClient() {
    const sUsrAg = window.navigator.userAgent;
    if (sUsrAg.indexOf('Firefox') > -1) {
      return 'Mozilla Firefox';
      // "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0"
    } else if (sUsrAg.indexOf('Opera') > -1) {
      return 'Opera';
    } else if (sUsrAg.indexOf('Trident') > -1 || sUsrAg.indexOf('MSIE') > -1) {
      return 'Microsoft Internet Explorer';
      // "Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; Zoom 3.6.0; wbx 1.0.0; rv:11.0) like Gecko"
    } else if (sUsrAg.indexOf('Edge') > -1) {
      return 'Microsoft Edge';
      // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
    } else if (sUsrAg.indexOf('Chrome') > -1) {
      return 'Google Chrome or Chromium';
      // "Mozilla/5.0 (X11; Linux x86_64)
      // AppleWebKit/537.36 (KHTML, like Gecko)
      // Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36"
    } else if (sUsrAg.indexOf('Safari') > -1) {
      return 'Apple Safari';
      // "Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X)
      // AppleWebKit/605.1.15 (KHTML, like Gecko)
      // Version/11.0 Mobile/15E148 Safari/604.1 980x1306"
    } else {
      return 'unknown';
    }
  }

  get isIE(): boolean {
    return [
      'Microsoft Internet Explorer',
      'Microsoft Edge',
    ].includes(this.checkClient());
  }

  get isNormalMode(): boolean {
    return this.helperService.appState === AppState.NORMAL;
  }

  get isPendingMode(): boolean {
    return this.helperService.appState === AppState.PENDING;
  }
}
