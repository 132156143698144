import { Injectable } from '@angular/core';
import { TokenLifecycleModalComponent } from '../components/token-lifecycle-modal/token-lifecycle-modal.component';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Subject, timer } from 'rxjs';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { AuthService } from './auth/auth.service';
import { Router } from '@angular/router';
import { TokenLifecycleModalEnum } from '../components/token-lifecycle-modal/models';


@Injectable()
export class ExpiresTokenTimerService {

  private time: number;
  private hasOpenModal: boolean = false;
  private wasClosedModal: boolean = false;
  private matDialogRef: MatDialogRef<TokenLifecycleModalComponent>
  private countSeconds = new Subject<number>();
  private countSecondNumber$ = this.countSeconds.asObservable();
  private timeBeforeLogout: number = 60;

  ngUnsubscribe$: Subject<void> = new Subject();
  constructor(private readonly dialog: MatDialog,
              private authService: AuthService,
              private router: Router) {
  }

  setTimer(data: string) {
    this.wasClosedModal = false;
    this.ngUnsubscribe$.next();
    this.time = parseInt(data);
    timer(this.time, 1000)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((n) => {
        this.countSeconds.next(this.time - n)
        if (this.time - n < this.timeBeforeLogout && !this.hasOpenModal && !this.wasClosedModal) {
          this.matDialogRef= this.dialog.open(TokenLifecycleModalComponent, {
            ...new MatDialogConfig(),
            data: this.countSecondNumber$
          });
          this.hasOpenModal = true;
        }
        if (this.time - n < 1) {
          this.ngUnsubscribe$.next();
          this.hasOpenModal = false;
          this.wasClosedModal = false;
          // делает разлогин
          this.authService.logout();
        }
    });
    this.matDialogRef?.afterClosed().subscribe(res =>  {
      if (res && res === TokenLifecycleModalEnum.REDIRECT_TO_DASHBOARD) {
        // navigate to main page or additional request
        this.router.navigate(['/'])
        this.hasOpenModal = false;
        this.matDialogRef.close(true)
      }
      this.wasClosedModal = true;
    })
  }
}
