<div class="slider">
  <button class="btn btn--close" type="button" (click)="close()">
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
      <g fill="none" fill-rule="evenodd" stroke="#323A3F" stroke-linecap="round" stroke-opacity=".697">
        <path d="M.688.688l23.625 23.625M24.313.688L.688 24.313"/>
      </g>
    </svg>
  </button>
  <span class="slider__title">Страхование карт</span>
  <ngx-slick-carousel id="prolongation-carousel"
                      [config]="slideConfig"
                      (afterChange)="afterChange($event)"
  >
    <div ngxSlickItem *ngFor="let slide of slides; let i = index" (click)="changeFadeState()">
      <div class="slider__img">
        <img class="slider__image" src="{{ slide.src }}" alt="">
      </div>
    </div>
  </ngx-slick-carousel>
  <h3 class="slider__heading" [@fade]="fadeState">{{ getSlideCaption() }}</h3>
  <div class="slider__text-content" [@fade]="fadeState">
    <p class="text text--gray">{{ getSlideDiscription() }}</p>
  </div>
  <button type="button"
          class="btn-main"
          [disabled]="!isDisabled()"
          (click)="enabledCardProtection()"
  >
    Включить страхование карт
  </button>
</div>
