import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { OnetimePayFormService } from './onetime-pay-form.service';
import { FormGroup } from '@angular/forms';
import { CardListComponent } from '../card-list/card-list.component';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FixedPaymentModel } from '../../models/payments/fixed-payment.model';
import { CardInsuranceModel } from '../../models/payments/card-insurance.model';
import { PaymentsContainerService } from '../../services/payments/payments-container.service';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { PdpDetailsService } from '../../services/pdp-details.service';
import { PdpStatusModel } from '../../models/repayments/pdp-status.model';
import { BusinessProcessLogModel } from '../../models/business-process-log.model';
import { BusinessProcessLogService } from '../../services/business-process-log.service';
import { PaymentFeeModel } from '../../models/payments/payment-fee.model';
import { IframeInfoModel } from '../../models/iframe-info.model';
import { DomSanitizer } from '@angular/platform-browser';
import { UserService } from '../../services/auth/user.service';
import { AuthService } from '../../services/auth/auth.service';
import { PaymentModel } from 'src/app/models/payments/payment.model';
import { ProcessPaymentModel } from '../../models/payments/process-payment.model';
import { CardModel } from '../../models/payments/card.model';
import {
  ProlongationConfirmationModel
} from '../../models/prolongation/prolongation-confirmation.model';
import { PaymentsService } from '../../services/payments/payments.service';
import { PromoService } from '../../services/payments/promo.service';
import { CardInsuranceService } from '../../services/payments/card-insurance.service';
import { ChangeCardNameModel } from '../../models/payments/change-card-name.model';
import { HelperService } from '../../services/helper.service';
import { ValidatorService } from '../../services/validator.service';

@Component({
  selector: 'onetime-pay',
  templateUrl: './onetime-pay.component.html',
  styleUrls: ['./onetime-pay.component.scss'],
  providers: [OnetimePayFormService]
})
export class OnetimePayComponent implements OnInit, OnChanges, OnDestroy {

  @ViewChild(CardListComponent, {static: true}) cardList: CardListComponent;

  @Input() payment: PaymentModel;
  @Input() feeRatio: number;
  @Input() maxAmount: number;
  @Input() isSending: boolean;
  @Input() title = 'С карты';
  @Input() subtitle: string;
  @Input() fixedPayment: FixedPaymentModel;
  @Input() error: string;
  @Input() withoutWrap: boolean;
  @Input() pdpStatus: PdpStatusModel;
  @Input() isAcquiringPayment = false;

  @Output() processPaymentEvent = new EventEmitter<ProcessPaymentModel>();
  @Output() cardEditEvent = new EventEmitter<CardModel>();
  @Output() returnToPaymentsEvent = new EventEmitter<CardModel>();

  amount: number;
  fee: number;
  payGroup: FormGroup;
  insuranceLimit: number;
  selected: 'payment' | 'slider' | 'edit' | 'verify' | 'iframe' = 'payment';
  selectedCard = 0;
  onetimePayment: ProcessPaymentModel = new ProcessPaymentModel();
  cardInsurance: CardInsuranceModel = new CardInsuranceModel();
  spinner: boolean;
  isLastSlide: boolean;
  editableCard: CardModel;
  isDisabled = true;
  referrer = 'MENU';
  confirmationModel: ProlongationConfirmationModel;
  dealId: number;
  paymentFee: PaymentFeeModel = new PaymentFeeModel();
  selectedDocument: IframeInfoModel;

  private $unsubscribe: Subject<void> = new Subject<void>();

  private amountSource = new Subject<number>();
  private amountChange$ = this.amountSource.asObservable();

  constructor(
    private formService: OnetimePayFormService,
    private helperService: HelperService,
    private paymentsService: PaymentsService,
    private router: Router,
    private userService: UserService,
    private paymentsContainer: PaymentsContainerService,
    private promoService: PromoService,
    private cardInsuranceService: CardInsuranceService,
    private route: ActivatedRoute,
    private validateService: ValidatorService,
    private pdpService: PdpDetailsService,
    private bpLogService: BusinessProcessLogService,
    private authService: AuthService,
    private domSanitizer: DomSanitizer
  ) {
  }

  ngOnInit() {
    if ('referrer' in this.queryParams) {
      this.referrer = this.queryParams['referrer'];
    }

    if (this.userService.isCardInsurEnabled) {
      this.cardInsuranceService.protection = true;
    }

    if (this.pdpStatus) {
      this.amount = this.pdpStatus.extended_info.suggested_amount / 100;
    } else if (this.isFixed()) {
      this.amount = this.fixedPayment.amount;
    } else {
      this.amount = this.payment.nearest_payments[0] && this.payment.nearest_payments[0].amount
                    ? +this.payment.nearest_payments[0].amount
                    : 0;
    }

    this.loadCardInsurance();

    this.buildForm(this.amount);

    this.initAmountStream();

    this.cardInsuranceService.$protectionSubject.pipe(takeUntil(this.$unsubscribe)).subscribe({
      next: async () => {
        this.calculateFee();
        this.validateDisabledButton(this.payGroup.value);
      }
    });
  }

  ngOnChanges() {
    if (this.userService.isCardInsurEnabled) {
      this.cardInsuranceService.protection = this.userService.isCardInsurEnabled;
      this.paymentsContainer.updateCardsForm();
      this.userService.isCardInsurEnabled = false;
    }
  }

  ngOnDestroy(): void {
    if (this.cardProtection && this.authService.isLoggedIn) {
      this.bpLogService.sendBpLogEvent('BP_END', this.bpLogService.activeStep);
    }

    this.$unsubscribe.next();
    this.$unsubscribe.complete();
  }

  buildForm(amount: number) {
    this.payGroup = this.formService.buildForm(amount);
  }

  validateDisabledButton(values: any) {
    if (!('amount' in values)) {
      return '';
    }

    const isFieldsFilled = this.isLastSlide
                           ? ['pan', 'validity', 'cvc'].filter(control => !!this.payGroup.controls[control].value).length >= 3
                           : true;

    if (+values.amount <= 0 || +values.amount > this.maxAmount / 100 || !this.checkValid() || !isFieldsFilled) {
      return this.isDisabled = true;
    }

    this.isDisabled = false;
  }

  loadCardInsurance(changeProtection = true) {
    this.paymentsService.getCardInsurance(this.getAmount(), this.referrer).subscribe((cardInsurance: CardInsuranceModel) => {
      this.cardInsurance = cardInsurance;
    },
    (error) => {
      this.helperService.error('Не удалось получить условия страхования карт', error);
    });

    if (!this.isAcquiringPayment && !this.bpLogService.dealId) {
      const logModel = new BusinessProcessLogModel(
        'CARD_INSURANCE'
      );

      this.businessProcessLogInit(logModel);
    }

    if (this.router.url.indexOf('paymentSuggest') !== -1 || changeProtection === false) {
      return;
    }

    if (this.promoService.validateEmitter.isCompleted) {
      return this.cardInsuranceService.protection = this.promoService.isCardProtection;
    }

    if (this.cardInsurance && this.cardInsurance.conditions) {
      return this.cardInsuranceService.protection = this.cardInsurance.conditions.enable_by_default;
    }
  }

  businessProcessLogInit(logModel: BusinessProcessLogModel) {
    this.bpLogService.bpLogInit(logModel).subscribe((res: { deal_id: number }) => {
      this.dealId = res.deal_id;
      this.bpLogService.dealId = res.deal_id;

      if (!this.bpLogService.activeStep) {
        this.bpLogService.sendBpLogEvent('NEXT_STEP', 'CARD_INSURANCE_PAYMENT_SCREEN');
      }
    });
  }

  async calculateFee() {
    if (this.isFixed()) {
      this.fee = this.fixedPayment.fee;
      return true;
    }

    await this.paymentsService.getPaymentFee(
      String(+this.onetimePayment.is_repeatable),
      String(this.getAmount()),
      String(this.onetimePayment.id),
      this.promoService.bannerId,
      this.cardInsurance && this.cardInsuranceService.protection ? String(this.cardInsurance.conditions.id) : undefined,
    ).then((fee: PaymentFeeModel) => {
      this.paymentFee = fee;
      this.fee = fee.fee / 100;
    });
  }

  async amountChange(event: any) {
    this.helperService.removeLettersFromAmount(event, 'amount', this.payGroup);
    this.amount = +event.target.value.replace(/[\s]/g, '');
    this.spinner = true;


    setTimeout(async () => {
      this.amountSource.next(this.amount);
      this.spinner = false;
    }, 1000);

    if (this.amount > 36000) {
      this.cardInsuranceService.protection = false;
    }

    this.validateDisabledButton(this.payGroup.value);
  }

  initAmountStream() {
     this.amountChange$
      .pipe(
        debounceTime(300),
        distinctUntilChanged()
      ).subscribe(() => {
        this.loadCardInsurance(false);
        this.calculateFee();
     });
  }

  amountValid() {
    const amount = this.payGroup.get('amount').value.toString().replace(/\s/g, '');

    if (+amount * 100 > this.maxAmount) {
      return 'Введенная сумма превышает лимит в ' + (this.maxAmount / 100) + ' рублей.';
    }
  }

  /**
   * Transform amount to penny
   * @returns {number}
   */
  getAmount(): number {
    if (this.amount % 1 !== 0) {
      const prepareAmount = String(this.amount).replace('.', '');
      const diffLength = (
        String(Math.trunc(this.amount)).length + 2
      ) - prepareAmount.length;

      return diffLength > 0 ? +prepareAmount * 10 * diffLength : +prepareAmount;
    }

    return this.amount * 100;
  }

  getAmountToPay() {
    if (this.isFixed() || !this.cardInsuranceService.protection) {
      return parseFloat((this.amount + this.fee).toFixed(2));
    }

    return this.amount + this.getMonthlyPayment();
  }

  getMonthlyPayment() {
    return this.cardInsurance && this.cardInsurance.conditions
      ? this.cardInsurance.conditions.monthly_payment / 100
      : 0;
  }

  cardDelete(id: number) {
    const index = this.payment.cards.findIndex((card) => card.id === id);
    this.payment.cards.splice(index, 1);
  }

  showSlider(limit: number) {
    this.insuranceLimit = limit;
    this.selected = 'slider';
  }

  showInfo() {
    window.open(this.cardInsurance.conditions.conditions_page_link, '_blank');
  }

  close() {
    this.selected = 'payment';
    this.returnToPaymentsEvent.emit();
  }

  goToCardEdit(card: CardModel) {
    this.cardEditEvent.emit(card);
    this.editableCard = card;
    this.selected = 'edit';
  }

  setLastSlide(value: boolean) {
    this.isLastSlide = value;
  }

  assignValues() {
    const formValue = this.payGroup.value;
    const validity = formValue.validity;

    if (this.isLastSlide) {
      this.onetimePayment.month = validity.slice(0, 2);
      this.onetimePayment.year = '20' + validity.slice(-2);
      this.onetimePayment.pan = formValue.pan.replace(/ /g, '');
      this.onetimePayment.cvc = formValue.cvc;
    } else {
      this.onetimePayment.token = formValue.token;
    }

    this.onetimePayment.email = formValue.email ? formValue.email : undefined;
    this.onetimePayment.amount = formValue.amount.toString().replace(/[\s]/g, '');
    this.onetimePayment.fee = !this.cardInsuranceService.protection ? JSON.stringify(this.fee) : '0';

    if (this.cardInsuranceService.protection && +this.onetimePayment.amount <= 36000) {
      this.onetimePayment.card_insurance_condition_id = this.cardInsurance.conditions.id;
    }
  }

  checkValid() {
    return this.payGroup.valid;
  }

  onSubmit() {
    if (this.cardInsurance.status === 'APPROVED_CONDITIONALLY') {
      this.bpLogService.sendBpLogEvent(
        'ERROR',
        'CARD_INSURANCE_PURCHASE',
        `К сожалению, включить защиту карт и обнулить комиссию можно только при пополнении меньше ${this.cardInsurance.transaction_upper_bound} ₽`
      );
    }

    if (this.cardProtection) {
      this.bpLogService.sendBpLogEvent('NEXT_STEP', 'CARD_INSURANCE_PURCHASE');

      this.paymentsService.cardInsuranceVerifySms(this.cardInsurance.conditions.id)
        .subscribe((response: ProlongationConfirmationModel) => {
          this.confirmationModel = response;
          this.selected = 'verify';
        });
    } else {
      this.processPayment();
    }
  }

  processPayment() {
    this.assignValues();
    this.isDisabled = true;
    this.processPaymentEvent.emit(this.onetimePayment);
  }

  async setSelectedCard(index: number) {
    if (this.selectedCard === index) { return; }

    this.spinner = true;
    this.selectedCard = index;

    await this.loadCardInsurance();
    await this.calculateFee();

    this.spinner = false;
  }

  changeCardName(cardNameModel: ChangeCardNameModel) {
    this.payment.cards.map(card => {
      if (card.id === cardNameModel.cardId) {
        card.name = cardNameModel.cardName;
      }
      return card;
    });
  }

  isFixed(): boolean {
    return typeof this.fixedPayment !== 'undefined';
  }

  offProtection(): boolean {
    return this.isFixed() && this.fixedPayment.offProtection;
  }

  offEmailInput() {
    return (this.isFixed() && this.fixedPayment.offEmail) || this.userService.userInfo.EMAIL;
  }

  get cardProtection() {
    return this.cardInsuranceService.protection && !this.spinner;
  }

  get blockSubmit(): boolean {
    const confirm = this.payGroup.get('confirm').value;

    if (this.cardProtection) {
      return this.isDisabled || this.isSending || this.spinner || !confirm || !this.validateService.validate(this.payGroup);
    } else {
      return this.isDisabled || this.isSending || this.spinner || !this.validateService.validate(this.payGroup);
    }
  }

  get queryParams(): Params {
    return this.route.snapshot.queryParams;
  }

  async showDisclaimer() {
    if (this.paymentsService.disclaimer) {
      try {
        await this.paymentsService.activityLog();
      } catch (e) {
      }
      this.helperService.alert(this.paymentsService.disclaimer.text);
    }
  }

  beautifyDate(date: string) {
    return this.helperService.beautifyDate(date);
  }

  getStringOfMonth(schedules: { date: Date; amount: number }[]) {
    return this.pdpService.getStringOfMonth(schedules);
  }

  createListOfMonth(schedules: { date: Date; amount: number }[]) {
    return this.pdpService.createListOfMonth(schedules);
  }

  calculateSumForMonths(schedules: { date: Date; amount: number }[]) {
    return this.pdpService.calculateSumForMonths(schedules);
  }

  goToPayment() {
    this.selected = 'payment';
  }

  openInIframe() {
    const link = this.domSanitizer.bypassSecurityTrustResourceUrl(this.cardInsurance.conditions.key_document_link);
    this.selected = 'iframe';
    this.selectedDocument = new IframeInfoModel('600px', link, '', '');
  }

  returnToPayment() {
    this.selected = 'payment';
    this.selectedDocument = null;
  }
}
