<div class="card-wrapper" [formGroup]="cardsGroup">
  <ngx-slick-carousel id="card-carousel"
                      #slickModal="slick-carousel"
                      [config]="slideConfig"
                      (afterChange)="afterChange($event)"
  >
    <div *ngFor="let card of cards; let last = last"
         ngxSlickItem class="card-select slide"
         [ngClass]="{'last-slide': isLastSlide()}"
    >
      <div *ngIf="!last" class="card-btn" (click)="selectCard(card)">
        <div class="bank-logo">
          <img *ngIf="card.internal_name!!" [src]="card.logo">
        </div>
        <div class="card-edit-block">
          <p class="card-name" (click)="goToCardEdit(card)">
            {{ card.name }}
          </p>
          <mat-icon style="width: 15px;" svgIcon="card-edit" class="card-edit-btn" (click)="goToCardEdit(card)"></mat-icon>
        </div>
        <div class="card-provider-logo"></div>
        <span class="short-pan">*{{ card.short_pan }}</span>
      </div>
      <ng-container *ngIf="last">
        <div *ngIf="!isLastSlide()" class="card-btn card-btn-add"><span>Другая карта</span></div>
        <div *ngIf="isLastSlide()" class="card slide">
          <input id="valid" class="validity" type="text" autocomplete="cc-exp" x-autocompletetype="email" style="display: none">
          <input type="text" name="password" id="pass" style="display: none;" autocomplete="cc-csc"> <!--fake inputs for chrome buggy autofill-->
          <div class="card-row card-row-number">
            <label for="pan">Номер</label>
            <div class="underline" [ngClass]="{'underline-error': checkControlInvalid('pan')}">
              <input id="pan"
                     name="cc-name"
                     maxlength="23"
                     class="number"
                     formControlName="pan"
                     type="text"
                     underline-focus
                     x-autocompletetype="cc-number"
                     autocomplete="cc-number"
                     [mask]="'0000 0000 0000 0000 000'"
                     [dropSpecialCharacters]="false"
                     (focusout)="formIsValid()"
                     (keydown)="helperService.preventAlpha($event)"
              >
            </div>
          </div>
          <div class="card-row card-row-validity">
            <label for="validity">Срок действия</label>
            <div class="underline" [ngClass]="{'underline-error': checkControlInvalid('validity')}">
              <input id="validity"
                     class="validity"
                     type="text"
                     formControlName="validity"
                     underline-focus
                     maxlength="5"
                     autocomplete="cc-exp"
                     name="cc-exp"
                     [mask]="'00/00'"
                     [dropSpecialCharacters]="false"
                     (focusout)="formIsValid()"
                     (keydown)="enteringDate($event)"
              >
            </div>
          </div>
          <div class="card-row card-row-cvv">
            <label for="cvc">CVV</label>
            <div class="underline" [ngClass]="{'underline-error': checkControlInvalid('cvc')}">
              <input id="cvc"
                     class="cvv"
                     name="cvc"
                     type="password"
                     maxlength="3"
                     formControlName="cvc"
                     autocomplete="cc-csc"
                     underline-focus
                     (focusout)="formIsValid()"
              >
            </div>
          </div>

          <ng-container *ngIf="isLastSlide()">
            <div class="form__errors" *ngIf="amountInvalid === 1">
              <span *ngIf="formErrors?.['pan'] && cardsGroup.controls['pan'].touched">
                {{ formErrors?.['pan'] }}
              </span>
              <span *ngIf="formErrors?.['validity'] && cardsGroup.controls['validity'].touched">
                {{ formErrors?.['validity'] }}
              </span>
              <span *ngIf="formErrors?.['cvc'] && cardsGroup.controls['cvc'].touched">
                {{ formErrors?.['cvc'] }}
              </span>
            </div>
            <div class="form__errors" *ngIf="amountInvalid > 1 && amountTouched > 1">
              <span>Неправильно введены реквизиты карты.</span>
            </div>
          </ng-container>
          <div class="form__errors" *ngIf="!fitForPromo && isPromoLoaded">
            <span>Ваша карта не подходит под условия акции</span>
          </div>
        </div>
      </ng-container>
    </div>
  </ngx-slick-carousel>
</div>
<app-promo-card *ngIf="fitForPromo && isPromoLoaded && !!promo.icon_link && !!promo.promo_text"
                [promo]="promo"
></app-promo-card>
