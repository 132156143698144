<div class="caption-wrapper">
  <h3 *ngIf="credits.length == 0">Прогресс по кредиту</h3>
  <h3 *ngIf="credits.length > 0">Прогресс по кредитам</h3>
</div>
<div class="content-after">
  <div class="table-wrap">
    <ng-container *ngFor="let credit of credits">
      <ng-container *ngIf="credits.length > 0">
        <div class="row progress-row-wrap">
          <div class="col-md-8 table-cell-left"><p>{{ credit.PRODUCT_NAME }} · {{ credit.CONTRACT_NUMBER }}</p></div>
        </div>
      </ng-container>
      <ng-container *ngIf="+credit.OVERDUE_AM > 0">
        <div class="row progress-row-wrap">
          <div class="col-md-8 table-cell-left"><p>Задолженность</p></div>
          <div class="col-md-4 table-cell-right"><p class="color-red">{{ getOverdue(credit) | moneySpaces }} ₽</p></div>
        </div>
      </ng-container>
      <ng-container>
        <div class="row table-row-wrap">
          <div class="col-md-8 table-cell-left"><p>Выплачено</p></div>
          <div class="col-md-4 table-cell-right"><p>{{ (+credit.SUM_DOG - +credit.REMAINING_BALANCE) | moneySpaces }} ₽</p></div>
        </div>
      </ng-container>
      <div class="row table-row-wrap">
        <div class="col-md-8 table-cell-left"><p>Осталось</p></div>
        <div class="col-md-4 table-cell-right"><p>{{ +credit.REMAINING_BALANCE | moneySpaces }} ₽</p></div>
      </div>
      <ng-container *ngIf="+credit.OVERDUE_AM === 0">
        <div class="table-row-progress table-row-wrap">
          <progress id="progress-bar" class="progress-bar progress-bar-small"
                    value="{{ +credit.SUM_DOG - +credit.REMAINING_BALANCE }}"
                    max="{{ credit.SUM_DOG }}"
          ></progress>
        </div>
      </ng-container>
    </ng-container>
  </div>

</div>
