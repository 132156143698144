<section class="legit-info">
  <div class="application">
    <h3>Заявление на страхование «Защита карты партнер»</h3>
    <p>
      Прошу заключить договор страхования имущественных интересов, связанных с риском утраты или повреждения банковской карты ,
      утраты денежных средств со счета банковской карты, утраты наличных денежных средств ,
      полученных держателем банковской карты в банкомате, в соответствии с
      «Правилами комбинированного страхования имущественных интересов (финансовых рисков) держателей банковских карт»
      СПАО «Ингосстрах» от «22» июня 2018 (далее – Правила страхования) ,
      а также подтверждаю мое добровольное заявление на заключение договора страхования в соответствии с анкетой и  распоряжением ,
      указанным ниже.
    </p>
    <table>
      <tr>
        <th class="tr-head">ФИО страхователя</th>
        <td data-var>Иванов Иван Иванович</td>
      </tr>
      <tr>
        <th class="tr-head">Адрес регистрации страхователя</th>
        <td data-var>Россия, г. Москва, ул. Маршала Бирюзова, д. 10, кв. 5</td>
      </tr>
      <tr>
        <th class="tr-head">Фактический адрес страхователя</th>
        <td data-var></td>
      </tr>
      <tr>
        <th class="tr-head">Паспортные данные страхователя (серия, номер, дата выдачи, кем и когда выдан)</th>
        <td data-var></td>
      </tr>
      <tr>
        <th class="tr-head">Телефон</th>
        <td data-var></td>
      </tr>
      <tr>
        <th class="tr-head">Адрес электронной почты</th>
        <td data-var>matorak@yandex.ru</td>
      </tr>
      <tr>
        <th class="tr-head">Дата рождения</th>
        <td data-var>12.05.2001</td>
      </tr>
      <tr>
        <th class="tr-head">Выгодоприобретатель (при наличии)</th>
        <td>
          По риску «Утрата карты», «Хищение денежных средств» - страхователь.
          По риску «Списание денежных средств»  - страхователь/держатель счета,
          к которому выпущены застрахованные банковские карты
        </td>
      </tr>
      <tr>
        <th class="tr-head">Застрахованные риски</th>
        <td>
          Банковские карты считаются застрахованными от рисков:
    - «Утрата карты» (подп. «б», «в», «г» п. 4.4.1 Правил страхования),
    - «Списание денежных средств» (подп. «а», «б», «в», «г», «д» п. 4.4.2 Правил страхования),
    - «Хищение денежных средств» (п. 4.4.3 Правил страхования).
        </td>
      </tr>
      <tr>
        <th class="tr-head">Особые условия</th>
        <td>
          1. По риску, указанному в п. 4.4.3 Правил страхования, страховая выплата осуществляется при условии подтверждения факта снятия и суммы полученных денежных средств по застрахованной банковской карте в банкомате не ранее, чем за 2 часа до события хищения.
          2. По риску, указанному в абз. 2 подп. «в» п. 4.4.2 Правил, страховым признается только один случай несанкционированных транзакций в течение срока действия договора страхования. Все несанкционированные транзакции, совершенные в течение 24 часов с момента совершения первой такой транзакции являются одним случаем несанкционированных транзакций.
        </td>
      </tr>
      <tr>
        <th class="tr-head">Лимиты ответственности</th>
        <td>По риску «Хищение денежных средств» (п. 4.4.3 Правил) установлен лимит  в пределах 300 000 руб. на один страховой случай, но не более страховой суммы.
        </td>
      </tr>
      <tr>
        <th class="tr-head">Территория страхования</th>
        <td>Страхование распространяется на события, произошедшие на территории всего мира.</td>
      </tr>
      <tr><th>Начало полиса</th><td data-var>28.07.2018</td></tr>
      <tr><th>Окончание полиса</th><td data-var>27.08.2019</td></tr>
      <tr><th>Сумма страховой премии</th><td data-var>------ руб.</td></tr>
      <tr><th>Страховая сумма по договору по всем застрахованным рискам</th><td data-var>------ руб.</td></tr>
      <tr><th>Подтвердить необходимый период страхования</th><td data-var></td></tr>
    </table>
    <div>Мне разъяснено и понятно, что:
      <ul class="card-ins-list">
        <li>- я имею право отказаться от договора страхования в течение 14 календарных дней с даты заключения договора страхования с получением возврата страховой премии согласно п. 8.23 Правил страхования и Указанием Банка России № 3854-У от 20.11.2015 «О минимальных (стандартных) требованиях к условиям и порядку осуществления отдельных видов добровольного страхования»;</li>
        <li>- в случае если мною были предоставлены страховщику заведомо ложные сведения при оформлении договора, страховщик вправе потребовать признания договора недействительным в порядке, предусмотренном законодательством РФ.</li>
        <li>- при наступлении страхового случая ущерб/убытки возмещается в полном объеме в пределах страховой суммы и с учетом лимитов выплат, установленных по отдельным рискам;</li>
        <li>- если банковские карты на дату страхового случая будут застрахованы у одного или  нескольких страховщиков по нескольким договорам страхования, из которых вытекает обязанность страховщиков выплатить страховое возмещение за одни и те же последствия наступления одного и того же страхового случая (двойное страхование), то: а) договор является ничтожным в той части страховой суммы, которая превышает страховую стоимость; б) сумма страхового возмещения, подлежащая выплате в этом случае каждым из страховщиков, сокращается пропорционально уменьшению первоначальной страховой суммы по соответствующему договору страхования (ст. 951, ст. 952 ГК РФ).</li>
      </ul>
      <p>Подавая настоящее заявление и оплачивая страховую премию, я подтверждаю свое распоряжение на заключение договора страхования согласно моему выбору, указанному в настоящем заявлении, а также подтверждаю, что все сведения, указанные в настоящем заявлении, являются полными и достоверными, с условиями «Правил комбинированного страхования имущественных интересов (финансовых рисков) держателей банковских карт» и договора ознакомлен и согласен, условия страхования доведены до меня полностью в приемлемой для меня форме.</p>
      <br>
      <label class="label">Мне также разъяснено и понятно, что с Правилами страхования я могу дополнительно ознакомиться на сайте https://www.ingos.ru/Upload/info/pravila_bankcard/bankcards-insurance-rules.pdf. и в моем Личном кабинете на указанном сайте страховщика. Все сообщения и уведомления, договоры страхования и другую документацию, и информацию прошу направлять мне на мой электронный адрес, указанный в настоящем заявлении.</label>
      <p>В случае расторжения настоящего Договора возврат оплаченной суммы страховой премии производится в соответствии с Правилами страхования.</p>
      <p>При неоплате страховой премии, либо оплате ее в сумме меньшей, чем предусмотрено договором, договор считается не заключённым.</p>
    </div>
  </div>
  <div class="agreement">
    <h3>Согласие на обработку персональных данных</h3>
    <ol>
      <li>Заключая договор страхования (подавая Заявление на страхование) на основании условий (Правил),
    я (страхователь) даю своё согласие и подтверждаю согласие Выгодоприобретателя(ей), названных в
    договоре страхования, с тем, что Страховщик (СПАО «Ингосстрах», 117997, г. Москва, ул. Пятницкая,
    д. 12, стр. 2, ИНН 7705042179, КПП 770501001, ОГРН 1027739362474) может осуществлять обработку
    указанных в моем Заявлении и Договоре страхования персональных данных физических лиц в течение
    всего срока действия договора страхования и последующие 50 (пятьдесят) лет с момента прекращения
    действия договора.</li>
      <li>Под персональными данными Страхователь, Выгодоприобретатель(и) и Водитель(и), названные в
    договоре страхования, понимают указанную договоре страхования информацию, относящуюся к прямо
    или косвенно определенному или определяемому из названных в договоре страхования Страхователю,
    Выгодоприобретателю, в том числе, фамилия, имя, отчество, год, месяц, дата и место рождения, пол,
    адрес, номер телефона, адрес электронной почты, семейное, социальное, имущественное положение,
    наличие детей, образование, профессия, доходы, паспортные данные.</li>
      <li>Страхователь обязуется предоставить Страховщику названные в настоящей статье согласия
    физических лиц (Выгодоприобретателя(ей) и Водителя(ей)) и несет персональную ответственность за
    неисполнение или ненадлежащее исполнение указанного обязательства.</li>
      <li>Под обработкой персональных данных Страхователь и Выгодоприобретатель(и), названные в договоре
    страхования понимают: сбор, запись, систематизацию, накопление, хранение, уточнение (обновление,
    изменение), извлечение, использование, передачу, обезличивание, блокирование, удаление,
    уничтожение персональных данных.</li>
      <li>Обработка персональных данных осуществляется с использованием средств автоматизации или без
    использования таких средств в целях изменения, продления, досрочного прекращения договора
    страхования, продвижения услуг Страховщика на рынке путем осуществления прямых контактов со
    Страхователем и Выгодоприобретателем(ями) с помощью средств связи, в статистических и иных
    исследовательских целях, в т.ч. в целях проведения анализа страховых рисков, а также в целях
    информирования о других продуктах и услугах Страховщика, получения рассылок, направленных на
    повышение уровня клиентоориентированности и лояльности, включая проведение исследований
    (опросов) в области сервисных услуг и их качества, предоставляемых Страховщиком по договору
    страхования, а также об условиях продления правоотношений со Страховщиком.</li>
      <li>Заключая договор страхования на основании настоящих Правил, Страхователь дает своё согласие и
    подтверждает согласие Выгодоприобретателя(ей)), названных в договоре страхования, на
    информирование о других продуктах и услугах, на получение рекламы по сетям электросвязи, а также
    рассылок, направленных на повышение уровня клиентоориентированности и лояльности, включая
    проведение исследований (опросов) в области сервисных услуг и их качества, предоставляемых
    Страховщиком по договору страхования, а также об условиях продления правоотношений со
    Страховщиком. В том числе, Страхователь в случае регистрации в личном кабинете СПАО
    «Ингосстрах» дает свое согласие на открытие доступа к своим персональным данным (публикацию)
    путем ввода идентифицирующих Страхователя данных при регистрации в соответствующем разделе
    сайта <a href="www.ingos.ru">www.ingos.ru</a> (в том числе, с использованием специализированного программного обеспечения,
    предоставленного Страховщиком).</li>
      <li>Заключая договор страхования на основании настоящих Правил, Страхователь соглашается с
    передачей Страховщиком персональных данных Страхователя и данных о наличии других договоров
    страхования, необходимых для расчета суммы страховой премии, оценки рисков или иных целей,
    связанных с заключением или исполнением договора страхования, ставших ему известными в связи с
    заключением и исполнением договора страхования, третьим лицам, с которыми у Страховщика
    заключены соответствующие соглашения, обеспечивающие надежное хранение и предотвращение
    незаконного разглашения (конфиденциальность) персональных данных, включая третьих лиц,
    проводящих работу по передаче или записи информации в информационную систему Страховщика.</li>
      <li> Страхователь соглашается с тем, что Страховщик может с соблюдением требований, предусмотренных
    действующим законодательством Российской Федерации, осуществлять обработку, в т.ч. сбор иных
    1сведений и информации, необходимых для заключения договора страхования, оценки страховых
    рисков, расчета страховой премии, определения размера убытков или ущерба, включая специальные
    категории персональных данных, в том числе, содержащихся в кредитной истории Страхователя, в
    автоматизированной информационной системе ОСАГО, в Бюро страховых историй и общедоступных
    источниках.</li>
      <li>Страховщик обязуется обеспечивать сохранность и неразглашение персональных данных
    Страхователя в целях иных, нежели предусмотрены настоящей статьей.</li>
      <li>Указанные в настоящей статье согласия действуют со дня выдачи согласия до дня истечения
    пятидесятилетнего периода, исчисляемого с момента прекращения действия договора страхования
    либо с момента получения Страховщиком заявления об отзыве согласия на обработку персональных
    данных. Согласие на обработку персональных данных может быть отозвано субъектом
    персональных данных полностью или в части информирования о других продуктах и услугах,
    путем направления письменного заявления Страховщику способом, позволяющим достоверно
    установить дату получения данного заявления Страховщиком.</li>
      <li> В случае полного отзыва субъектом персональных данных своего согласия на обработку персональных
    данных, действие договора страхования в отношении такого лица прекращается, а в случае отзыва
    такого согласия субъектом персональных данных, являющимся Страхователем, договор страхования
    прекращается полностью, за исключением случаев, когда согласно законодательству Российской
    Федерации, получение согласия субъекта персональных данных на их обработку не требуется. При
    этом действие договора страхования прекращается досрочно с даты получения Страховщиком
    соответствующего заявления об отзыве согласия на обработку персональных данных.</li>
      <li>После прекращения действия договора страхования (в том числе при его расторжении), а также в
    случае отзыва субъектом персональных данных согласия на обработку своих персональных данных,
    Страховщик обязуется уничтожить такие персональные данные в срок, не превышающий 50
    (пятидесяти) лет с момента прекращения действия договора либо с момента получения Страховщиком
    заявления об отзыве согласия на обработку персональных данных.</li>
      <li>Договором страхования могут быть определены иные (уточненные) условия обработки персональных
    данных, не противоречащие законодательству Российской Федерации.</li>
    </ol>
  </div>
  <div class="rules">
    <h3>Правила комбинированного страхования имущественных интересов (финансовых рисков) держателей
      банковских карт.</h3>
    <div class="rules-block">
      <h4 class="rules-heading">ОБЩИЕ ПОЛОЖЕНИЯ</h4>
      <ul class="main-list">
        <li>1.1. Настоящие «Правила комбинированного страхования имущественных интересов
  (финансовых рисков) держателей банковских карт» (далее – Правила) определяют общие
  условия и порядок осуществления страхования, связанного с риском утраты или повреждения
  банковской карты, утраты денежных средств со счета банковской карты, утраты наличных
  денежных средств, полученных держателем банковской карты в банкомате, а также риском
  возникновения непредвиденных расходов вследствие утраты документов и ключей, если они
  были застрахованы и украдены вместе с банковской картой.
  Страхование, осуществляемое в соответствии с настоящими Правилами, относится к
  следующим видам страхования:
  - страхование имущества граждан, за исключением транспортных средств;
  - страхование финансовых рисков.</li>
        <li>1.2. Определения, используемые в настоящих Правилах:
          Банковская карта – инструмент безналичных расчетов, предназначенный для
          совершения физическими лицами, в том числе уполномоченными юридическими лицами
          (держателями банковских карт), операций с денежными средствами, находящимися у эмитента
          банковской карты, в соответствии с законодательством РФ и договором с эмитентом
          банковской карты.
          Застрахованная банковская карта – Банковская карта, указанная в договоре
          страхования.
          Расчетная (дебетовая) банковская карта – банковская карта, выданная владельцу
          средств на банковском счете, предназначенная для совершения операций ее держателем в
          пределах установлен-ной эмитентом банковской карты суммы денежных средств (расходного
          лимита), расчеты по кото-рым осуществляются за счет денежных средств держателя,
          находящихся на его банковском счете, или кредита, предоставляемого эмитентом банковской
          карты держателю в соответствии с договором банковского счета при недостаточности или
          отсутствии на банковском счете денежных средств (овердрафт).
          Кредитная банковская карта – банковская карта, использование которой позволяет
          держателю банковской карты совершать операции, расчеты по которым осуществляются за счет
          денежных средств, предоставленных эмитентом банковской карты держателю в пределах
          установленного лимита в соответствии с условиями кредитного договора.
          Основная карта – расчетная (дебетовая) или кредитная карта, держателем которой
          является владелец счета, соответствующего данной банковской карте.
          Дополнительная карта - расчетная (дебетовая) или кредитная карта, выдаваемая
          дополнительно к основной карте, держателем которой является третье лицо, не являющееся
          владельцем счета, но имеющее предоставленное владельцем право распоряжения средствами,
          учитываемыми на счете в полном или ограниченном объеме.
          Держатель банковской карты – физическое лицо, использующее банковскую карту на
          основании договора с эмитентом банковской карты.
          Идентификатор держателя банковской карты (персональный идентификационный
          номер – PIN-код) – кодированный номер, присваиваемый держателю банковской карты для
          удаленной идентификации, формируемый обычно из четырех цифр; такой номер выдается
          держателю банковской карты в запечатанном непрозрачном конверте одновременно с
          банковской картой, именно этот номер является кодом/паролем, который предлагается набрать
          держателю банковской карты в банкомате, в пункте покупки товаров.
          Несанкционированное списание денежных средств – противоправные действия
          третьих лиц, связанные с незаконным (несанкционированным) доступом третьих лиц к Счету
          Держателя Основной банковской карты.
          Скимминг – один из видов мошенничества с пластиковыми картами, при котором
          используется скиммер — инструмент для считывания магнитной дорожки Банковской карты, а
          Правила комбинированного страхования имущественных интересов (финансовых рисков) держателей
          банковских карт.
          также приспособление, установленное на клавиатуру, в виде специальных накладок/ насадок,
          которые внешне повторяют оригинальные кнопки банкомата, или незаконно установленные
          видеокамеры для снятия (получения информации) PIN- кода.
          Фишинг – вид интернет-мошенничества, цель которого — получить
          идентификационные данные пользователей. Организаторы фишинг-атак используют массовые
          рассылки электронных писем от имени популярных брендов и т.п.
          Стоп-лист – банковский реестр с номерами блокированных банковских карт.
          Счет (Банковский счет, Счет Карты) – открытый на имя держателя основной
          банковской карты банковский счет Банком – эмитентом банковской карты при заключении
          договора банковского обслуживания, используемый для учета операций, совершаемых с
          использованием банковской карты и/или реквизитов банковской карты, и проведения расчетов
          в соответствии с договором, заключенным между держателем банковской карты и Банком.
          Транзакция – операция, совершенная с использованием банковской карты, по переводу
          денежных средств с одного банковского счета на другой, оплате товаров/ услуг в торговосервисных точках, а также снятию денежных средств со счета.
          Третьи лица – любые лица, за исключением Страхователя, Выгодоприобретателя,
          членов их семей, работников Страхователя, совершающие противоправные действия в
          отношении Держателя банковской карты, а также в отношении его имущества (пластиковой
          карты и денежных средств на банковском счете Держателя банковской карты, открытого для
          учета операций, совершаемых с использованием банковской карты и/или реквизитов
          банковской карты, и (или) Личных документов либо Ключей).
          Личные документы – документы, принадлежащие Застрахованному: паспорт,
          загранпаспорт, водительское удостоверение, документы, свидетельствующие о регистрации
          транспортного средства и прохождении государственного технического осмотра, а также
          другие документы, указанные в Договоре страхования, для восстановления которых
          необходимо обращение в соответствующие государственные органы и оплата государственных
          пошлин и, если таковые имеются, сопутствующих расходов.
          Ключи от движимого и/или недвижимого имущества
          - ключи от основного места проживания (место постоянной или временной регистрации)
          Застрахованного или от дома, квартиры, иного жилого помещения, имущественные права
          Застрахованного на которые установлены правоустанавливающими документами (в т. ч.
          договором аренды, договором безвозмездного пользования и т.п.);
          -ключи от транспортного средства, принадлежащего Застрахованному, либо
          транспортного средства, к управлению которым допущен Застрахованный в предусмотренном
          законом порядке.
          Лимит ответственности (лимит страхового возмещения) — максимально возможный
          размер страховой выплаты по одному страховому случаю в пределах страховой суммы.
          Члены семьи – лица, проживающие совместно со страхователем
          (выгодоприобретателем) и/или лица, ведущие с ним совместное хозяйство, близкие
          родственники (супруг(а) (за исключением бывших супругов), родственники по прямой
          восходящей и нисходящей линии (родители, дети, дедушка, бабушка и внуки), полнородные и
          неполнородные (имеющих общих отца или мать) братья и сестры, усыновители и
          усыновленные), лица, находящихся на иждивении страхователя (выгодоприобретателя).
          Мобильный банк – услуга дистанционного доступа держателя банковской карты к
          своему счету банковской карты и другим сервисам Банка, предоставляемая Банком держателю
          банковской карты с использованием мобильной связи по номеру(-ам) мобильного(-ых)
          телефона(-ов).
          Интернет-банк – услуга дистанционного доступа держателя банковской карты к своему
          счету банковской карты и другим продуктам в Банке, предоставляемая Банком держателю
          банковской карты через глобальную информационно-телекоммуникационную сеть Интернет.
          Франшиза – определённая в Договоре страхования часть убытков, которая не подлежит
          Правила комбинированного страхования имущественных интересов (финансовых рисков) держателей
          банковских карт.
          возмещению Страховщиком Страхователю или Выгодоприобретателю, и устанавливается в
          виде определенного процента от страховой суммы или в фиксированном размере. Если иное не
          предусмотрено Договором страхования, то франшиза в нём считается безусловной.</li>
        <li>1.3. Толкование применяемых терминов в отсутствие их определения в настоящих
  Правилах осуществляется в соответствии с нормами действующего законодательства
  Российской Федерации. В случае отсутствия определения какого-либо термина в действующем
  законодательстве Российской Федерации и нормативных актах, значение соответствующего
  термина определяется в соответствии с его общепринятым лексическим значением.</li>
        <li>1.4. Под договором страхования (далее – Договор) в настоящих Правилах понимается
  соглашение между Страхователем и Страховщиком, согласно которому Страховщик за
  обусловленную Договором плату (страховую премию) при наступлении предусмотренного в
  Договоре события (страхового случая) обязуется возместить Страхователю
  (Выгодоприобретателю, Застрахованному лицу) в пределах определенной Договором страховой
  суммы причиненный вследствие этого события ущерб застрахованному имуществу в размере
  (ограничении) и порядке, определенными настоящими Правилами и/или Договором
  страхования.</li>
        <li>1.5. Под ущербом (реальным ущербом) в настоящих Правилах понимаются расходы,
  которые лицо, чье право нарушено, произвело или должно будет произвести для
  восстановления нарушенного права в связи с утратой или повреждением имущества в пределах,
  установленных в соответствии с законодательством Российской Федерации, настоящими
  Правилами и/или Договором страхования.</li>
      </ul>
    </div>
    <div class="rules-block">
      <h4 class="rules-heading">СУБЪЕКТЫ СТРАХОВАНИЯ</h4>
      <ul class="main-list">
        <li>2.1. Страховщиком по договорам страхования, заключаемым в соответствии с
  настоящими Правилами, является СПАО «Ингосстрах» - страховая организация, созданная в
  соответствии с законодательством Российской Федерации для осуществления деятельности по
  страхованию, зарегистрированная в г. Москве и получившая лицензию на осуществление
  соответствующего вида страховой деятельности в установленном законодательством
  Российской Федерации порядке (далее – Страховщик). Договоры страхования от имени
  Страховщика могут заключать его уполномоченные сотрудники.</li>
        <li>2.2. Страховщик осуществляет оценку страхового риска, получает страховые премии,
  определяет размер убытков (вреда), производит страховые выплаты, осуществляет иные
  действия, связанные с исполнением обязательств по Договору страхования.</li>
        <li>2.3. Страхователями признаются юридические лица любой организационно-правовой
  формы и дееспособные физические лица, являющиеся Держателями Банковских карт,
  заключившие со Страховщиком Договоры страхования в соответствии с настоящими
  Правилами.</li>
        <li>2.4. Страхователи заключают Договор страхования как в свою пользу, так и в пользу
  иных лиц (Выгодоприобретателей).</li>
        <li>2.5. Выгодоприобретателем по рискам утраты и повреждения Застрахованной
  банковской карты (п 4.1.1. настоящих Правил) является Держатель Застрахованной банковской
  карты, если расходы в связи с повторным выпуском Банковской карты в случае ее потери,
  хищения или повреждения, согласно условиям банковского обслуживания, должен понести
  клиент Банка — Держатель Банковской карты.</li>
        <li> 2.6. Выгодоприобретателем по рискам, указанным в п. 4.4.2. настоящих Правил,
  несанкционированного снятия денежных средств со Счета Держателя Основной банковской
  карты, является Клиент банка, на которого открыт данный Банковский счет, с выпущенными к
  нему банковскими картами.</li>
        <li>2.7. Выгодоприобретателем по рискам, указанным в п. 4.4.3.,4.4.4. настоящих Правил,
  Правила комбинированного страхования имущественных интересов (финансовых рисков) держателей
  банковских карт.
  является Держатель Застрахованной банковской карты.</li>
        <li>2.8. Договор страхования, заключенный при отсутствии у Страхователя или
  Выгодоприобретателя интереса в сохранении застрахованного имущества, недействителен.</li>
        <li>2.9. Условия настоящих Правил, касающиеся Страхователя, распространяются
  соответственно на Выгодоприобретателя и Застрахованное лицо, в той мере, как это не
  противоречит императивным требованиям законодательство РФ в отношении
  Выгодоприобретателя и Застрахованного лица соответственно.</li>
      </ul>
    </div>
    <div class="rules-block">
      <h4 class="rules-heading">ОБЪЕКТ СТРАХОВАНИЯ</h4>
      <ul class="main-list">
        <li>Объектом страхования по настоящим Правилам являются имущественные интересы
          (финансовые риски) Страхователя (Выгодоприобретателя, Застрахованного лица), связанные с
          риском утраты или повреждения Банковской карты, а также с риском утраты денежных средств
          со Счета, к которому выпущены Застрахованные банковские карты или утраты наличных
          денежных средств, полученных Держателем Застрахованной банковской карты в банкомате по
          Застрахованной банковской карте, а также риском возникновения непредвиденных расходов
          вследствие утраты документов и ключей, если они были застрахованы и украдены вместе с
          застрахованной банковской картой.</li>
      </ul>
    </div>
    <div class="rules-block">
      <h4 class="rules-heading">СТРАХОВЫЕ РИСКИ И СТРАХОВЫЕ СЛУЧАИ</h4>
      <ul class="main-list">
        <li>4.1. Страховым риском является предполагаемое событие, на случай наступления
          которого заключается Договор страхования.
          4.2. Страховым случаем является свершившееся событие из указанных в п. 4.4.
          настоящих Правил, предусмотренное Договором страхования, повлекшее обязанность
          Страховщика произвести страховую выплату.
          4.3. Договор страхования может быть заключен на случай наступления любого из
          ниженазванных событий («Утрата карты», «Списание денежных средств», «Хищение денежных
          средств», «Хищение документов и ключей») в любой модификации их причин, указанных в
          подп. «а»-«г» п 4.4.1. настоящих Правил и подп. «а»-«ж» п. 4.4.2. настоящих Правил и их
          абзацах, отдельных рисков, их совокупности или любой их комбинации.
          4.4. В соответствии с настоящими Правилами Страховщик предоставляет страховую
          защиту от повреждения или утраты (гибели) застрахованного имущества в результате
          следующих страховых случаев:
          4.4.1. «Утрата карты» - утрата Застрахованной банковской карты вследствие:
          а) утери;
          б) хищения путем кражи, грабежа или разбоя;
          в) случайных механических, термических повреждений, размагничивания и т. п.;
          г) неисправной работы банкомата.
          4.4.2. «Списание денежных средств» - несанкционированное списание денежных
          средств со Счета Страхователя (Выгодоприобретателя) по Застрахованной банковской карте
          путем:
          а) Получения третьими лицами наличных денег из банкомата со счета
          Страхователя (Выгодоприобретателя), когда в результате насилия или под угрозой насилия в
          отношении Держателя Застрахованной банковской карты или его близких Держатель
          Застрахованной банковской карты был вынужден передать свою карту и сообщить третьим
          лицам PIN-код Застрахованной банковской карты;
          б) Получения третьими лицами наличных денежных средств со счета
          Правила комбинированного страхования имущественных интересов (финансовых рисков) держателей
          банковских карт.
          Страхователя (Выгодоприобретателя) в отделении Банка с использованием Застрахованной
          банковской карты с копированием подписи Держателя Застрахованной банковской карты на
          платежных документах (слипе, чеке);
          в) Перевода денежных средств со счета Страхователя (Выгодоприобретателя)
          третьими лицами:
          - используя поддельную карту с нанесенными на нее данными действительной
          Застрахованной банковской карты в качестве расчетного средства (за покупки,
          работы, услуги);
          - используя информацию о Застрахованной банковской карте, полученную от ее
          Держателя обманным путем (используя в т. ч. фишинг, скимминг за
          исключением случаев, предусмотренных подп. «е» и «ж» п. 4.4.2. настоящих
          Правил), для осуществления расчетов за покупки, работы, услуги;
          - посредством списания денежных средств со Счета Страхователя
          (Выгодоприобретателя) в заведомо большем размере, чем стоимость
          приобретенного товара (услуг), при оплате указанных товаров (услуг) с
          использованием Застрахованной банковской карты;
          - посредством получения денежных средств из банкомата по поддельной карте, на
          которую нанесены данные действительной Застрахованной банковской карты;
          г) Использования третьими лицами Застрахованной банковской карты в результате
          ее утраты Держателем;
          д) Использования третьими лицами Застрахованной банковской карты в результате
          ее хищения у Держателя путем грабежа или разбоя;
          е) Перевода денежных средств со счета Страхователя (Выгодоприобретателя) в счет
          оплаты за товары, работы, услуги, а также перечисления денежных средств со счета Держателя
          Застрахованной банковской карты посредством переподключения Мобильного банка
          Держателя Застрахованной банковской карты на сторонний номер телефона в результате
          получения третьими лицами мошенническим путем доступа к Мобильному банку Держателя
          Банковской карты, подключенному к Застрахованной банковской карте;
          ж) Перевода денежных средств со счета Страхователя (Выгодоприобретателя) в счет
          оплаты за товары, работы, услуги, а также перечисления денежных средств со счета Держателя
          Застрахованной банковской карты посредством получения доступа к Интернет - банку и
          одноразовым паролям Держателя Застрахованной банковской карты в результате получения
          третьими лицами мошенническим путем доступа к Интернет-банку Держателя Застрахованной
          банковской карты, подключенному к Банковской карте, и одноразовым паролям к нему.
          4.4.3. «Хищение денежных средств» - хищение у Держателя Застрахованной
          банковской карты наличных денежных средств, полученных им в банкомате по Застрахованной
          банковской карте, если такое хищение совершено путем разбойного нападения или грабежа и
          имело место не позднее 12 часов с момента снятия денежных средств, если иное не оговорено
          Договором страхования.
          Страховая выплата осуществляется при условии подтверждения Страхователем
          (Выгодоприобретателем) факта снятия и суммы полученных денежных средств по
          Застрахованной банковской карте в банкомате не ранее, чем за 12 часов до события хищения.
          4.4.4. «Хищение документов и ключей» - возникновение непредвиденных расходов
          вследствие хищения в течение срока действия Договора страхования третьими лицами Личных
          документов, принадлежащих Держателю банковской карты, и/или Ключей от принадлежащего
          держателю банковской карты движимого и/или недвижимого имущества вместе с
          находившейся с ними банковской картой в результате разбоя, грабежа.
          4.5. В случае если Страхователь умышлено не принял разумных и доступных ему мер,
          чтобы уменьшить возможные убытки от наступления страхового случая, и это привело к
          увеличению убытков от страхового случая, Страховщик освобождается от возмещения таких
          убытков в соответствии со ст. 962 Гражданского кодекса Российской Федерации.
          Правила комбинированного страхования имущественных интересов (финансовых рисков) держателей
          банковских карт.
          4.6. В случае если Страховщик предоставляет страховую защиту по одному или
          нескольким рискам, указанным в п. 4.4. настоящих Правил, в Договоре страхования
          указываются соответствующие риски (названия рисков) или пункты Правил.
          4.7. В договоре страхования по согласованию между Страховщиком и страхователем
          может быть установлено ограничение по количеству страховых случаев в течение действия
          Договора страхования.</li>
      </ul>
    </div>
    <div class="rules-block">
      <h4 class="rules-heading">ИСКЛЮЧЕНИЯ ИЗ ОБЪЕМА СТРАХОВЫХ ОБЯЗАТЕЛЬСТВ</h4>
      <ul class="main-list">
        <li>5. ИСКЛЮЧЕНИЯ ИЗ ОБЪЕМА СТРАХОВЫХ ОБЯЗАТЕЛЬСТВ
          5.1. В соответствии с настоящими Правилами не признаются страховыми случаями
          и не подлежат возмещению:
          5.1.1. Убытки в результате операций с использованием Застрахованной банковской
          карты, проведенных до уведомления Банка Держателем Застрахованной банковской карты об
          ее утрате, если с момента обнаружения факта утраты Застрахованной банковской карты или
          сообщения информации о Банковской карте третьим лицам прошло более 12 часов;
          5.1.2. Убытки, наступившие в результате хищения у Держателя Застрахованной
          банковской карты наличных денежных средств, полученных им в банкомате по
          Застрахованной банковской карте, если такое хищение имело место по истечении 12 часов с
          момента снятия денежных средств, если иное не оговорено в Договоре страхования;
          5.1.3. Убытки в результате операций с использованием Застрахованной банковской
          карты, указанной в Договоре страхования, наступившие ранее 48 – часового периода,
          предшествующего моменту блокировки Застрахованной банковской карты;
          5.1.4. Убытки от несанкционированного использования Застрахованной банковской
          карты, указанной в Договоре страхования, в результате ее утраты Страхователем
          (Выгодоприобретателем), кроме случаев, указанных в п. 4.4. настоящих Правил
          5.1.5. Убытки, вызванные повреждением Застрахованной банковской карты,
          указанной в Договоре страхования, в результате попытки Держателя Банковской карты
          получить наличные деньги по Застрахованной банковской карте в банкомате, не
          приспособленном для авторизации Картой платежной системы, с которой Банк имеет
          договор об эмитировании Карт;
          5.1.6. Убытки, вызванные отказом работника кредитного учреждения выдать
          наличные деньги, если он не может провести авторизацию Застрахованной банковскую карту изза сомнений в идентификации подписи на Застрахованной банковской карте и подписи на
          выдаваемом в подтверждение снятия средств слипе;
          5.1.7. Убытки, вызванные невозможностью получения наличных денежных средств по
          Застрахованной банковской карте в результате внесения Застрахованной банковской карты в
          Стоп-лист;
          5.1.8. Ущерб, причиненный вследствие грабежа или разбоя, совершенного лицами,
          проживающими совместно со Страхователем, ведущими с ним совместное хозяйство,
          близкими родственниками Страхователя или работающими у Страхователя на основании
          трудового или гражданско-правового договора;
          5.1.9. Убытки в результате несанкционированного снятия денежных средств со счета
          при отсутствии блокировки карты после обнаружения Держателем Застрахованной банковской
          карты несанкционированного снятия денежных средств, за исключением случаев,
          подтвержденных соответствующими медицинскими документами, когда по состоянию
          здоровья на момент такого обнаружения Держатель Застрахованной банковской карты не мог
          сообщить о необходимости блокировки карты;
          5.1.10. Убытки в результате использования Застрахованной банковской карты членами
          семьи Держателя Застрахованной банковской карты независимо от способа получения ими
          Застрахованной банковской карты;
          Правила комбинированного страхования имущественных интересов (финансовых рисков) держателей
          банковских карт.
          5.1.11. Убытки в результате приостановления или прекращения Банком действия
          Застрахованной банковской карты, а также приостановления или прекращения Банком
          операций с использованием Банковской карты или ее реквизитов;
          5.1.12. Убытки в результате прекращения Банком в одностороннем порядке
          договора с держателем Застрахованной банковской карты и/или возврата в Банк
          Застрахованной банковской карты по требованию Банка;
          5.1.13. Убытки в результате отказа Банка от зачисления средств на Застрахованную
          банковскую карту;
          5.1.14. Убытки, причиненные вследствие нарушения Правил пользования
          Застрахованной банковской картой, установленных Банком, в том числе – вследствие
          нанесения PIN-кода на Застрахованную банковскую карту и/или сообщения его третьим лицам
          (за исключением действий третьих лиц, перечисленных в подп. (а) п. 4.4.2. настоящих Правил,
          если соответствующие риски застрахованы по Договору страхования) и/или оставления PINкода в месте, доступном третьим лицам;
          5.1.15. Убытки в результате владения, использования, распоряжения членами семьи
          Держателя Застрахованной банковской карты дополнительной банковской картой.
          5.1.16. События, указанные в п.4.4.4. настоящих Правил, также не признаются
          страховыми случаями и не влекут за собой возникновение обязанности Страховщика выплатить
          страховое возмещение, если:
          - они наступили в результате кражи документов и/или застрахованных ключей отдельно
          от (не одновременно с) банковской картой;
          5.2. Также не являются страховыми случаями и не возмещаются Страхователю
          (Выгодоприобретателю):
          5.2.1. Моральный вред, упущенная выгода;
          5.2.2. Убытки от блокирования счета в результате отказа от покупки и непроизведенной
          отмены авторизации;
          5.2.3. Убытки в результате использования пластиковой Банковской карты, выданной
          Держателю Банковской карты Банком без заключения договора между Банком и Держателем
          Банковской карты, за исключением случаев замены Застрахованной банковской карты, ранее
          выданной Банком;
          5.2.4. Убытки вследствие совершения мошеннических или иных незаконных действий со
          стороны Держателя Застрахованной банковской карты или лиц, не являющихся третьими
          лицами в соответствии с настоящими Правилами;
          5.2.5. Убытки, прямо или косвенно связанные с войной или военными действиями,
          вторжением, боевыми действиями внешних врагов (независимо от того была война объявлена
          или нет), гражданской войной, мятежом, революцией, восстанием, гражданскими волнениями,
          введением военного положения и узурпацией власти, комендантским часом, бунтом или
          действиями представителей законной власти;
          5.2.6. Убытки, прямо или косвенно, вызванные или являющиеся следствием
          ионизирующей радиации, возникающей при радиоактивном загрязнении (ядерное топливо,
          радиоактивные отходы и т.п.);
          5.2.7. Убытки, возникшие в результате изъятия, конфискации, реквизиции, уничтожения
          Застрахованной банковской карты или ареста счета по распоряжению государственных органов;
          5.2.8. Убытки, по которым Страхователь (Выгодоприобретатель) получил возмещение, в
          полном объеме от:
          - Банка-эмитента;
          - физических и/или юридических лиц, ответственных за причинение вреда.
          5.2.9. Убытки Страхователя (Выгодоприобретателя), возникшие в результате
          умышленных действий Страхователя, Выгодоприобретателя, Держателя Застрахованной
          банковской Карты или лиц, имеющих на основании договора банковского обслуживания право
          на использование Застрахованной банковской карты;
          Правила комбинированного страхования имущественных интересов (финансовых рисков) держателей
          банковских карт.
          5.2.10. Расходы, связанные со срочным изготовлением новой карты взамен утраченной
          Застрахованной банковской карты.
          5.3. Дополнительные расходы, возникшие у Страхователя (Выгодоприобретателя) в
          результате утраты Застрахованной банковской карты (дополнительная плата за обналичивание
          средств со счета Держателя Застрахованной банковской карты, которую Страхователь
          (Выгодоприобретатель) в рамках оговоренного при открытии счета и получении Застрахованной
          банковской карты ежедневного лимита не оплачивал при совершении подобных операций,
          оплата услуг других организаций вследствие невозможности пользования услугами тех
          организаций, которые принимали оплату по утраченной Застрахованной банковской карте и
          т.п.).
          5.4. Расходы, связанные с неисполнением или ненадлежащим исполнением
          обязательств по договору с кредитной организацией, включая штрафные санкции, пени,
          проценты и т.п.</li>
      </ul>
    </div>
    <div class="rules-block">
      <h4 class="rules-heading">СТРАХОВАЯ СУММА</h4>
      <ul class="main-list">
        <li>6.1. Страховой суммой является денежная сумма, которая определена в порядке,
          установленном Договором страхования при его заключении, и, исходя из которой,
          устанавливаются размер страховой премии (страховых взносов) и размер страховой выплаты
          при наступлении страхового случая.
          6.2. Страховая сумма определяется по соглашению Сторон и устанавливается исходя
          из предполагаемой суммы убытков, которые Страхователь (Застрахованное лицо) может
          понести при наступлении страхового случая.
          6.3. Договором страхования может быть предусмотрен различный порядок
          определения страховой суммы в зависимости от страхового риска (страхового случая) и (или)
          в зависимости от условий страхового возмещения.
          6.4. По рискам, перечисленным в п. 4.4.1. настоящих Правил, страховая сумма
          устанавливается исходя из стоимости изготовления Банковской карты (страховой
          стоимости) в соответствии с положениями договора между Держателем Основной
          Банковской карты и Банком, предусматривающими оплату расходов по изготовлению
          взамен поврежденной или утраченной Банковской карты.
          6.5. По рискам, перечисленным в п.п. 4.4.2. - 4.4.4. настоящих Правил, страховая
          сумма устанавливается исходя из предполагаемой суммы убытков, которые Страхователь
          (Выгодоприобретатель) может понести при наступлении страхового случая.
          6.6. Страховые суммы и лимиты ответственности в пределах страховой суммы
          устанавливаются в Договоре страхования. Если договором страхования не установлено иного,
          лимит возмещения равен страховой сумме.
          6.7. Если иное не предусмотрено Договором страхования, при наступлении
          страхового случая указанная в Договоре страховая сумма уменьшается на сумму ранее
          произведенных Страховщиком страховых выплат.</li>
      </ul>
    </div>
    <div class="rules-block">
      <h4 class="rules-heading">СТРАХОВАЯ ПРЕМИЯ И ПОРЯДОК ЕЕ ОПЛАТЫ</h4>
      <ul class="main-list">
        <li>7.1. При заключении Договора страхования Страховщик определяет размер страховой
          премии, которую должен уплатить Страхователь.
          7.2. Страховая премия определяется Страховщиком в соответствии с действующими
          на момент заключения Договора страхования тарифами, исходя из условий Договора и оценки
          степени риска.
          Правила комбинированного страхования имущественных интересов (финансовых рисков) держателей
          банковских карт.
          7.3. Страховой тариф – ставка страховой премии с единицы страховой суммы с
          учетом объекта страхования и характера страхового риска, а также других условий
          страхования, в т.ч. наличия франшизы и её размера в соответствии с условиями договора
          страхования.
          7.4. При определении размера страховой премии к базовым тарифам могут
          применяться повышающие и понижающие коэффициенты, размер которых определяется в
          зависимости от факторов страхового риска (Приложение № 3) в т. ч. от результатов
          предыдущего страхования.
          7.5. Поправочный коэффициент в зависимости от результатов страхования не
          применяется в случае перерыва в страховании, продолжительностью два года и более.
          7.6. Случаи выплаты страхового возмещения с последующим переходом к
          Страховщику в порядке суброгации права требования, которое Страхователь имеет к лицу,
          ответственному за ущерб, возмещенный в результате страхования, рассматриваются при
          определении поправочного коэффициента в зависимости от результатов страхования как
          отсутствие страховой выплаты.
          7.7. Страховая премия уплачивается в валюте Российской Федерации, за исключением
          случаев, предусмотренных валютным законодательством РФ и принятым в соответствии с ним
          нормативными правовыми актами валютного регулирования.
          7.8. Если иное не предусмотрено Договором страхования, при установлении
          страховой суммы и страховой премии в эквиваленте иностранной валюты (в соответствии со ст.
          317 Гражданского кодекса Российской Федерации), страховая премия уплачивается в рублях по
          официальному курсу Центрального банка Российской Федерации (ЦБ РФ), установленному для
          иностранной валюты на дату её уплаты (перечисления).
          7.9. Страховая премия может быть уплачена Страхователем наличными деньгами в
          кассу Страховщика или безналичным путем на расчетный счет Страховщика. Датой уплаты
          страховой премии считается день уплаты страховой премии в кассу Страховщика или день
          поступления страховой премии на расчетный счет Страховщика Страховая премия по Договору
          уплачивается Страхователем единовременно за весь период страхования или в рассрочку в виде
          нескольких страховых взносов (рассрочка уплаты страховой премии).
          7.10. При неоплате страховой премии, либо оплате ее в сумме меньшей, чем
          предусмотрено Договором, Договор считается не заключённым. Страховщик возвращает
          полученную сумму Страхователю в течение 5 (пяти) рабочих дней с даты получения от
          Страхователя соответствующего письменного заявления.</li>
      </ul>
    </div>
    <div class="rules-block">
      <h4 class="rules-heading">ЗАКЛЮЧЕНИЕ, ДЕЙСТВИЕ И ПРЕКРАЩЕНИЕ ДОГОВОРА СТРАХОВАНИЯ</h4>
      <ul class="main-list">
        <li>8.1. Под договором страхования (далее – Договор) в настоящих Правилах понимается
          соглашение между страхователем и страховщиком, согласно которому Страховщик за
          обусловленную Договором плату (страховую премию) при наступлении предусмотренного в
          Договоре события (страхового случая) обязуется возместить Страхователю
          (Выгодоприобретателю) в пределах определенной Договором страховой суммы причиненный
          вследствие этого события ущерб в размере (ограничении) и порядке, определенными
          настоящими Правилами и/или Договором страхования.
          8.2. Договор страхования заключается на основании устного или письменного
          заявления Страхователя.
          8.3. Заявление Страхователя, независимо от того, составляется оно в письменной
          форме или заявляется в устной, должно содержать:
          8.3.1. Ф. И. О. (наименование) Страхователя (Выгодоприобретателя);
          Правила комбинированного страхования имущественных интересов (финансовых рисков) держателей
          банковских карт.
          8.3.2. Дату рождения физического лица — Страхователя (Выгодоприобретателя) или
          сведения о государственной регистрации и ИНН юридического лица — Страхователя
          (Выгодоприобретателя);
          8.3.3. Сведения о регистрации по месту жительства для физического лица —
          Страхователя (Выгодоприобретателя) или адрес (место нахождения) юридического лица —
          Страхователя (Выгодоприобретателя), указанный в Едином государственном реестре
          юридических лиц и в учредительных документах;
          8.3.4. Сведения о количестве карт, выпускаемых Банком-эмитентом для Держателя
          банковских карт, принимаемых на страхование, их типе и условиях использования;
          8.3.5. Сведения о перечне страховых рисков, желаемом сроке страхования, страховой
          сумме;
          8.4. Заявление на страхование, составленное в письменной форме, является
          неотъемлемой частью Договора страхования.
          8.5. Одновременно с Заявлением на страхование банковских карт Страховщик вправе
          потребовать у Страхователя документы, подтверждающие сведения, сообщенные в Заявлении, а
          также документы, необходимые для оценки риска Страховщиком, а именно:
          — документ, удостоверяющий личность (в том числе паспорт гражданина РФ, временное
          удостоверение личности, удостоверение личности или военный билет военнослужащего,
          общегражданский заграничный паспорт, удостоверение беженца, удостоверение личности
          моряка, паспорт иностранного гражданина либо вид на жительство иностранного гражданина,
          лица без гражданства);
          — договор с Банком-эмитентом, выпустившим Застрахованную(ые) банковскую(ие)
          карту(ы);
          — перечень должностных лиц при страховании корпоративных Банковских карт или
          список членов семьи при страховании семейной Банковской карты, уполномоченных проводить
          операции по Застрахованной банковской карте.
          Страховщик имеет право проверить достоверность указанных Страхователем
          (Застрахованным лицом) в заявлении и/или анкете сведений. Документы (их копии) должны
          быть предоставлены исключительно на русском языке либо с заверенным нотариально
          переводом на русский язык.
          По решению Страховщика перечень документов информации, приведенный в настоящей
          статье, может быть сокращен, если это не влияет на оценку риска, вероятности наступления
          страхового случая, а также определение размера ущерба и получателя страхового возмещения.
          8.6. Если после заключения Договора страхования Страховщиком будет установлено,
          что в заявлении на страхование Страхователь сообщил заведомо ложные сведения, влияющие
          на степень риска и вероятность наступления страхового случая, то Страховщик вправе
          потребовать признания Договора страхования недействительным и применения последствий,
          предусмотренных законодательством РФ.
          8.7. Договор страхования заключается на срок один год (годовой договор). На срок
          менее года (краткосрочный договор) или на срок более года (долгосрочный договор).
          8.8. Договор страхования оформляется в письменной форме в виде страхового полиса,
          подписанного Страховщиком или единого документа, подписанного Сторонами Договора
          При заключении договора страхования Страховщик обязан вручить Страхователю
          настоящие Правила, о чем делается запись в договоре страхования.
          8.9. Настоящие Правила могут быть вручены Страхователю, в т.ч. путем
          информирования его об адресе размещения Правил на сайте Страховщика в информационнотелекоммуникационной сети Интернет, путем направления файла, содержащего текст Правил
          на указанный Страхователем адрес электронной почты или путем вручения Страхователю
          Правила комбинированного страхования имущественных интересов (финансовых рисков) держателей
          банковских карт.
          любого электронного носителя информации (CD или DVD диск, накопитель на жестких
          магнитных дисках, флеш - накопители и т.д.), на котором размещен файл, содержащий текст
          настоящих Правил.
          В случае если Правила страхования были вручены Страхователю одним из способов,
          указанных в настоящем пункте, без вручения текста Правил на бумажном носителе, это не
          освобождает Страхователя (Выгодоприобретателя) от обязанностей, которые предусмотрены
          настоящими Правилами страхования. Страхователь имеет право в любой момент действия
          Договора страхования обратиться к Страховщику за получением текста Правил на бумажном
          носителе.
          8.10. Если иное не предусмотрено Договором, срок страхование по Договору начинает
          своё течение с 00 часов дня, указанного в Договоре как дата начала срока страхования, при
          условии оплаты Страхователем страховой премии в оговоренные Договором страхования
          сроки, и оканчивается в 24 часа дня, указанного в Договоре страхования как дата окончания
          срока страхования.
          8.11. Если иное не предусмотрено Договором страхования, все уведомления и
          извещения в связи с исполнением и прекращением Договора страхования направляются
          сторонами по адресам, которые указаны в Договоре страхования. В случае изменения адресов
          и/или реквизитов стороны обязаны в течение 30 (тридцати) дней с даты их изменения известить
          друг друга об этом. Если сторона не была извещена об изменении адреса и/или реквизитов
          другой стороны своевременно, то все уведомления и извещения, направленные по прежнему
          адресу, будут считаться доставленными.
          Любые уведомления и извещения в связи с заключением, исполнением или
          прекращением договорных правоотношений считаются направленными сторонами в адрес
          друг друга, только если они сделаны в письменной форме.
          8.12. Все изменения и/или дополнения в Договор страхования оформляются
          Страховщиком и Страхователем на основании заявления Страхователя.
          8.13. В случае утраты Полиса (Договора страхования) в период действия Договора
          страхования Страхователю на основании его письменного заявления выдается дубликат, после
          чего утраченный экземпляр Полиса (Договора страхования) считается недействительным и
          никакие выплаты по нему не производятся.
          8.14. Заключая Договор страхования на основании настоящих Правил, Страхователь
          дает своё согласие и подтверждает согласие Выгодоприобретателя(ей) и Застрахованного(ых)
          лица (лиц), названных в Договоре страхования, с тем, что Страховщик может осуществлять
          обработку указанных в нем персональных данных физических лиц в течение всего срока
          действия Договора страхования и последующие 50 (пятьдесят) лет с момента прекращения
          действия Договора.
          Под персональными данными Страхователь, Выгодоприобретатель(и) и
          Застрахованное(ые) лицо (лица), названные в Договоре страхования, понимают указанную
          Договоре страхования информацию, относящуюся к прямо или косвенно определенному или
          определяемому из названных в Договоре страхования Страхователю, Выгодоприобретателю и
          Застрахованному лицу, в том числе, фамилия, имя, отчество, год, месяц, дата и место рождения,
          адрес, номер телефона, адрес электронной почты, семейное, социальное, имущественное
          положение, наличие детей, образование, профессия, доходы, паспортные данные.
          Страхователь обязуется предоставить Страховщику названные в настоящем пункте
          согласия физических лиц (Выгодоприобретателя(ей) и Застрахованного(ых)) и несет
          персональную ответственность за неисполнение или ненадлежащее исполнение указанного
          обязательства.
          Под обработкой персональных данных Страхователь, Выгодоприобретатель(и) и
          Застрахованный(ые), названные в Договоре страхования понимают: сбор, запись,
          систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение,
          Правила комбинированного страхования имущественных интересов (финансовых рисков) держателей
          банковских карт.
          использование, передачу, обезличивание, блокирование, удаление, уничтожение персональных
          данных.
          Обработка персональных данных осуществляется с использованием средств
          автоматизации или без использования таких средств в целях изменения, продления, досрочного
          прекращения Договора страхования, осуществления Страховщиком права требования,
          перешедшего к Страховщику в порядке суброгации, реализации Страховщиком права
          требования о возврате неосновательного обогащения в виде излишне выплаченной суммы
          страхового возмещения, продвижения услуг Страховщика на рынке путем осуществления
          прямых контактов со Страхователем, Выгодоприобретателем(ями) и Застрахованным(ыми)
          лицом (лицами) с помощью средств связи, в статистических и иных исследовательских целях, в
          т.ч. в целях проведения анализа страховых рисков, а также в целях информирования о других
          продуктах и услугах Страховщика, получения рассылок, направленных на повышение уровня
          клиентоориентированности и лояльности, включая проведение исследований (опросов) в
          области сервисных услуг и их качества, предоставляемых Страховщиком по Договору
          страхования, а также об условиях продления правоотношений со Страховщиком.
          Заключая Договор страхования на основании настоящих Правил, Страхователь даёт своё
          согласие и подтверждает согласие Выгодоприобретателя(ей) и Застрахованного(ых) лица (лиц),
          названных в Договоре страхования, на информирование о других продуктах и услугах, на
          получение рекламы по сетям электросвязи, а также рассылок, направленных на повышение
          уровня клиентоориентированности и лояльности, включая проведение исследований (опросов)
          в области сервисных услуг и их качества, предоставляемых Страховщиком по Договору
          страхования, а также об условиях продления правоотношений со Страховщиком. В том числе,
          Страхователь в случае регистрации в личном кабинете СПАО «Ингосстрах» дает свое согласие
          на открытие доступа к своим персональным данным (публикацию) путем ввода
          идентифицирующих Страхователя данных при регистрации в соответствующем разделе сайта
          www.ingos.ru (в том числе, с использованием специализированного программного обеспечения,
          предоставленного Страховщиком).
          Заключая Договор страхования на основании настоящих Правил, Страхователь даёт своё
          согласие и подтверждает согласие Выгодоприобретателя(ей) и Застрахованного(ых) лица (лиц),
          названных в Договоре страхования, на передачу его (их) персональных данных при обработке в
          указанных целях третьим лицам, в том числе лицу, ответственному за ущерб, возмещённый в
          результате страхования, при осуществлении Страховщиком права требования, перешедшего к
          Страховщику в порядке суброгации, а также лицу, получившему неосновательное обогащение в
          виде излишне выплаченной суммы страхового возмещения.
          Заключая Договор страхования на основании настоящих Правил, Страхователь
          соглашается с передачей Страховщиком персональных данных Страхователя и данных о
          наличии других договоров страхования, необходимых для расчета суммы страховой премии,
          оценки рисков или иных целей, связанных с заключением или исполнением Договора
          страхования, ставших ему известными в связи с заключением и исполнением Договора
          страхования, третьим лицам, с которыми у Страховщика заключены соответствующие
          соглашения, обеспечивающие надежное хранение и предотвращение незаконного разглашения
          (конфиденциальность) персональных данных, включая третьих лиц, проводящих работу по
          передаче или записи информации в информационную систему Страховщика.
          Страховщик вправе с соблюдением требований, предусмотренных действующим
          законодательством Российской Федерации, осуществлять обработку, в т.ч. сбор иных сведений,
          необходимых для заключения Договора страхования, оценки страховых рисков, определения
          размера убытков или ущерба, включая специальные категории персональных данных, в том
          числе, содержащихся в общедоступных источниках.
          Страховщик обязуется обеспечивать сохранность и неразглашение персональных
          данных Страхователя в целях иных, нежели предусмотрены настоящим пунктом.
          Указанные в настоящем пункте согласия действуют со дня выдачи согласия до дня
          Правила комбинированного страхования имущественных интересов (финансовых рисков) держателей
          банковских карт.
          истечения пятидесятилетнего периода, исчисляемого с момента прекращения действия
          Договора страхования либо с момента получения Страховщиком заявления об отзыве согласия
          на обработку персональных данных. Согласие на обработку персональных данных может быть
          отозвано субъектом персональных данных полностью или в части информирования о других
          продуктах и услугах, путем направления письменного заявления Страховщику способом,
          позволяющим достоверно установить дату получения данного заявления Страховщиком.
          В случае полного отзыва субъектом персональных данных своего согласия на обработку
          персональных данных, действие Договора страхования в отношении такого лица прекращается,
          а в случае отзыва такого согласия субъектом персональных данных, являющимся
          Страхователем, Договор страхования прекращается полностью, за исключением случаев, когда
          согласно законодательству Российской Федерации, получение согласия субъекта персональных
          данных на их обработку не требуется. При этом действие Договора страхования прекращается
          досрочно с даты получения Страховщиком соответствующего заявления об отзыве согласия на
          обработку персональных данных.
          После прекращения действия Договора страхования (в том числе при его расторжении),
          а также в случае отзыва субъектом персональных данных согласия на обработку своих
          персональных данных, Страховщик обязуется уничтожить такие персональные данные в срок,
          не превышающий 50 (пятидесяти) лет с момента прекращения действия Договора либо с
          момента получения Страховщиком заявления об отзыве согласия на обработку персональных
          данных.
          Договором страхования могут быть определены иные (уточненные) условия обработки
          персональных данных, не противоречащие законодательству Российской Федерации.
          8.15. Договор страхования прекращается в случаях:
          а) истечения срока его действия с 00 часов 00 минут дня, следующего за
          последним днем страхования;
          б) исполнения Страховщиком обязательств по Договору страхования в полном
          объеме с 00 часов 00 минут дня, следующего за днем, в котором были выполнены
          обязательства Страховщика в полном объеме;
          в) неоплаты Страхователем (если Страхователем является юридическое лицо)
          очередного взноса страховой премии в установленные Договором страхования сроки, если
          Договором страхования не предусмотрено иное. (Договор страхования считается
          прекращенным с момента, указанного в уведомлении Страховщика, независимо от даты его
          получения Страхователем. При этом дата досрочного прекращения Договора страхования не
          может быть указана ранее даты отправки уведомления. Если Страховщик не уведомил
          Страхователя о досрочном прекращении Договора страхования в связи с неуплатой
          Страхователем очередного страхового взноса, Страховщик вправе при определении размера,
          подлежащего выплате страхового возмещения зачесть сумму просроченных страховых взносов,
          если Договором страхования не предусмотрено иное.);
          г) ликвидации Страхователя, являющегося юридическим лицом, кроме случаев
          замены Страхователя в Договоре страхования с 00 часов 00 минут дня, следующего за днем
          внесения записи в ЕГРЮЛ о ликвидации Страхователя — юридического лица;
          д) прекращения действия Договора страхования по решению суда с момента
          вступления в силу решения суда;
          е) по соглашению Сторон;
          ж) полного отзыва субъектом персональных данных своего согласия на обработку
          персональных данных, за исключением случаев, когда для исполнения Договора страхования
          согласие субъекта персональных данных не требуется;
          з) в иных случаях, предусмотренных законодательством Российской Федерации.
          8.16. Договор страхования прекращается до наступления срока, на который он был
          заключен, если после его вступления в силу возможность наступления страхового случая
          отпала и существование страхового риска прекратилось по обстоятельствам иным, чем
          Правила комбинированного страхования имущественных интересов (финансовых рисков) держателей
          банковских карт
          страховой случай, в частности:
          а) закрытие Банковского счета;
          б) прекращение операций по банковскому обслуживанию Банком-эмитентом
          Застрахованной банковской карты в результате отзыва (приостановления действия)
          банковской лицензии, расторжения договора с процессинговым центром или платежной
          системой и т.п.;
          в) истек срок действия банковской карты.
          8.17. При досрочном прекращении Договора страхования по обстоятельствам иным,
          чем страховой случай (п. 8.16. Правил), Страховщик имеет право на часть страховой премии
          пропорционально времени, в течение которого действовало страхование, при этом возврат
          страховой премии Страхователю за вычетом указанной выше части, производится по заявлению
          последнего.
          8.18. Договор страхования может быть прекращен досрочно в любое время по
          требованию Страхователя. Досрочное прекращение договора производится на основании
          письменного заявления (отказа) Страхователя. Договор страхования считается прекращенным
          по настоящему пункту Правил с 00 часов 00 минут дня, указанного в заявлении, но не ранее 00
          часов 00 минут дня получения заявления Страховщиком. Договор страхования считается
          прекращенным с 00 часов 00 минут дня получения заявления Страховщиком, если дата
          прекращения Договора страхования в заявлении не указана. При этом Страховщик не
          производит возврат уплаченной страховой премии за неистекший период страхования, если
          Договором страхования не предусмотрено иное, и за исключением случаев, предусмотренных
          п. 8.16, 8.15 настоящих Правил.
          8.19. Договор страхования может быть признан недействительным с момента его
          заключения по основаниям, предусмотренным действующим гражданским законодательством
          Российской Федерации. Признание Договора страхования недействительным осуществляется в
          соответствии с действующим гражданским законодательством Российской Федерации.
          8.20. В случае расторжения Договора страхования по соглашению сторон, моментом
          расторжения Договора является дата подписания такого соглашения, если соглашением о
          прекращении Договора не предусмотрено иного. Вопрос о возврате страховой премии при
          расторжении Договора страхования решается по соглашению Сторон и фиксируется по тексту
          соглашения.
          8.21. В случае прекращения действия Договора страхования, в соответствии с подп. «д»
          п. 8.15. настоящих Правил, датой прекращения договора является дата получения
          Страховщиком соответствующего заявления. При этом возврат уплаченной страховой премии
          не производится.
          8.22. Момент прекращения Договора и условия о возврате премии при прекращении
          Договора страхования в соответствии с подп. «е» п. 8.15. настоящих Правил решается в
          зависимости от основания прекращения Договора.
          8.23. Страхователь – физическое лицо1
          в дополнение к условиям о досрочном
          расторжении договора страхования, указанным п,п. 8.15. -8.16. настоящих Правил, вправе
          досрочно отказаться от Договора страхования в течение 14 (четырнадцати) календарных дней
          со дня его заключения независимо от момента уплаты страховой премии, при отсутствии в
          данном периоде событий, имеющих признаки страхового случая.
          В случае если Страхователь отказался от Договора страхования в срок, указанный
          настоящем пункте, и до даты возникновения обязательств Страховщика по заключенному
          Договору (далее – дата начала действия страхования), уплаченная страховая премия подлежит
          1 Под страхователями, имеющими право на досрочный отказ от договора добровольного страхования на
          специальных условиях, понимаются только страхователи- физические лица
          Правила комбинированного страхования имущественных интересов (финансовых рисков) держателей
          банковских карт.
          возврату Страховщиком Страхователю в полном объеме.
          В случае если Страхователь отказался от Договора страхования в срок, указанный
          настоящем пункте, но после даты начала действия страхования, Страховщик при возврате
          уплаченной страховой премии Страхователю удерживает её часть пропорционально сроку
          действия Договора страхования, прошедшему с даты начала действия страхования до даты
          прекращения действия Договора страхования.
          При досрочном прекращении Договора страхования в порядке, предусмотренном
          настоящим пунктом, Договор страхования считается прекратившим свое действие с даты
          получения Страховщиком письменного заявления Страхователя об отказе от Договора
          страхования или иной даты, установленной по соглашению Сторон, но не позднее срока,
          определенного в настоящем пункте.
          Возврат страховой премии Страхователю в соответствии с настоящим пунктом по
          выбору Страхователя производится наличными деньгами или в безналичном порядке в срок,
          не превышающий 10 (десять) рабочих дней со дня получения письменного заявления
          Страхователя об отказе от Договора страхования.
          Действие настоящего пункта не распространяется на осуществление добровольного
          страхования, являющегося обязательным условием допуска физического лица к выполнению
          профессиональной деятельности в соответствии с законодательством Российской Федерации.
          8.24. При страховании в эквиваленте иностранной валюты, в случае досрочного
          расторжения (прекращения) договора / прекращении Договора в отношении части объектов
          страхования и возврата части страховой премии за не истекший срок действия Договора,
          расчет производится в рублях по официальному курсу Центрального банка РФ,
          установленному для данной валюты на дату расторжения (прекращения) Договора, но не
          более курса валюты страхования, установленного ЦБ РФ на дату заключения Договора.
          8.25. Территорией страхования, если иное не оговорено договором страхования,
          является Российская Федерация. При увеличении до территории всего мира или конкретных
          стран, в зависимости от условий (события, произошедшие на территории всего мира, и т. п.) к
          базовой тарифной ставке Страховщик может применять повышающие коэффициенты.
          8.26. Договор страхования может содержать и иные условия, определяемые по
          соглашению Сторон и не противоречащие законодательству Российской Федерации</li>
      </ul>
    </div>
    <div class="rules-block">
      <h4 class="rules-heading">ПРАВА И ОБЯЗАННОСТИ СТОРОН</h4>
      <ul class="main-list">
        <li>9.1. Страховщик обязан:
  9.1.1. Вручить Страхователю настоящие Правила.
  9.1.2. По требованию Страхователя (Застрахованных лиц), а также лиц, имеющих
  намерение заключить Договор страхования, разъяснять положения, содержащиеся в Правилах
  страхования и Договорах страхования, расчеты изменения страховой суммы в течение срока
  действия Договора страхования, расчеты страховой выплаты
  9.1.3. При наступлении событий, предусмотренных Договором страхования и
  настоящими Правилами, выплатить страховое возмещение либо предоставить обоснованный
  полный или частичный отказ в страховой выплате в течение 15 (пятнадцати) рабочих дней с
  даты получения последнего из документов, указанных в п. 10.5. настоящих Правил, за
  исключением случаев, предусмотренных в п. 10.14.3. настоящих Правил.
  9.1.4. Возместить расходы, произведенные Страхователем (Выгодоприобретателем) при
  наступлении страхового случая для предотвращения или уменьшения ущерба объекту
  страхования в соответствии с законодательством РФ;
  9.1.5. Без согласия Страхователя не разглашать сведения о Страхователе и его
  имущественном положении, если иное не предусмотрено законодательством РФ.
  9.2. Страхователь (Выгодоприобретатель, Держатель банковской карты) обязан:
  Правила комбинированного страхования имущественных интересов (финансовых рисков) держателей
  банковских карт.
  9.2.1. При заключении Договора страхования сообщать Страховщику обо всех
  известных ему обстоятельствах, имеющих значение для оценки страхового риска, а также обо
  всех заключенных или заключаемых договорах страхования в отношении данного объекта
  страхования;
  9.2.2. Выполнять предусмотренные Договором с Банком обязанности, связанные с
  использованием Застрахованной банковской карты и Правилами использования банковских
  карт, утвержденными Банком.
  9.2.3. Не сообщать PIN-код Застрахованной банковской карты другим лицам и не
  оставлять его в местах, доступных другим лицам, а также не наносить его на Застрахованную
  банковскую карту.
  9.2.4. При наступлении страхового случая немедленно, как только это станет
  возможным, любым доступным способом уведомить Страховщика;
  9.2.5. При утере или хищении Застрахованной банковской карты или информации о ней,
  а также при утрате вследствие неисправной работы банкомата:
  а) немедленно, но во всяком случае не позднее 12 (двенадцати) часов с момента
  обнаружения факта утраты Застрахованной банковской карты, сообщить в Банк (в случае
  пребывания за границей - по указанным в договоре с Банком телефонам) о случившемся для
  блокировки Карты;
  б) заявить о произошедшем в страховую компанию не позднее 3 (трех) рабочих
  дней с момента как стало известно о событии, имеющем признаки страхового случая.
  9.2.6. При обнаружении расхождений между расходами, произведенными Держателем
  Застрахованной банковской карты по Застрахованной банковской карте и остатком по счету
  Страхователя (Выгодоприобретателя), немедленно сообщить в Банк о случившемся и потребовать
  заблокировать Карту;
  9.2.7. В случае несанкционированного списания денежных средств, кражи, грабежа, разбоя -
  немедленно письменно заявить о произошедшем в правоохранительные органы для начала
  расследования и получить талон-уведомление или иной документ о принятии заявления
  правоохранительными органами;
  9.2.8. В случае несанкционированного списания денежных средств оформить в Банке
  претензию-заявление о спорной операции в установленной Банком форме. Заявление должно быть
  подано в срок, установленный договором с Банком (Условиями/Правилами использования банковских
  карт, утвержденными Банком).
  9.2.9. При нахождении утерянной или возвращении похищенной Застрахованной банковской
  карты немедленно сообщить об этом Страховщику и в Банк, эмитировавший Карту.
  9.2.10. Страховщик и Страхователь обязаны соблюдать строгую конфиденциальность
  деловой, коммерческой и иной информации, полученной друг от друга в связи с
  заключением и исполнением Договора страхования.
  9.2.11. Передача конфиденциальной информации третьим лицам может осуществляться
  лишь с предварительного письменного согласия обеих сторон Договора страхования.
  9.2.12. К Страховщику, выплатившему страховое возмещение, переходит в пределах
  выплаченной суммы право требования, которое Страхователь (Выгодоприобретатель) имеет к
  лицу, ответственному за убытки, возмещенные в результате страхования.
  Перешедшее к Страховщику право требования осуществляется им с соблюдением
  правил, регулирующих отношения между Страхователем (Выгодоприобретателем) и лицом,
  ответственным за убытки. Страховщик заменяет собой Страхователя (Выгодоприобретателя) в
  его требованиях к лицу, ответственному за убытки.
  Страхователь (Выгодоприобретатель) обязан передать Страховщику все имеющиеся у
  него документы и доказательства, а также сообщить все известные ему сведения, необходимые
  для осуществления Страховщиком перешедшего к нему права требования. К ним относятся
  документы, доказательства и сведения, которые необходимы для предъявления претензий и
  исков к ответственным за ущерб лицам. Страхователь (Выгодоприобретатель) передает свои
  Правила комбинированного страхования имущественных интересов (финансовых рисков) держателей
  банковских карт.
  права требования в полном объеме, то есть в том, в каком они могли бы быть
  осуществлены им самим. Страхователь принимает на себя обязательства содействовать
  Страховщику в осуществлении его права на суброгацию.
  Если Страхователь (Выгодоприобретатель) отказался от своего права требования к
  лицу, ответственному за причинение убытков, возмещенных Страховщиком, или
  осуществление этого права стало невозможным по вине Страхователя
  (Выгодоприобретателя), то Страховщик освобождается от выплаты страхового возмещения
  полностью или в соответствующей части и вправе потребовать возврата выплаченной суммы
  страхового возмещения.
  9.2.13 Убытки, наступившие после окончания срока страхования, возмещению не
  подлежат.
  9.2.14 В период действия договора страхования обязан незамедлительно, но в любом
  случае в течение не более трех дней с момента, как ему стало известно, письменно уведомлять
  Страховщика об обстоятельствах, влекущих изменение степени риска.
  9.2.15 Возвратить Страховщику полученное страховое возмещение (или его
  соответствующую часть), если обнаружится обстоятельство, которое по закону или по
  настоящим Правилам страхования полностью или частично лишает Страхователя
  (Выгодоприобретателя) права на получение страховой выплаты.
  9.2.16 Договором страхования могут быть установлены и иные обязанности сторон, не
  противоречащие законодательству Российской Федерации.
  9.3. Страховщик вправе:
  9.3.1. Проводить самостоятельно или посредством специализированной организации
  анализ сведений, полученных от Страхователя, при заключении договора страхования,
  знакомиться с соответствующей документацией;
  9.3.2. Запрашивать у компетентных органов информацию, необходимую для
  установления факта страхового случая, величины подлежащего выплате страхового
  возмещения (размера страховой выплаты), а также самостоятельно выяснять причины и
  обстоятельства наступления страхового случая, проводить экспертизу наступления страхового
  случая;
  9.3.3. Назначать или нанимать экспертов, специалистов для урегулирования убытков
  по наступившему событию;
  9.3.4. Проводить совместные со Страхователем расследования, экспертные проверки
  факта наступления страхового случая и размера причиненного убытка;
  9.3.5. Требовать изменения условий Договора страхования и оплаты дополнительной
  страховой премии при увеличении степени риска соразмерно такому увеличению;
  Если Страхователь (Выгодоприобретатель) возражает против изменения условий
  договора страхования или доплаты страховой премии, Страховщик вправе в соответствии с
  Гражданским кодексом Российской Федерации потребовать расторжения договора страхования.
  9.3.6. Отказать в страховой выплате в случаях, предусмотренных настоящими
  Правилами, Договором страхования и действующим законодательством Российской Федерации;
  9.3.7. Осуществлять иные права, предусмотренные настоящими Правилами,
  Договором страхования и действующим законодательством Российской Федерации.
  9.4. Страхователь вправе:
  9.4.1. Требовать выполнения Страховщиком условий Договора страхования,
  настоящих Правил страхования и действующего законодательства Российской Федерации;
  9.4.2. Получить дубликат Договора страхования в случае его утраты;
  9.4.3. Отказаться от Договора страхования в соответствии с условиями настоящих
  Правил страхования о досрочном прекращении Договора страхования по инициативе
  Страхователя;
  9.4.4. Осуществлять иные права, специально предусмотренные Договором страхования
  и действующим законодательством Российской Федерации.
  Правила комбинированного страхования имущественных интересов (финансовых рисков) держателей
  банковских карт.</li>
      </ul>
    </div>
    <div class="rules-block">
      <h4 class="rules-heading">ВЫПЛАТА СТРАХОВОГО ВОЗМЕЩЕНИЯ</h4>
      <ul class="main-list">
        <li>10.1. Страховщик в течение 15 (пятнадцати) рабочих дней с даты получения
          последнего из документов, указанных в п.10.5. настоящих Правил, выплачивает страховое
          возмещение либо предоставляет полный или частичный отказ в выплате страхового
          возмещения.
          10.2. Размер страховой выплаты определяется:
          10.2.1. По рискам, предусмотренным п. 4.4.1. настоящих Правил, – исходя из стоимости
          изготовления новой Банковской Карты, предусмотренной договором между Банком -
          эмитентом и Держателем Застрахованной банковской карты, но не может превышать
          страховую сумму;
          10.2.2. По рискам, предусмотренным п.4.4.2. настоящих Правил, – исходя из размера
          списанных со счета Страхователя (Выгодоприобретателя) средств в результате
          несанкционированного использования Застрахованной банковской карты, указанной в
          Договоре страхования, но не более страховой суммы;
          10.2.3. По рискам, предусмотренным п. 4.4.3. Правил, – исходя из размера похищенных
          наличных денежных средств, полученных Держателем Застрахованной банковской карты в
          банкомате по Застрахованной банковской карте, но в пределах страховой суммы.
          10.2.4. По рискам, предусмотренным п. 4.4.4. настоящих Правил, – исходя из стоимости
          изготовления и/или замены утраченных ключей и соответствующего замка, расходов на
          восстановление личных документов, но не может превышать страховую сумму.
          Если иное не предусмотрено Договором страхования, Страховщик по риску
          возникновения расходов в связи с восстановлением похищенных личных документов
          возмещает только расходы Страхователя (Застрахованного) по оплате госпошлины за
          восстановление похищенных личных документов на территории Российской Федерации,
          размер которых регламентирован Налоговым Кодексом РФ, действующим на день
          восстановления похищенных личных документов, а также дополнительные расходы
          Застрахованного, понесенные им в прямой связи с восстановлением утраченных личных
          документов (включая, но не ограничиваясь почтовыми расходами, расходами на копирование
          документов, расходами на получение выписок из архивов, исключая расходы на платные
          услуги по получению документов через третьих лиц).
          10.3. Если иное не предусмотрено Договором страхования, события, предусмотренные
          п.4.4.1 и п.4.4.2., 4.4.4. настоящих Правил, являются страховыми случаями, только если они
          произошли в течение 48 часов, предшествующих моменту уведомления Банка-эмитента о
          случившемся событии и блокировке Застрахованной банковской карты, но не ранее 00 часов 00
          минут дня, указанного в Договоре как день начала действия Договора страхования. Однако
          Страховщик имеет право отказать в выплате страхового возмещения, если Страхователь/
          Выгодоприобретатель/ Держатель Застрахованной банковской карты не сообщил в Банк или в
          указанные Банком организации о случившемся событии в течение 12 (двенадцати) часов с
          момента его обнаружения (т.е. не принял разумных и доступных мер, чтобы уменьшить
          возможные убытки) за исключением случаев, подтвержденных соответствующими
          медицинскими документами, когда по состоянию здоровья на момент такого обнаружения
          Страхователь/ Выгодоприобретатель/ Держатель Застрахованной банковской карты не имел
          возможности сообщить о случившемся событии.
          10.4. Заявление о наступлении события, имеющего признаки страхового случая,
          составляется Страхователем (Выгодоприобретателем) и направляется Страховщику в срок не
          позднее 3 (трех) рабочих дней с момента как Страхователю (Выгодоприобретателю) стало
          известно о произошедшем событии, носящем признаки страхового случая. В заявлении о
          страховом случае указываются сведения, предусмотренные формой заявления, являющегося
          Правила комбинированного страхования имущественных интересов (финансовых рисков) держателей
          банковских карт.
          приложением к настоящим Правилам.
          10.5. Страхователь (Выгодоприобретатель) обязан представить Страховщику наряду с
          вышеуказанным заявлением следующие документы:
          10.5.1. Копию Договора страхования и копию документа, подтверждающих уплату
          страховой премии;
          10.5.2. Документ, удостоверяющий личность, в том числе: паспорт гражданина РФ,
          временное удостоверение личности, удостоверение личности или военный билет
          военнослужащего, общегражданский заграничный паспорт, удостоверение беженца,
          удостоверение личности моряка, паспорт иностранного гражданина либо вид на жительство
          иностранного гражданина, лица без гражданства);
          10.5.3. Договор с Банком – эмитентом, выпустившим Застрахованную(ые)
          банковскую(ие) карту(ы);
          10.5.4. Перечень должностных лиц при страховании корпоративной Банковской карты
          или список членов семьи при страховании семейной Банковской карты, уполномоченных
          проводить операции по Застрахованной банковской карте;
          10.5.5. При наступлении событий, предусмотренных п. 4.4.1. настоящих Правил:
          - справку Банка о стоимости восстановления (изготовления новой) Банковской
          карты;
          - Копию заявления, поданного Держателем Застрахованной банковской карты в
          Банк
          - эмитент, о восстановлении (изготовлении новой) Банковской карты с указанием
          причины необходимости восстановления (изготовления новой) Банковской карты;
          - тарифы Банка, определяющие стоимость восстановления (изготовления новой)
          Банковской карты;
          - выписку со Счета карты на дату списания с Застрахованной банковской карты
          стоимости услуг Банка по восстановлению (изготовлению новой) Банковской
          карты, заверенную Банком.
          10.5.6. При наступлении событий, предусмотренных подп. «б» 4.4.1. настоящих Правил,
          в дополнение к документам, указанным в п. 10.5.5 настоящих Правил, - копию постановления
          о возбуждении, приостановлении или прекращении уголовного дела;
          10.5.7. При наступлении событий, предусмотренных подп. «в» 4.4.1. настоящих Правил,
          в дополнение к документам, указанным в п. 10.5.6 настоящих Правил, – справку Банка –
          эмитента о повреждении Застрахованной банковской карты и непригодности ее к
          дальнейшему использованию (если выдача такой справки предусмотрена Банком –
          эмитентом);
          10.5.8. При наступлении событий, предусмотренных п. 4.4.2. настоящих Правил:
          - документы из правоохранительных органов, удостоверяющие факт
          противоправных действий третьих лиц в отношении Держателя Застрахованной
          банковской карты (копия постановления о возбуждении, приостановлении или
          прекращении уголовного дела и/или справка полиции страны, в которой
          произошло несанкционированное снятие, копия заграничного паспорта, решение
          суда - при передаче дела в суд);
          - справку – отчет по счету Застрахованной банковской карты, содержащий
          информацию о дате операции, месте ее проведения, сумме и валюте, а также
          письмо от Банка о блокировке Застрахованной банковской карты (с указанием
          времени, даты);
          - письменное заключение о проверке Банком обоснованности претензии Клиента –
          Держателя Застрахованной банковской карты (претензия удовлетворена или, в
          случае отказа, с указанием причины отказа Банка в возмещении средств по
          оспоренным операциям);
           письмо от Банка о блокировке Застрахованной банковской карты (с указанием
          Правила комбинированного страхования имущественных интересов (финансовых рисков) держателей
          банковских карт.
          времени, даты);
          - документы, подтверждающие стоимость покупок или услуг (если снятие за
          предоставленные товары или услуги произошло в большем размере, чем указано в
          этих документах);
          - копию заграничного паспорта Страхователя (Выгодоприобретателя) (в случае
          если несанкционированное списание денежных средств с Застрахованной
          банковской карты имело место за пределами РФ);
          10.5.9. Заверенную оператором сотовой связи детализацию (с указанием номеров
          абонентов) входящих и исходящих вызовов, полученных и отправленных смс-сообщений на
          телефонный номер/с телефонного номера Держателя Застрахованной банковской карты, к
          которому подключен Мобильный банк на дату произошедшего события и/или на дату
          получения третьими лицами сведений, в результате которых был переподключен Мобильный
          банк или Интернет-банк, и/или за период времени, запрошенный Страховщиком;
          10.5.10. При наступлении событий, предусмотренных п. 4.4.3. настоящих Правил:
          - копии постановления о возбуждении, приостановлении или прекращении
          уголовного дела и/или справку полиции страны, в которой произошло
          противоправное действие в отношении Держателя Застрахованной банковской
          карты, решение суда - при передаче дела в суд;
          - выписку Банка по счету с указанием транзакции, предшествующей хищению
          и/или документ, выданный и заверенный Банком с информацией о дате, времени,
          месте (адресе нахождения банкомата) получения денежных средств из банкомата
          по Застрахованной банковской карте и полученной сумме из банкомата по
          Застрахованной банковской карте;
          10.5.11. При наступлении событий, предусмотренных п. 4.4.4. настоящих Правил:
          - копии постановления о возбуждении, приостановлении или прекращении
          уголовного дела и/или справку полиции страны, в которой произошло
          противоправное действие в отношении Держателя Застрахованной банковской
          карты, решение суда - при передаче дела в суд;
          - Копию заявления, поданного Держателем Застрахованной банковской карты в
          Банк эмитент, о восстановлении (изготовлении новой) Банковской карты с
          указанием причины необходимости восстановления (изготовления новой)
          Банковской карты;
          - выписку со Счета карты на дату списания с Застрахованной банковской карты
          стоимости услуг Банка по восстановлению (изготовлению новой) Банковской
          карты, заверенную Банком.
          - документы, подтверждающие имущественный интерес Страхователя
          (Застрахованного): свидетельство о государственной регистрации права
          собственности, договор аренды и т.п., ПТС на транспортное средство и т.п.
          - чеки, счета, иные платежные документы, подтверждающие стоимость замены
          утраченных ключей и соответствующего замка.
          - копии восстановленных личных документов.
          - чеки, счета, иные платежные документы, подтверждающие расходы на
          восстановление личных документов.
          10.5.12. Банковские реквизиты для перечисления страхового возмещения
          10.6. Страховщик, если это не противоречит законодательству Российской Федерации,
          вправе самостоятельно собирать информацию, необходимую для суждения о размере убытка и
          причинах его возникновения, а также подтверждающую факт наступления страхового случая и
          наличия у Страхователя (Выгодоприобретателя) имущественного интереса.
          10.7. К рассмотрению Страховщиком принимаются оригиналы или заверенные в
          соответствующих компетентных органах копии документов, указанных в данном разделе
          настоящих Правил, на бумажном носителе. Все документы, представляемые Страхователем
          Правила комбинированного страхования имущественных интересов (финансовых рисков) держателей
          банковских карт.
          (Выгодоприобретателем), должны быть оформлены на русском языке. Документы,
          составленные на иностранном языке должны быть переведены на русский язык
          соответствующим Бюро переводов или дипломированным переводчиком, если иное не
          предусмотрено Договором страхования.
          10.8. По решению Страховщика перечень документов, приведенный в п. 10.5.
          настоящих Правил, может быть сокращен, если это не влияет на оценку факта наступления
          страхового случая, а также определение размера ущерба и получателя страхового возмещения.
          10.9. Если причиненный Страхователю (Выгодоприобретателю) ущерб частично
          компенсирован другими лицами, в том числе Банком, то Страховщик выплачивает только
          разницу между суммой, подлежащей возмещению по Договору страхования, и суммой, которая
          была компенсирована другими лицами.
          10.10. Из суммы страхового возмещения вычитается франшиза и суммы, полученные
          Страхователем (Выгодоприобретателем) в возмещение данного ущерба от других лиц, а в
          случае двойного страхования возмещение по убытку производится с применением положений
          законодательства РФ.
          10.11. Если компенсация ущерба другими лицами наступила позднее выплаты
          страхового возмещения, то Страхователь (Выгодоприобретатель) обязан вернуть Страховщику
          разницу между выплаченной суммой страхового возмещения и суммой полученной
          компенсации.
          10.12. Если страховой случай наступил до оплаты очередного страхового взноса,
          внесение которого просрочено, Страховщик в случае предоставления отсрочки Страхователю в
          оплате страхового взноса вправе при определении размера страховой выплаты зачесть сумму
          просроченного страхового взноса.
          10.13. Если иное не предусмотрено Договором страхования, после выплаты страхового
          возмещения страховая сумма уменьшается на величину выплаченного страхового возмещения.
          10.14. Страховщик имеет право отсрочить выплату страхового возмещения в случае:
          10.14.1. Если соответствующими органами внутренних дел возбуждено уголовное
          дело против Страхователя или его уполномоченных лиц и ведется расследование
          обстоятельств, приведших к причинению ущерба — до окончания расследования. О факте
          отсрочки Страховщик направляет Страхователю письменное уведомление в течение 10
          (десяти) рабочих дней с момента принятия Страховщиком решения об отсрочке.
      </ul>
    </div>
    <div class="rules-block">
      <h4 class="rules-heading">ОТКАЗ В ВЫПЛАТЕ СТРАХОВОГО ВОЗМЕЩЕНИЯ</h4>
      <ul class="main-list">
        <li>11.1. Страховщик имеет право полностью или частично отказать в выплате страхового
          возмещения, если:
          11.1.1. Страхователь/Застрахованное лицо (Выгодоприобретатель) не исполнил
          обязанностей, предусмотренных п. 10.5. настоящих Правил, если не будет доказано, что
          Страховщик своевременно узнал о наступлении страхового случая либо что отсутствие у
          Страховщика сведений об этом не могло сказаться на его обязанности выплатить страховое
          возмещение.
          11.1.2. Страхователь/Застрахованное лицо (Выгодоприобретатель) не предоставил
          Страховщику документы, предусмотренные настоящими Правилами, и необходимые для
          принятия решения о выплате страхового возмещения, или представил документы,
          оформленные с нарушением общепринятых требований и (или) требований, указанных в
          настоящих Правилах.
          11.2. Страховщик освобождается от выплаты страхового возмещения:
          11.2.1. Если Страхователь/ Застрахованное лицо (Выгодоприобретатель) умышленно не
          принял разумных и доступных ему мер, чтобы уменьшить возможные убытки от страхового
          случая;
          Правила комбинированного страхования имущественных интересов (финансовых рисков) держателей
          банковских карт.
          11.2.2. Если страховой случай наступил вследствие умысла Страхователя/Застрахованного
          лица (Выгодоприобретателя);
          11.2.3. Если страховой случай наступил вследствие воздействия ядерного взрыва,
          радиации и радиоактивного заражения;
          11.2.4. Если страховой случай наступил вследствие военных действий, а также маневров
          или иных военных мероприятий;
          11.2.5. Если страховой случай наступил вследствие гражданской войны, народных
          волнений всякого рода или забастовок;
          11.2.6. Если страховой случай наступил вследствие изъятия, конфискации, реквизиции,
          ареста или уничтожения Устройства по распоряжению государственных органов.
          11.3. Если Страхователь при заключении договора страхования или во время его действия
          сообщил заведомо ложные сведения, Страховщик вправе требовать признания договора
          страхования недействительным и применения последствий, предусмотренных Гражданским
          кодексом Российской Федерации.
          Страховщик не вправе отказать в страховой выплате по основаниям, не предусмотренным
          законодательством, настоящими Правилами или договором страхования (Полисом).</li>
      </ul>
    </div>
    <div class="rules-block">
      <h4 class="rules-heading">ПЕРЕХОД ПРАВ ТРЕБОВАНИЙ</h4>
      <ul class="main-list">
        <li>12.1. Если Договором страхования не предусмотрено иное, к Страховщику,
          выплатившему страховое возмещение, переходит в пределах выплаченной суммы право
          требования, которое Страхователь (Выгодоприобретатель) имеет к лицу, ответственному за
          ущерб, возмещенный в результате страхования. Страхователь (Выгодоприобретатель) обязан
          передать Страховщику все документы и доказательства, сообщить ему в письменном виде все
          сведения и произвести все действия, необходимые для осуществления Страховщиком
          перешедшего к нему права требования, которое Страхователь (Выгодоприобретатель) имеет к
          лицу, ответственному за ущерб, возмещенный в результате страхования.
          12.2. Если Страхователь (Выгодоприобретатель) откажется от таких прав или
          осуществление этих прав окажется невозможным по его вине, то Страховщик освобождается
          от обязанности выплачивать страховое возмещение в размере ущерба, причиненного ему
          вышеуказанными действиями. В случае если выплата возмещения уже произведена,
          Страхователь (Выгодоприобретатель) обязан возвратить Страховщику соответствующую
          этому ущербу сумму.
          12.3. Если Страхователь (Выгодоприобретатель) получил возмещение убытка от
          третьих лиц, Страховщик выплачивает лишь разницу между суммой, подлежащей выплате по
          условиям страхования, и суммой, полученной от третьих лиц. Страхователь
          (Выгодоприобретатель) обязан немедленно известить Страховщика о получении таких сумм.
          12.4. В соответствии со статьей 382 Гражданского кодекса Российской Федерации,
          передача прав страхователя, выгодоприобретателя, застрахованного, потерпевшего другому
          лицу по сделке (уступка требования) в силу условий договора страхования, заключенного на
          основании настоящих Правил (условий) страхования, запрещается.</li>
      </ul>
    </div>
    <div class="rules-block">
      <h4 class="rules-heading">ПОРЯДОК РАЗРЕШЕНИЯ СПОРОВ</h4>
      <ul class="main-list">
        <li>13.1. Споры, вытекающие из Договора страхования, разрешаются сторонами в
          обязательном досудебном порядке, путем направления письменной претензии. В случае не
          разрешения спора в досудебном порядке, споры разрешаются судом в соответствии с
          законодательством Российской Федерации.</li>
      </ul>
    </div>
  </div>
</section>
