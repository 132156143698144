import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  RouterStateSnapshot
} from '@angular/router';

import { AuthService } from '../services/auth/auth.service';
import { UserService } from '../services/auth/user.service';

// import { logger } from '../logger.fn';

// const log = logger(__filename);

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(private authService: AuthService,
              private userService: UserService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const url: string = state.url;

    return this.checkLogin(url);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return this.canActivate(route, state);
  }

  canLoad(route: Route): Promise<boolean> {
    const url = `/${route.path}`;

    return this.checkLogin(url);
  }

  checkLogin(url: string): Promise<boolean> {
    return this.authService.check().then((isLoggedIn) => {
      if (isLoggedIn && this.userService.isEmailConfirmed()) {
        return true;
      }

      this.authService.redirectUrl = '/credits'; // url !== '/error' && url.indexOf('insurance') === -1 ? url : '/credits';
      this.authService.redirectToLoginForm();

      return false;
    });
  }
}
