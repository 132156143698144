import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class PdpDetailsService {

  createListOfMonth(schedules: { date: Date; amount: number }[]) {
    const months = schedules.map(item => moment(item.date).format('MMMM')).join(', ');

    const x = months.split('');
    if (months.lastIndexOf(',') > 0) {
      const lastComma = months.lastIndexOf(',');
      x.splice(lastComma, 1, ' ', 'и');
    }
    return x.join('');
  }

  calculateSumForMonths(schedules: { date: Date; amount: number }[]) {
    let sum = 0;
    schedules.map(el => sum += el.amount);
    return (sum / 100).toString();
  }

  getStringOfMonth(schedules: { date: Date; amount: number }[]) {
    const nameOfDays = ['Один', 'Два', 'Три', 'Четыре'];
    let countMonth: string = nameOfDays[schedules.length - 1];
    countMonth += schedules.length === 1 ? ' ежемесячный платеж' : ' ежемесячных платежа';
    return countMonth;
  }
}
