import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { CreditsService } from '../../services/credits/credits.service';

@Injectable()
export class CreditsResolver implements Resolve<any> {

  constructor(private creditService: CreditsService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return this.creditService.loadDashboard();
  }
}
