<form [formGroup]="cardEditGroup" *ngIf="!!cardEditGroup">
  <div class="wrap">
    <div class="btn-back btn-back--arrow" (click)="returnToPayments()">
      <div class="btn-back--icon">
        <mat-icon  style="width: 21px; height: 21px" svgIcon="back-arrow"></mat-icon>
      </div>
      <span class="info__descr">Редактирование имени карты</span>
    </div>
    <div class="card-edit-wrapper">
      <div class="card-edit-form">
        <div class="card-edit-frame" [ngClass]="{'frame-deletion': cardEditMode === 'deletion'}">
          <div class="bank-logo"></div>
          <div class="underline">
            <input
                type="text"
                class="card-name-edit"
                underline-focus
                value="{{currentCard.name}}"
                name="card_name"
                formControlName="card_name"
                [maxLength]="30"
            >
          </div>
          <div class="card-provider-logo"></div>
          <span class="short-pan">*{{ currentCard.short_pan }}</span>
        </div>
        <ng-container *ngIf="cardEditMode === 'editing'">
          <div class="card-edit-form__buttons">
            <button type="button" class="btn-secondary" (click)="toggleDeleteCard()">Удалить карту</button>
            <button type="button" class="btn-main" (click)="changeCardName()" [disabled]="!formIsValid()">Сохранить</button>
          </div>
        </ng-container>
        <ng-container *ngIf="cardEditMode === 'deletion'">
          <p class="deletion-text">Вы собираетесь удалить карту *{{ currentCard.short_pan }}</p>
          <div class="card-edit-form__buttons">
            <button type="button" class="btn-secondary" (click)="changeMode('editing')">Отменить</button>
            <button type="button" class="btn-main" (click)="deleteCard()">Подтвердить</button>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</form>
