import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { ExtraServiceModel, ExtraServicesModel } from '../../models/extra-services.model';

@Component({
  selector: 'app-notice-terminate',
  templateUrl: './notice-terminate.component.html',
  styleUrls: ['./notice-terminate.component.scss']
})
export class NoticeTerminateComponent implements OnInit {

  @Output() closeEvent: EventEmitter<any> = new EventEmitter();

  extraServices: ExtraServiceModel[] = [];

  constructor(
    private apiService: ApiService
  ) {
  }

  ngOnInit() {
    this.apiService.getExtraServices().subscribe((services: ExtraServicesModel) => {
      this.extraServices = services.extra_services;
    });
  }

  close() {
    this.closeEvent.emit();
  }
}
