import { Injectable } from '@angular/core';
import { find, get } from 'lodash';
import { HttpErrorResponse } from '@angular/common/http';
import { statusCodesConstant } from '../constants/status-codes.constant';
import { errorCodesConstant } from '../constants/error-codes.constant';

@Injectable()
export class ErrorService {
  public getHttpErrorMsg(error: HttpErrorResponse): string | number {
    const errorCode = get(error, 'error.error_code', '');

    if (errorCode) {
      return errorCode === 1051 ? this.getErrorMessageByCode(errorCode, error.error.new_cft_id) : this.getErrorMessageByCode(errorCode);
    } else {
      return this.getErrorMessageByStatus(error.status, error.statusText);
    }
  }

  getErrorMessageByStatus(status: string|number, statusText = '') {
    const errorMessage: string = 'Ошибка при выполнении запроса. Статус: ' + status + ' ' + statusText;
    const result = statusCodesConstant.find((code) => code[0] === status);

    return result && result.length > 0 ? result[1] : errorMessage;
  }

  getErrorMessageByCode(code: any, newCftId?: string) {
    const errorMessage: string = 'Код ошибки: ' + code;
    const result = find(errorCodesConstant, (value: Array<string>) => value[0] === code);

    if (!result || result.length <= 0) {
      return errorMessage;
    }

    if (code === 1051) {
      return `${result[1].toString().replace(/%newCftId%/gi, newCftId)}. ${errorMessage}`;
    } else {
      return `${result[1]}. ${errorMessage}`;
    }
  }
}
