import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { DeviceMetadataModel } from "../models/device-metadata.model";
import { DeviceMetadataService } from './device-metadata.service';

@Injectable()
export class ApiService {

  constructor(
    private http: HttpClient,
    private deviceMetadataService: DeviceMetadataService
  ) {
  }

  uploadFile(formData: FormData ) {
    const url = `/documents/file`;
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    return this.http.post(url, formData);
  }

  sendEsignDocument(id: number, email: string) {
    const url = `/esign/send_documents`;
    return this.http.post(url, { id, email });
  }

  async activityLog(request_id: number, action: string) {
    const url = '/activity/log';

    await this.http.post(url, {
      DEVICE_OS: 'web',
      DEVICE_BUILD: 2003,
      DEVICE_TIMESTAMP: (new Date()).getTime(),
      ACTION: action,
      request_id
    }).toPromise();
  }

  autocompleteEmailSuggest(prefix: string) {
    const url = `/documents/email/suggest`;
    const params = new HttpParams().append('email_prefix', `${encodeURIComponent(prefix)}`);

    return this.http.get(url, { params });
  }

  sendEmail(email: string) {
    const url = `/user/info`;

    return this.http.post(url, { email, metadata: this.deviceMetadataService.getDeviceMetadata() });
  }

  getRestriction() {
    const url = `/user/accounts/restrictions`;

    return this.http.get(url);
  }

  getExtraServices() {
    const url = `/user/extra_services`;

    return this.http.get(url);
  }

  getDeposits() {
    const url = '/user/dashboard';

    return this.http.get(url);
  }

  getExtraAgreement(id: string) {
    const url = '/extra_agreement';

    return this.http.get(url, {
      params: {
        extra_agreement_id: id,
      },
    });
  }

  getExtraAgreements() {
    const url = '/extra_agreement';

    return this.http.get(url, {
      params: {
        extra_agreement_id: 0,
      },
    });
  }

  signExtraAgreement(id: string) {
    const url = '/extra_agreement/sign';
    const metadata = this.deviceMetadataService.getDeviceMetadata();

    return this.http.post(url, {
        extra_agreement_id: id,
        metadata
    })
  }
}
