import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { HelperService } from './helper.service';
import { saveAs } from 'file-saver';

// const log = logger(__filename);

@Injectable()
export class FileDownloaderService {
  constructor(
    private http: HttpClient,
    private helperService: HelperService
  ) { }

  download(
    uri: string,
    optional: {
      params?: any,
      defaultFilename?: string,
      defaultResponseFileType?: string
    } = {
      params: {},
      defaultFilename: 'file',
      defaultResponseFileType: ''
    }
  ) {
    let headers = new HttpHeaders();

    headers = headers.append('Accept', optional.defaultResponseFileType || '*/*');

    const params = optional.params;

    const req = new HttpRequest('GET', uri, {
      responseType: 'arraybuffer',
      headers,
      params,
    });

    this.http
      .request(req)
      .toPromise()
      .then(this.saveToFileSystem.bind(this, optional.defaultFilename, optional.defaultResponseFileType))
      .catch((error) => this.helperService.error('Не удалось загрузить файл', error));
  }

  saveToFileSystem(defaultFilename = 'file', defaultResponseFileType = '', response: any) {
    if (! response) {
      // log('response is empty, response', response);
      return;
    }

    const headers = response.headers;

    const fileName = this.parseFileName(headers.get('Content-Disposition')) || defaultFilename;
    const fileType = this.parseFileType(headers.get('Content-Type')) || defaultResponseFileType;

    // log(`try to save: ${fileName} as blob type: ${fileType}`);

    const blob = new Blob([response.body], { type: fileType });
    saveAs(blob, fileName);
  }

  parseFileName(contentDispositionHeader: string) {
    if (contentDispositionHeader) {
      const parts: string[] = contentDispositionHeader.split(';');
      return parts[1].split('=')[1];
    }
    return '';
  }

  parseFileType(contentTypeHeader: string) {
    return contentTypeHeader && contentTypeHeader !== 'multipart/form-data'
      ? contentTypeHeader
      : '';
  }

}
