<label class="underline">
  <input [(ngModel)]="email"
         (ngModelChange)="onEmailChange()"
         name="email-search"
         type="text"
         autocomplete="off"
         class="form__input form__input--noindent"
         maxlength="255"
  >
  <ng-container>
    <div class="autocomplete" [ngClass]="{'active': true}">
      <div class="autocomplete__drop">
        <div class="autocomplete__items">
          <div *ngFor="let suggest of suggest.suggestions"
               class="autocomplete__item"
               (click)="selectEmail(suggest)"
          >
            <div class="autocomplete__item-name">
              {{ suggest }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</label>

<p *ngIf="suggest.is_valid !== undefined && !suggest.is_valid && email.length > 0" class="error-text">
  Неверный e-mail
</p>

<p *ngIf="isAlreadyTaken" class="error-text">
  Указанный e-mail уже зарегистрирован у другого пользователя
</p>

<p *ngIf="isAlreadyRegistered" class="error-text">
  Указанный e-mail уже у вас зарегистрирован
</p>

<p *ngIf="isAlreadySent" class="error-text">
  Повторное подтверждение электронной почты доступно через 15 минут
</p>

