import { Injectable } from '@angular/core';
import * as sha256 from 'sha256';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ProlongationsModel } from '../models/prolongation/prolongations.model';
import { DeviceMetadataService } from './device-metadata.service';
import { BehaviorSubject } from 'rxjs';
import { UserService } from './auth/user.service';
import { ProlongationConditionResModel } from '../models/prolongation/prolongation-condition-res.model';
import { ProlongationConfirmationModel } from '../models/prolongation/prolongation-confirmation.model';
import { HelperService } from './helper.service';

@Injectable()
export class ProlongationApiService {

  dealId$: BehaviorSubject<number> = new BehaviorSubject<number>(null);

  constructor(
    private client: HttpClient,
    private helper: HelperService,
    private userService: UserService,
    private deviceMetadataService: DeviceMetadataService
  ) {
  }

  getProlongationConditions(credId: number) {
    const url = '/prolongation/conditions';
    const params = new HttpParams().append('CRED_ID', credId ? credId.toString() : '');
    return this.client
      .get(url, {params})
      .toPromise()
      .then((res: ProlongationConditionResModel) => {
        return res;
      })
      .catch(err => new ProlongationConditionResModel(false));
  }

  initProlongation(id: number) {
    const url = '/prolongation/init';
    return this.client
      .post(url, JSON.stringify({
        id,
        metadata: this.deviceMetadataService.getDeviceMetadata()
      }))
      .toPromise()
      .then((res: ProlongationConfirmationModel) => {
        return res;
      })
      .catch(err => new Error('Не удалось загрузить условия'));
  }

  smsConfirmation(id: string, code: string) {
    const url = '/sms/confirm';
    const codeHash = sha256(this.userService.userInfo.CLIENT.trim() + 'salt' + code);
    return this.client
      .post(url, JSON.stringify({
        request_id: id,
        code_hash: codeHash,
        metadata: this.deviceMetadataService.getDeviceMetadata()
      }))
      .toPromise()
      .then(res => {
        return res;
      })
      .catch((error) => error);
  }

  smsResend(requestId: string) {
    const url = '/sms/resend';
    return this.client
      .post(url, JSON.stringify({
        request_id: requestId,
        metadata: this.deviceMetadataService.getDeviceMetadata()
      }))
      .toPromise()
      .then((res) => res)
      .catch(err => this.helper.error('Не удалось повторно отослать sms', err));
  }

  getCompleteProlongations() {
    const url = '/prolongations';
    return this.client
      .get(url)
      .toPromise()
      .then((res: ProlongationsModel) => {
        return res;
      })
      .catch(err => this.helper.error('Не удалось получить данные по выполненным пролонгациям', err));
  }

  sendAgreementToEmail(id: number, email?: string) {
    const url = '/prolongation/send';
    const bodyRequest = email ? {id, email} : {id};
    return this.client
      .post(url, JSON.stringify(bodyRequest))
      .toPromise()
      .then(res => {
        return {
          heading: 'Соглашение было отправленно на почту',
          status: 'success',
          textForButton: 'Продолжить работу'
        };
      })
      .catch(err => {
        return {
          heading: 'Не удалось отправить соглашение на почту',
          status: 'error',
          textForButton: 'Попробовать еще раз'
        };
      });
  }
}
