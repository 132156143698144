import { Component, Inject } from '@angular/core';
import { EventEmitter } from 'events';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  templateUrl: 'alert-modal.component.html',
  styleUrls: ['alert-modal.component.scss']
})
export class AlertModal {
  onClose = new EventEmitter();

  constructor(
    private matDialogRef: MatDialogRef<AlertModal>,
    @Inject(MAT_DIALOG_DATA) public data: { msg: string, title: string, closeCallback: () => {} }
  ) {
  }

  get msg(): string {
    return this.data.msg;
  }

  get title(): string {
    return this.data.title;
  }

  close(isBtn = false) {
    if (isBtn) { this.data.closeCallback(); }

    this.matDialogRef.close();
  }

  // close(isBtn = false) {
  //   if (isBtn) {
  //     this.onClose.emit('close');
  //   }
  //   this.activeModal.close();
  // }
}
