import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class UploadImageService {

  constructor(private http: HttpClient) { }

  loadImage(imageBuffer: ArrayBuffer | string, name = '') {
    const url = '/documents/file';
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');

    const file: File = new File([imageBuffer], name);
    const formData = new FormData();
    formData.append('file', file);

    return this.http.post(url, formData, { headers });
  }
}
