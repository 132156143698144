export class SuccessFormModel {
  constructor(
    public _type: 'success' | 'waiting' | 'error' | 'await' = 'success',
    public _successHeading?: string,
    public _successText?: string,
    public _link?: string,
    public _textForLink?: string,
    public _buttonText?: string,
    public _secondButtonText?: string,
    public _firstMainButtonText?: string,
    public _secondMainButtonText?: string
  ) {
  }

  get type() { return this._type; }
  get successHeading() { return this._successHeading; }
  get successText() { return this._successText; }
  get link() { return this._link; }
  get textForLink() { return this._textForLink; }
  get buttonText() { return this._buttonText; }
  get secondButtonText() { return this._secondButtonText; }
  get firstMainButtonText() { return this._firstMainButtonText; }
  get secondMainButtonText() { return this._secondMainButtonText; }

  set type(t: 'success' | 'waiting' | 'error' | 'await') {
    this._type = t;
  }

  set successHeading(header: string) {
    this._successHeading = header;
  }

  set successText(text: string) {
    this._successText = text;
  }

  set link(link: string) {
    this._link = link;
  }

  set textForLink(textForLink: string) {
    this._textForLink = textForLink;
  }

  set buttonText(buttonText: string) {
    this._buttonText = buttonText;
  }

  set secondButtonText(buttonText: string) {
    this._secondButtonText = buttonText;
  }

  set firstMainButtonText(buttonText: string) {
    this._firstMainButtonText = buttonText;
  }

  set secondMainButtonText(buttonText: string) {
    this._secondMainButtonText = buttonText;
  }
}
