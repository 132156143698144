import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class CardInsuranceService {
    private isInit = false;
    private readonly $protection: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    prepare(status) {
      if (this.isInit) { return; }

      this.isInit = true;
      this.protection = status;
    }

    get $protectionSubject(): BehaviorSubject<boolean> {
      return this.$protection;
    }

    set protection(status: boolean) {
      if (this.protection === status) {
        return;
      }

      this.$protection.next(status);
    }

    get protection(): boolean {
      if (this.$protection.value === undefined) {
        return false;
      } else {
        return this.$protection.value;
      }
    }
}
