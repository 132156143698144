<div class="modal modal__min">
  <div class="modal__header">
    <span class="modal__title">{{ title }}</span>
    <div>
      <button class="modal__close" (click)="close()">
        <img class="modal__close-icon" src="assets/images/svg/cross.svg" alt="">
      </button>
    </div>
  </div>
  <div class="modal__description">{{ msg }}</div>
  <div class="modal__actions">
    <button class="btn btn-main" (click)="close(true)">Ок</button>
  </div>
</div>
