export class ServerConstantsModel {
  constructor(
    public values?: ServerConstantsValuesModel
  ) {
  }
}

export class ServerConstantsValuesModel {
  constructor(
    public sms_sender_login?: string
  ) {
  }
}
