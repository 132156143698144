import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

@Injectable()
export class CardEditFormService {
  constructor(
    private formBuilder: FormBuilder,
  ) {}

  buildForm(name: string) {
    return this.formBuilder.group({
      card_name: [name || '', [Validators.maxLength(30)]]
    });
  }

}
