import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { debounceTime, distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ApiService } from '../../services/api.service';
import { EmailSuggestModel } from '../../models/email-suggest.model';
import { AutocompleteService } from '../../services/autocomplete.service';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss']
})
export class AutocompleteComponent implements OnInit {

  @Input() initValue = '';

  @Output() selectEmailEvent = new EventEmitter<string>();
  @Output() isValidEvent = new EventEmitter<boolean>();

  email = '';
  suggest: EmailSuggestModel = new EmailSuggestModel();
  isAlreadyTaken = false;
  isAlreadySent = false;
  isAlreadyRegistered = false;

  private emailDomainSource = new Subject<string>();
  private emailDomainChange$ = this.emailDomainSource.asObservable();
  private unsubscribe$ = new Subject();

  constructor(
    private apiService: ApiService,
    private autocompleteService: AutocompleteService
  ) {
    this.autocompleteService.clear$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.clearEmail();
    });
  }

  ngOnInit() {
    this.email = this.initValue;

    this.initSearchStream();

    this.autocompleteService.errorCode$.subscribe((code: number) => {
      if (code === 1060) {
        this.isAlreadyTaken = true;
      }

      if (code === 1061) {
        this.isAlreadyRegistered = true;
      }

      if (code === 1062) {
        this.isAlreadySent = true;
      }

      this.isValidEvent.emit(false);
    });
  }

  initSearchStream() {
    this.emailDomainChange$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      takeUntil(this.unsubscribe$),
      filter((res) => !!res)
    ).subscribe((email: any) => {
      this.apiService.autocompleteEmailSuggest(email).subscribe((suggest: EmailSuggestModel) => {
        if (suggest.is_email_full) {
          this.selectEmail(email);
          return;
        }

        this.suggest = suggest;
        this.isValidEvent.emit(suggest.is_valid);
      });
    });
  }

  onEmailChange() {
    this.isAlreadyTaken = false;
    this.isAlreadyRegistered = false;
    this.isAlreadySent = false;

    this.selectEmailEvent.emit('');
    this.emailDomainSource.next(this.email);
  }

  selectEmail(email: string) {
    this.suggest.suggestions = [];
    this.email = email;
    this.selectEmailEvent.emit(email);
  }

  clearEmail() {
    this.email = null;
    this.suggest = new EmailSuggestModel();
    this.isAlreadyTaken = false;
    this.isAlreadySent = false;
    this.isAlreadyRegistered = false;
  }
}
