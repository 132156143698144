import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DeviceMetadataService } from './device-metadata.service';
import { ChangePasswordModel } from '../models/settings/change-password.model';
import { AuthService } from './auth/auth.service';
import { HelperService } from './helper.service';

@Injectable()
export class SettingsService {

  constructor(
    private http: HttpClient,
    private helperService: HelperService,
    private deviceMetadataService: DeviceMetadataService,
    private authService: AuthService
  ) {
  }

  /**
   * Запрос к API на смену пароля
   * @param password - новый и старый пароли
   */
  changePassword(password: ChangePasswordModel) {
    const url = `/web/auth/password`;
    const headers = new HttpHeaders()
      .set('X-Client-Token', this.authService.getToken());

    return this.http.post(url, JSON.stringify({
      password: password.password,
      old_password: password.old_password,
      metadata: this.deviceMetadataService.getDeviceMetadata()
    }), { headers });
  }

  /**
   * Запрос к API на смену почты
   */
  async changeEmail(email: string, notify = true, notifyCloseCallback = () => {}, errorNotify = true) {
    const url = `/user/info`;

    try {
      await this.http.post(url, JSON.stringify({
        email,
        metadata: this.deviceMetadataService.getDeviceMetadata()
      })).toPromise();

      if (!notify) {
        return;
      }

      this.helperService.alert('Проверьте, пожалуйста, почту и перейдите по ссылке в письме для подтверждения.', undefined, notifyCloseCallback);
    } catch (error) {
      if (errorNotify) {
        this.helperService.error('Не удалось сменить почту', error);
      }
      return Promise.reject(error);
    }
  }
}
