<div class="wrap" [ngClass]="{'wrap--auto' :
selected === 'iframe' ||
selected === 'slider' ||
selected === 'verify'
}">
  <form [formGroup]="payGroup" *ngIf="!!payGroup">
    <ng-container *ngIf="selected === 'payment'">
      <div class="info">
        <div class="info__items">
          <div class="info__item">
            <ng-container *ngIf="subtitle">
              <div class="info__title info__title--black mb-0">{{ title }}</div>
              <div class="info__descr mb-3">{{ subtitle }}</div>
            </ng-container>
            <ng-container *ngIf="!subtitle">
              <div class="info__title">{{ title }}</div>
            </ng-container>

            <app-card-list  *ngIf="payGroup"
                            [form]="payGroup"
                            [payment]="payment"
                            [amount]="amount.toString()"
                            [isAuto]="false"
                            [currentSlide]="selectedCard"
                            (selectedCardEvent)="setSelectedCard($event)"
                            (lastSlide)="setLastSlide($event)"
                            (cardEditEvent)="goToCardEdit($event)"
            >
            </app-card-list>

            <div class="form">
              <div class="form__row">
                <span class="form__title">Сумма пополнения</span>
                <div class="form__elem">
                  <div class="price__content">
                    <label class="underline">
                      <input formControlName="amount"
                             type="text"
                             class="form__input form__input--noindent"
                             currency-input
                             maxlength="10"
                             underline-focus
                             [class.fixed]="isFixed()"
                             [disabled]="isFixed()"
                             [readOnly]="isFixed()"
                             (input)="amountChange($event)"
                      >
                      <span class="floating-ruble"> ₽</span>
                    </label>
                  </div>
                  <p class="error-text">{{ amountValid() || error }}</p>
                </div>
              </div>
              <div class="form__row" *ngIf="!cardInsurance">
                <div class="form__title">Комиссия</div>
                <div class="form__elem">
                  <span class="form__text">{{ fee | moneySpaces }} ₽</span>
                </div>
              </div>
            </div>

            <ng-container *ngIf="!offProtection()">
              <insurance-panel *ngIf="cardInsurance"
                               [cardInsurance]="cardInsurance"
                               [amount]="getAmount()"
                               [spinner]="spinner"
                               (showSliderEvent)="showSlider($event)"
              ></insurance-panel>

              <div *ngIf="cardProtection" class="insurance-row" >
                <p class="label">Защита карт</p>
                <p class="amount">{{ getMonthlyPayment() | moneySpaces }} ₽</p>
              </div>
            </ng-container>

            <div class="form__row">
              <span class="form__title">
                Комиссия за перевод
              </span>
              <span *ngIf="!cardProtection" class="form__text">
                {{ fee | moneySpaces }} ₽
              </span>
              <span *ngIf="cardProtection" class="form__text" >
                0 ₽
              </span>
            </div>
            <div *ngIf="!offEmailInput()" class="insurance-row">
              <p class="label">Электронная почта</p>
              <div class="user-email">
                <div class="underline">
                  <input type="email" formControlName="email">
                </div>
              </div>
            </div>

            <div *ngIf="cardProtection && !!cardInsurance.conditions.key_document_link"
                 class="insurance-row"
            >
              <div class="insurance-row mt-3 mb-0">
                <div class="flex-fill">
                  Подтверждаю, что с
                  <a class="text-primary cursor-pointer" (click)="openInIframe()">
                    ключевым информационным документом
                  </a>
                  ознакомлен
                </div>
              </div>
            </div>

            <div class="insurance-row" *ngIf="cardProtection">
              <div class="insurance-row mt-3 mb-0">
                <div class="flex-fill">
                  Перед оплатой необходимо подтвердить свое согласие с
                  <a class="text-primary cursor-pointer" (click)="showInfo()">
                    условиями страхования
                  </a>
                </div>
              </div>
            </div>

            <ng-container *ngIf="!!pdpStatus">
              <div class="form__row form__row--wide">
                <span class="form__text">До {{ beautifyDate(this.pdpStatus.extended_info.date) }} у вас будут списаны со счета суммы</span>
              </div>

              <ng-container *ngFor="let payment of pdpStatus.extended_info.payments; let index = index; let last = last">
                <div class="form__row form__row--wide">
                  <span class="form__title">
                    {{ payment.description + ' ' + pdpStatus.credit_number + ' на ' + pdpStatus.mark_and_model + ' ' + beautifyDate(payment.date) }}
                  </span>
                </div>
                <div class="form__row">
                  <span class="form__title">Сумма к оплате</span>
                  <span class="form__text font-size-big"
                        [ngClass]="{'font-size-big': last}"
                  >
                    {{ payment.amount / 100 | moneySpaces }} ₽
                  </span>
                </div>
                <div class="info__items indent" *ngIf="!!payment.pdp_details">
                  <span class="info__title">Из чего состоит сумма</span>
                  <ng-container *ngFor="let schedule of payment.pdp_details.schedules">
                    <div class="form__row">
                      <span class="form__title ">{{ getStringOfMonth(payment.pdp_details.schedules) }} <br/>
                        <span>({{ createListOfMonth(payment.pdp_details.schedules) }})</span>
                      </span>
                      <span class="form__text">{{ +calculateSumForMonths(payment.pdp_details.schedules) | moneySpaces }} ₽</span>
                    </div>
                  </ng-container>
                  <div class="form__row">
                    <span class="form__title">Оставшаяся сумма кредита</span>
                  </div>
                  <div class="form__row">
                    <span class="form__title pd-left">Основной долг</span>
                    <span class="form__text">{{ payment.pdp_details.pdp.body / 100 | moneySpaces }} ₽</span>
                  </div>
                  <div class="form__row">
                    <span class="form__title pd-left">Проценты</span>
                    <span class="form__text">{{ payment.pdp_details.pdp.percents / 100 | moneySpaces }} ₽</span>
                  </div>
                </div>
              </ng-container>
            </ng-container>

            <div class="insurance-row mt-3 mb-0">
              <div class="flex-fill">
                Производя оплату, Вы соглашаетесь с
                <a class="text-primary cursor-pointer" (click)="showDisclaimer()">условиями сервиса платежной системы</a>
              </div>
            </div>

            <div *ngIf="cardProtection" class="insurance-row mt-3 mb-0">
              <div class="flex-fill">
                <div class="form-check">
                  <input class="form-check__input"
                         id="agreed"
                         name="agreed"
                         type="checkbox"
                         formControlName="confirm"
                  >
                  <label class="form-check__label" for="agreed">Согласен с вышеуказанными условиями</label>
                </div>
              </div>
            </div>

            <ng-container *ngIf="!!this.paymentFee.payment_processing_disclaimer">
                <div class="insurance-row mt-3 mb-0">
                  {{ this.paymentFee.payment_processing_disclaimer }}
                </div>
            </ng-container>

            <div class="info__buttons">
              <button type="button" class="btn btn-main"
                      [disabled]="blockSubmit"
                      (click)="onSubmit()"
              >
                К оплате {{ getAmountToPay() }} ₽
              </button>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="selected === 'slider'">
      <insurance-slider [insurance_limit]="insuranceLimit"
                        [amount]="amount"
                        (closeSliderEvent)="close()"
      ></insurance-slider>
    </ng-container>

    <ng-container *ngIf="selected === 'edit'">
      <card-edit [currentCard]="editableCard"
                 (cardDeleteEvent)="cardDelete($event)"
                 (changeNameEvent)="changeCardName($event)"
                 (returnToPaymentsEvent)="close()"
      ></card-edit>
    </ng-container>

    <ng-container *ngIf="selected === 'verify'">
      <payment-confirm [confirmationModel]="confirmationModel"
                       [id]="cardInsurance.conditions.id.toString()"
                       (backEvent)="goToPayment()"
                       (confirmEvent)="processPayment()"
      ></payment-confirm>
    </ng-container>

    <ng-container *ngIf="selected === 'iframe'">
      <div>
        <div class="info">
          <div class="info__container mb-5">
            <div class="btn-back btn-back--arrow mb-2" (click)="returnToPayment()">
              <div class="btn-back--icon">
                <mat-icon  style="width: 21px; height: 21px" svgIcon="back-arrow"></mat-icon>
              </div>
              <span class="info__descr">Назад</span>
            </div>
          </div>
        </div>
      </div>
      <app-iframe-preview [iframeInfoModel]="selectedDocument"></app-iframe-preview>
    </ng-container>
  </form>
</div>
