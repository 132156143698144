import { Component, Input } from '@angular/core';
import { CreditInfoModel } from '../../models/credits/credit-info.model';

@Component ({
  selector: 'sh-credit-progress',
  templateUrl: './sh-credit-progress.component.html',
  styleUrls: ['./sh-credit-progress.component.scss']
})

export class ShCreditProgressComponent {
  @Input() credits: CreditInfoModel[] = [];

  getOverdue(credit: CreditInfoModel) {
    return parseFloat(credit.OVERDUE_AM) + parseFloat(credit.OVERDUE_PERC) + parseFloat(credit.PENALTY);
  }
}
