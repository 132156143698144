import { Injectable } from '@angular/core';
import { BehaviorSubject, from } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { PromoRules } from './payments/promo.service';
import { PromoModel } from '../models/payments/promo.model';
import { PromoEventModel } from '../models/payments/promo-event.model';

export interface ValidateRule {
  rule: string;
  callback: (...args: any) => {};
  arguments: any[];
  status?: boolean;
}

export interface EventsInterface {
  COMPLETED: string;
  FINISH: string;
  PUSH: string;
}

@Injectable()
export class CallbackValidator {

  private readonly rules: ValidateRule[] = [];
  private $isCompleted: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

  private _events: EventsInterface = {
    COMPLETED: 'completed',
    FINISH: 'finish',
    PUSH: 'push',
  };

  eventsSubject$ = new BehaviorSubject<PromoEventModel>(null);

  constructor() {
  }

  get events(): EventsInterface {
    return this._events;
  }

  set events(events: EventsInterface) {
    this._events = events;
  }

  set isCompleted(status: boolean) {
    this.$isCompleted.next(status);
  }

  get isCompleted(): boolean {
    return this.$isCompleted.value;
  }

  /**
   * Добавить правило к основному списку
   * @param rule - правило валидации
   * @return {this}
   */
  addRule(rule: any) {
    const find = this.rules.findIndex(r => r.rule === rule.rule);

    if (find > -1) {
      this.rules.splice(find, 1);
    }

    this.rules.push(rule);

    this.eventsSubject$.next(new PromoEventModel(this.events.PUSH, rule));
  }

  /**
   * Запуск callback ф-ции
   * @param rule правило, для которого необходимо запустить ф-цию
   */
  private dispatchRule(rule: ValidateRule) {
    return Object.assign({}, rule, { status: rule.callback.apply(this, rule.arguments) });
  }

  /**
   * Запуск валидации. Если не передать имя правила , запустятся все
   * @param ruleName название правила
   */
  validate(ruleName?: string): Promise<ValidateRule|boolean> {
    return new Promise(resolve => {
      from(this.rules)
        .pipe(
          filter(rule => rule.rule === ruleName || !ruleName),
          map(rule => this.dispatchRule(rule)),
          tap(rule => {
            if (this.isCompleted === null || this.isCompleted === true) {
              this.$isCompleted.next(
                rule.status && rule.arguments[0].is_card_insurance_enabled !== null
              );
            }
            return rule;
          })
        )
        .subscribe({
          next: rule => {
            resolve(rule);
            // return this.emit(this.events.FINISH, rule);
            this.eventsSubject$.next(new PromoEventModel(this.events.FINISH, rule));
          },
          complete: () => {
            resolve(this.isCompleted);
            // this.emit(this.events.COMPLETED, this.isCompleted);
            this.eventsSubject$.next(new PromoEventModel(this.events.COMPLETED, null, this.isCompleted));
          }
        });
    });
  }

  reset() {
    this.$isCompleted.next(true);
  }
}
