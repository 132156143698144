// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const apiUAT = 'autofinancebankuat.spbdev.net';
const apiTest = 'autofinancebanktest.spbdev.net';
const apiDemo = 'demo.autofinancebankuat.spbdev.net';

export const environment = {
  production: false,
  debug: true,
  socketHost: `wss://ws2.${apiTest}`,
  socketHostDemo: `wss://ws.${apiDemo}`,
  socketHostUat: `wss://ws2.${apiUAT}`,
  apiHost: `https://${apiTest}`,
  apiHostDemo: `https://${apiDemo}`,
  apiHostUat: `https://${apiUAT}`,
  best2payHost: 'https://test.best2pay.net/webapi',
  best2paySuccessUrl: 'https://autofinancebankwebtest.spbdev.net/payments/success',
  successUrl: 'https://autofinancebankwebtest.spbdev.net/payments/success',
  best2payFailUrl: 'https://autofinancebankwebtest.spbdev.net/payments/error',
  errorUrl: 'https://autofinancebankwebtest.spbdev.net/payments/error',
  sentryDsn: 'https://b2ebf383d95b4cab92993711498c8140@sentry.io/4902768',
  required_features: {
    t1926: true,
    t1332: true,
    t3031: true,
    t8554: true,
    t9145: true,
    t9346: true,
    t10376: true,
    t9427_9428: true,
    t9265: true,
    t10633: true
  },
  SENTRY_TURN: true,
  SITE_KEY_V2: '6LeA8LAcAAAAAFvfaF95r9SZFpFqpbFyBNjnk4Au',
  SITE_KEY_V3: '6LdPydcUAAAAAPUq0uCtIQyv1Aw0ItGqWgYKy5bU'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

