import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FileDescriptionModel } from '../../models/requests/file-description.model';
import { RequestsService } from '../../services/requests/requests.service';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-file-downloader',
  templateUrl: './file-downloader.component.html',
  styleUrls: ['./file-downloader.component.scss']
})
export class FileDownloaderComponent implements OnInit {

  @Input() file: FileDescriptionModel;
  @Input() isCanDelete: boolean;
  @Output() deleteEvent = new EventEmitter<string>();

  constructor(private requestsService: RequestsService) { }

  ngOnInit() {
  }

  downloadFile() {
    this.requestsService.downloadFile(this.file.id).subscribe((data: ArrayBuffer) => {
      const blob = new Blob([data]);
      FileSaver.saveAs(blob, `${this.file.filename}`);
    });
  }


  delete() {
    this.deleteEvent.emit(this.file.id);
  }
}
