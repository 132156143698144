import { Injectable } from '@angular/core';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { TranslationWidth } from '@angular/common';

const I18N_VALUES = {
  weekdays: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'],
  months: [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь'
  ]
};

@Injectable()
export class RusDatepickerI18n extends NgbDatepickerI18n {
  getMonthShortName(month: number): string {
    return I18N_VALUES.months[month - 1].substr(0, 3);
  }

  getMonthFullName(month: number): string {
    return I18N_VALUES.months[month - 1];
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return I18N_VALUES.months[date.month];
  }

  getWeekdayLabel(weekday: number, width?: TranslationWidth): string {
    return I18N_VALUES.weekdays[weekday - 1];
  }
}
