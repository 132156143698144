import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DatepickerMonthsComponent } from './datepicker/datepicker-months.component';
import { MoneySplitterComponent } from './money-splitter/money-splitter.component';
import { InformNotificationComponent } from './inform-notification/inform-notification.component';
import { ShCreditProgressComponent } from './sh-credit-progress/sh-credit-progress.component';
import { DatepickerComponent } from './datepicker/datepicker.component';
import { ProlongationApiService } from '../services/prolongation-api.service';
import { UploadImageComponent } from './upload-image/upload-image.component';
import { UploadImageService } from './upload-image/upload-image.service';
import { RouterModule } from '@angular/router';
import { SpinnerComponent } from './spinner/spinner.component';
import { WebsocketService } from '../services/websocket.service';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { ActiveApplicationComponent } from './active-application/active-application.component';
import { IframePreviewComponent } from './iframe-preview/iframe-preview.component';
import { OnetimePayComponent } from './onetime-pay/onetime-pay.component';
import { CardEditComponent } from './card-edit/card-edit.component';
import { CardInsuranceComponent } from './card-insurance/card-insurance.component';
import { InsurancePanelComponent } from './insurance-panel/insurance-panel.component';
import { CallbackValidator } from '../services/callback-validator.service';
import { PromoCardComponent } from './promo-card/promo-card.component';
import { SettingsService } from '../services/settings.service';
import { FileLoaderComponent } from './file-loader/file-loader.component';
import { ApiService } from '../services/api.service';
import { FileDownloaderComponent } from './file-downloader/file-downloader.component';
import { EsignConditionDocumentComponent } from './esign-condition-document/esign-condition-document.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { PaymentConfirmComponent } from './payment-confirm/payment-confirm.component';
import { AutocompleteComponent } from './autocomplete/autocomplete.component';
import { AutocompleteService } from '../services/autocomplete.service';
import { TimerService } from '../services/timer.service';
import { CustomSelectComponent } from './custom-select/custom-select.component';
import { ErrorComponent } from './error/error.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { AlertModal } from './alert-modal/alert-modal.component';
import { InsuranceSliderComponent } from './insurance-slider/insurance-slider.component';
import { CardListComponent } from './card-list/card-list.component';
import { MatComponentsModuleModule } from '../modules/mat-components-module/mat-components-module.module';
import { NgxMaskModule } from 'ngx-mask';
import { PipesModule } from '../pipes/pipes.module';
import { DirectivesModule } from '../directives/directives.module';
import { ValidatorService } from '../services/validator.service';
import { FileDownloaderService } from '../services/file-downloader.service';
import { NoticeTerminateComponent } from './notice-terminate/notice-terminate.component';
import { AdditionalAgreementComponent } from './additional-agreement/additional-agreement.component';
import { ShDepositProgressComponent } from './sh-deposit-progress/sh-deposit-progress.component';
import { WarningScreenComponent } from './warning-screen/warning-screen.component';
import { TokenLifecycleModalComponent } from './token-lifecycle-modal/token-lifecycle-modal.component';
import { ExpiresTokenTimerService } from '../services/expires-token-timer.service';


@NgModule({
  declarations: [
    DatepickerComponent,
    DatepickerMonthsComponent,
    MoneySplitterComponent,
    InformNotificationComponent,
    ShCreditProgressComponent,
    ShDepositProgressComponent,
    UploadImageComponent,
    SpinnerComponent,
    ConfirmationComponent,
    ActiveApplicationComponent,
    IframePreviewComponent,
    OnetimePayComponent,
    CardListComponent,
    CardEditComponent,
    CardInsuranceComponent,
    InsuranceSliderComponent,
    InsurancePanelComponent,
    PromoCardComponent,
    FileLoaderComponent,
    FileDownloaderComponent,
    EsignConditionDocumentComponent,
    PaymentConfirmComponent,
    AutocompleteComponent,
    AlertModal,
    CustomSelectComponent,
    ErrorComponent,
    NoticeTerminateComponent,
    AdditionalAgreementComponent,
    WarningScreenComponent,
    TokenLifecycleModalComponent,
  ],
  imports: [
    CommonModule,
    NgbModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    SlickCarouselModule,
    PdfViewerModule,
    MatComponentsModuleModule,
    NgxMaskModule.forRoot(),
    PipesModule,
    DirectivesModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DatepickerComponent,
    DatepickerMonthsComponent,
    NgbModule,
    MoneySplitterComponent,
    InformNotificationComponent,
    ShCreditProgressComponent,
    ShDepositProgressComponent,
    UploadImageComponent,
    RouterModule,
    SpinnerComponent,
    ActiveApplicationComponent,
    ConfirmationComponent,
    IframePreviewComponent,
    OnetimePayComponent,
    CardListComponent,
    CardEditComponent,
    CardInsuranceComponent,
    InsuranceSliderComponent,
    InsurancePanelComponent,
    PromoCardComponent,
    FileLoaderComponent,
    FileDownloaderComponent,
    EsignConditionDocumentComponent,
    PaymentConfirmComponent,
    AutocompleteComponent,
    CustomSelectComponent,
    ErrorComponent,
    NoticeTerminateComponent,
    AdditionalAgreementComponent,
    WarningScreenComponent,
    TokenLifecycleModalComponent,
  ],
  providers: [
    ValidatorService,
    FileDownloaderService,
    ProlongationApiService,
    UploadImageService,
    WebsocketService,
    CallbackValidator,
    SettingsService,
    ApiService,
    AutocompleteService,
    TimerService,
    ExpiresTokenTimerService,

  ]
})
export class SharedModule {
}
