export const errorCodesConstant = [
  [1001, 'Учётная запись заблокирована'],
  [1002, 'Мы не нашли такой логин. Попробуйте ввести номер телефона или свяжитесь со службой поддержки'],
  [1003, 'Время подтверждения смс истекло. Повторите попытку'],
  [1004, 'Неверный код из смс'],
  [1005, 'Unknown email'],
  [1006, 'PDP information is outdated, try again later'],
  [1007, 'Conditions with given credit id not found'],
  [1008, 'Credit not found'],
  [1009, 'Operation not found'],
  [1010, 'At the moment there is no mobile phone associated with the user'],
  [1011, 'Нам понадобится некоторое время, чтобы обработать информацию по Вашему кредиту. В ближайшие несколько часов приложение будет готово к работе.'],
  [1012, 'Schedule not found'],
  [1013, 'График платежей не действителен'],
  [1014, 'Too many points for given query'],
  [1015, 'Forbidden'],
  [1016, 'DRIVER_NOT_FOUND'],
  [1018, 'Неверный логин или пароль'],
  [1019, 'TOKEN_EXPIRED'],
  [1020, 'Превышено допустимое количество попыток. Попробуйте повторить запрос позднее'],
  [1021, 'Operation not permitted'],
  [1022, 'Insurance company not found'],
  [1023, 'Insurance not found'],
  [1045, 'Неверный код из смс'],
  [1048, 'Процесс верификации reCAPTCHA завершился с ошибкой. Попробуйте еще раз'],
  [1051, 'Текущий логин пользователя устарел. Активируйтесь под новым логином %newCftId%']
];
