<div *ngIf="isBackButton ?? errorModel.isBackButton" class="info">
  <div class="btn-back btn-back--arrow" (click)="back()">
    <div class="btn-back--icon">
      <mat-icon  style="width: 21px; height: 21px" svgIcon="back-arrow"></mat-icon>
    </div>
    <span class="info__descr">Назад</span>
  </div>
</div>

<div class="notify-error">
  <div class="notify-error__img">
    <mat-icon style="width: 190px; height: 190px" svgIcon="icon-reject"></mat-icon>
  </div>
  <div class="notify-error__info">
    {{ errorModel?.caption ?? caption }}
  </div>
  <div class="notify-error__note">
    {{ text ?? errorModel?.text }}
  </div>
  <div class="notify-error__button">
    <button *ngIf="isButton ?? errorModel.isButton"
            class="btn-secondary"
            (click)="buttonHandler()"
    >
      {{ buttonText ?? errorModel?.buttonText }}
    </button>
  </div>
</div>
