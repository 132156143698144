<ng-container *ngIf="screen === 'main'">
  <div class="wrap">
    <div class="info">
      <div class="info__container">
        <div>
<!--                  <div [svg]="'back-arrow'" class="btn-back&#45;&#45;icon"></div>-->
          <div class="info__title">
            <span>Дополнительное соглашение</span>
          </div>
        </div>
      </div>
    </div>
    <div  class="banners">
      <div class="banners__wrapper">
        <div class="banners__items">
          <div class="banners__item">
            <div class="banners__item-img">
              <img src="../../../../../assets/images/svg/shield-yellow.svg" alt="">
            </div>
          </div>
          <div class="banners__item">
            <div class="banners__item-title">Вам необходимо ознакомиться <br/>
              и подписать дополнительное соглашение</div>
          </div>
        </div>
      </div>
    </div>

    <div class="form-check">
      <input class="form-check__input"
             id="agreement"
             name="agreed"
             type="checkbox"
             [(ngModel)]="agreement"
             >
      <label class="form-check__label" [for]="'agreement'">
      </label>
        <p>
          Согласен с  <a class="text-primary cursor-pointer" (click)="goToAgreement()">дополнительным соглашением ></a>
        </p>
    </div>

    <div class="info__buttons">
      <button class="btn btn-main" [disabled]="!agreement" (click)="goToSmsVerification()">
        Подписать
      </button>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="screen === 'doc'">
  <div class="wrap wrap--wide">
    <div>
      <div class="btn-back btn-back--arrow" (click)="goMain()">
        <div class="btn-back btn-back--icon">
          <mat-icon  style="width: 21px; height: 21px" svgIcon="back-arrow"></mat-icon>
        </div>
        <span class="info__descr">
           Дополнительное соглашение
        </span>
      </div>
      <div class="info info--scroll">
        <div class="info__items">
          <pdf-viewer [src]="document?.file_link"
                      [render-text]="true"
                      style="display: block; height: 592px; overflow: auto;"
          ></pdf-viewer>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="screen !== 'doc'">
  <div class="wrap wrap--auto">
    <div>
      <ng-container *ngIf="screen === 'process'">
        <app-inform-notification  [successHeading]="'Подписание дополнительного соглашения'"
                                  type="waiting"
        ></app-inform-notification>
      </ng-container>

      <ng-container *ngIf="screen === 'loading'">
        <app-inform-notification  [successHeading]="'Данные загружаются'"
                                  type="waiting"
        ></app-inform-notification>
      </ng-container>

      <ng-container *ngIf="screen === 'signError'">
        <app-inform-notification  [successHeading]="'Не удалось подписать дополнительное соглашение'"
                                  [successText]="'Заявление отменено. Произошла ошибка сервера. Попробуйте повторить попытку через несколько минут.'"
                                  [secondButtonText]="'Продолжить работу'"
                                  (clickSecondEventEmitter)="goToMainPage('default')"
                                  type="error"
        ></app-inform-notification>
      </ng-container>

      <ng-container *ngIf="screen === 'conditionsError'">
        <app-inform-notification  [successHeading]="'Не удалось загрузить условия'"
                                  [successText]="'Заявление отменено. Произошла ошибка сервера. Попробуйте повторить попытку через несколько минут.'"
                                  [secondButtonText]="'Продолжить работу'"
                                  (clickSecondEventEmitter)="goToMainPage('extra_agree')"
                                  type="error"
        ></app-inform-notification>
      </ng-container>

      <ng-container *ngIf="screen === 'final'">
        <!--<ng-container>-->
        <app-inform-notification  [successHeading]="'Дополнительное соглашение подписано'"
                                  [successText]="'Дополнительное соглашение можно посмотреть в разделе '"
                                  [link]="'/documents/agreement/'"
                                  [textForLink]="'Документы'"
                                  [secondButtonText]="'Продолжить работу'"
                                  (clickSecondEventEmitter)="goToMainPage('default')"
                                  type="success"
        ></app-inform-notification>
      </ng-container>

      <ng-container *ngIf="screen === 'sms'">
        <div class="btn-back btn-back--arrow" (click)="goMain()">
          <div class="btn-back btn-back--icon">
            <mat-icon  style="width: 21px; height: 21px" svgIcon="back-arrow"></mat-icon>
          </div>
          <span class="info__descr">
           Дополнительное соглашение
        </span>
        </div>
      </ng-container>
      <app-confirmation [buttonTitle]="'Подписать соглашение'"
                        [hidden]="screen !== 'sms'"
                        [confirmationModel]="confirmationModel"
                        (confirmed)="prepareConfirmedResponse($event)"
                        (waiter)="showWaiter($event)"
                        (errorEvent)="logError($event)"
      ></app-confirmation>

  </div>
  </div>
</ng-container>
