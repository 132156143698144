import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CardEditFormService} from './card-edit-form.service';
import { CardModel } from '../../models/payments/card.model';
import { ChangeCardNameModel } from '../../models/payments/change-card-name.model';
import { PaymentsService } from '../../services/payments/payments.service';
import { ValidatorService } from '../../services/validator.service';
import { HelperService } from '../../services/helper.service';

@Component({
  selector: 'card-edit',
  templateUrl: './card-edit.component.html',
  styleUrls: ['./card-edit.component.scss'],
  providers: [CardEditFormService]
})
export class CardEditComponent implements OnInit {

  @Input() currentCard: CardModel;

  @Output() returnToPaymentsEvent = new EventEmitter();
  @Output() changeNameEvent = new EventEmitter<ChangeCardNameModel>();
  @Output() cardDeleteEvent = new EventEmitter<number>();

  cardEditMode = 'editing';
  cardEditGroup: FormGroup;

  constructor(
    private formService: CardEditFormService,
    private paymentsService: PaymentsService,
    private validatorService: ValidatorService,
    private helperService: HelperService
  ) { }

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.cardEditGroup = this.formService.buildForm(this.currentCard.name);
  }

  changeMode(mode: 'deletion' | 'editing') {
    this.cardEditMode = mode;
  }

  returnToPayments() {
    this.returnToPaymentsEvent.emit();
  }

  deleteCard() {
     this.paymentsService.deleteCard(this.currentCard.id)
    .then(() => {
      this.cardDeleteEvent.emit(this.currentCard.id);
      this.returnToPayments();
    });
  }

  formIsValid(): boolean {
    if (this.cardEditGroup.get('card_name').value === this.currentCard.name) {
      return false;
    }
    return this.validatorService.validate(this.cardEditGroup);
  }

  changeCardName() {
    this.paymentsService.changeCardName(this.currentCard.id, this.cardEditGroup.get('card_name').value ? this.cardEditGroup.get('card_name').value : '')
      .then((cardName: ChangeCardNameModel) => {
        this.changeNameEvent.emit(cardName);
        this.returnToPayments();
      });
  }

  toggleDeleteCard() {
    if (this.currentCard.delete_information.is_possible) {
      this.changeMode('deletion');
    } else {
      this.helperService.alert(this.currentCard.delete_information.reason, 'Отключите автоплатеж');
    }
  }
}
