const svgIcons = [
  { name: 'logo-rn-big', url: 'assets/images/svg/logo-rn-big.svg' },
  { name: 'underline-dashed', url: 'assets/images/svg/underline-dashed.svg'},
  { name: 'input-dashed-short', url: 'assets/images/svg/input-dashed-short.svg'},
  { name: 'input-dashed-long', url: 'assets/images/svg/input-dashed-long.svg'},
  { name: 'input-dashed-3', url: 'assets/images/svg/input-dashed-3.svg'},
  { name: 'input-numbers-9', url: 'assets/images/svg/input-numbers-9.svg'},
  { name: 'input-numbers-5', url: 'assets/images/svg/input-numbers-5.svg'},
  { name: 'input-numbers-6', url: 'assets/images/svg/input-numbers-6.svg'},
  { name: 'input-numbers-15', url: 'assets/images/svg/input-numbers-15.svg'},
  { name: 'input-numbers-20', url: 'assets/images/svg/input-numbers-20.svg'},
  { name: 'input-numbers-21', url: 'assets/images/svg/input-numbers-21.svg'},
  { name: 'input-numbers', url: 'assets/images/svg/input-numbers.svg'},
  { name: 'phone', url: 'assets/images/svg/phone.svg'},
  { name: 'email-verification', url: 'assets/images/svg/email-verification.svg'},
  { name: 'see-doc', url: 'assets/images/svg/see-doc.svg'},
  { name: 'done', url: 'assets/images/svg/done.svg'},
  { name: 'undone', url: 'assets/images/svg/undone.svg'},
  { name: 'cross', url: 'assets/images/svg/cross.svg'},
  { name: 'icon-warning', url: 'assets/images/svg/icon-warning.svg'},
  { name: 'icon-envelope', url: 'assets/images/svg/icon-envelope.svg'},
  { name: 'stop', url: 'assets/images/svg/stop.svg'},
  { name: 'stop', url: 'assets/images/svg/stop.svg'},
  { name: 'logo-auto-big', url: 'assets/images/svg/logo-auto-big.svg'},
  { name: 'logo-auto', url: 'assets/images/svg/logo-auto.svg'},
  { name: 'eye', url: 'assets/images/svg/eye.svg'},
  { name: 'eye-hide', url: 'assets/images/svg/eye-hide.svg'},
  { name: 'icon-collapse', url: 'assets/images/svg/icon-collapse.svg'},
  { name: 'icon-master-lock', url: 'assets/images/svg/icon-master-lock.svg'},

  //Table
  { name: 'check', url: 'assets/images/svg/check.svg'},
  //Cards
  { name: 'card-edit', url: 'assets/images/svg/card-edit.svg'},
  //Arrows
  { name: 'back-arrow', url: 'assets/images/svg/back-arrow.svg'},
  { name: 'arrow', url: 'assets/images/svg/arrow.svg'},
  { name: 'done-arrow', url: 'assets/images/svg/done-arrow.svg'},
  { name: 'double-arrow-selector', url: 'assets/images/svg/double-arrow-selector.svg'},
  { name: 'arrow-line', url: 'assets/images/svg/arrow-line.svg'},
  { name: 'search-close', url: 'assets/images/svg/search-close.svg'},
  { name: 'search-loupe', url: 'assets/images/svg/search-loupe.svg'},
  { name: 'search-loupe-big', url: 'assets/images/svg/search-loupe-big.svg'},

  //notification
  { name: 'icon-complete', url: 'assets/images/svg/icon-complete.svg'},
  { name: 'icon-reject', url: 'assets/images/svg/icon-reject.svg'},
  { name: 'smscode', url: 'assets/images/svg/smscode.svg'},
  { name: 'clock', url: 'assets/images/svg/clock.svg'},
  { name: 'call', url: 'assets/images/svg/call.svg'},
  { name: 'closeBig', url: 'assets/images/svg/closeBig.svg'},

  //banner
  { name: 'flower', url: 'assets/images/svg/flower-banner.svg'},

  //Session
  { name: 'WINDOWS', url: 'assets/images/svg/windows.svg'},
  { name: 'ANDROID', url: 'assets/images/svg/android.svg'},
  { name: 'APPLE', url: 'assets/images/svg/apple.svg'},
  { name: 'MACOS', url: 'assets/images/svg/macOs.svg'},
  { name: 'LINUX', url: 'assets/images/svg/linux.svg'},
  { name: 'UNKNOWN', url: 'assets/images/svg/unknown-system.svg'},
  { name: 'help-icon', url: 'assets/images/svg/help-icon.svg'},

];

/**
 * return array for initialize application icons
 */
export function getSvgIcons(): Array<{ name: string, url: string }> {
  return [...svgIcons];
}
