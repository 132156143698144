import { Injectable } from '@angular/core';
import { CanLoad, Router } from '@angular/router';
import { AuthService } from '../services/auth/auth.service';
import { UserService } from '../services/auth/user.service';

@Injectable()
export class EsignRequiredGuard implements CanLoad {

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private router: Router
  ) {}

  canLoad(): Promise<boolean> {
    return this.authService.check().then((isLoggedIn) => {
      if (isLoggedIn) {
        if (this.userService.userInfo.is_esign_required) {
          this.router.navigate(['/credits']);

          return false;
        }

        return true;
      }

      return false;
    });
  }
}
