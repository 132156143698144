import {Component, Input, OnInit} from '@angular/core';
import {PromoModel} from '../../models/payments/promo.model';

@Component({
  selector: 'app-promo-card',
  templateUrl: './promo-card.component.html',
  styleUrls: ['./promo-card.component.scss']
})
export class PromoCardComponent implements OnInit {

  @Input() promo: PromoModel;

  constructor() { }

  ngOnInit() {
  }
}
