import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { cloneDeep } from 'lodash';

export const formErrors = {
  cards: {
    pan: '',
    validity: '',
    cvc: '',
    amount: ''
  }
};

export const validationMessages = {
  cards: {
    pan: {
      required: 'Номер карты не заполнен',
      pattern: 'Неверный номер карты'
    },
    cvc: {
      required: 'CVC не заполнен',
      pattern: 'CVC не заполнен'
    },
    validity: {
      required: 'Срок действия карты не заполнен',
      pattern: 'Неверный срок действия карты'
    },
    amount: {
      required: 'Сумма не заполнена',
      pattern: 'Неверный размер суммы'
    },
    confirm: {
      required: '',
      pattern: ''
    }
  }
};

export const validationMessagesNotLast = {
  cards: {
    pan: {
      required: '',
      pattern: ''
    },
    cvc: {
      required: '',
      pattern: ''
    },
    validity: {
      required: '',
      pattern: ''
    },
    amount: {
      required: 'Сумма не заполнена',
      pattern: 'Неверный размер суммы'
    },
    confirm: {
      required: '',
      pattern: ''
    }
  }
};

@Injectable()
export class OnetimePayFormService {
  constructor(private formBuilder: FormBuilder) {}

  buildForm(amount: number) {
    return this.formBuilder.group({
      amount: [amount, [Validators.pattern(/^([0-9.\s]{0,11})$/)]],
      email: ['', [Validators.pattern(/^([a-zA-Z0-9_\-.+]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]+)$/)]],
      token: '',
      // acceptLicense: '',
      pan: '',
      validity: '',
      cvc: '',
      confirm: false
    });
  }

  freshFormErrors() {
    return cloneDeep(formErrors);
  }
}
