import { Injectable } from '@angular/core';
import { LoginReasonEnum } from '../enums/login-reason.enum';
import { BehaviorSubject, firstValueFrom, lastValueFrom, Observable } from 'rxjs';

@Injectable()
export class ScreenServiceService {

  private statusSubject = new BehaviorSubject<LoginReasonEnum>(LoginReasonEnum.NONE);
  public status$ = this.statusSubject.asObservable();
  public status = LoginReasonEnum.NONE;

  setStatus(status: LoginReasonEnum): void {
    this.statusSubject.next(status);
  }

  getStatus(): Promise<LoginReasonEnum> {
    return firstValueFrom(this.status$);
  }

  constructor() {
  }
}
