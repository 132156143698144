export class IframeInfoModel {
  constructor(
    public height: string,
    public link: any,
    public title: string,
    public subtitle: string,
  ) {
    this.height = height;
    this.link = link;
    this.title = title;
    this.subtitle = subtitle;
  }
}
