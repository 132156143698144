import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from '../services/auth/auth.service';

@Injectable()
export class SetTokenInterceptor implements HttpInterceptor {

  private auth: AuthService;

  constructor(private injector: Injector) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Workaround for cyclic dependency error with HttpInterceptor, see https://github.com/angular/angular/issues/18224
    this.auth = this.injector.get(AuthService);

    if (req.method !== 'POST' || req.url.indexOf('/api/web/auth/confirm') === -1) {
      return next.handle(req);
    }

    return next
      .handle(req)
      .pipe(
        tap((event) => {
          if (event instanceof HttpResponse) {
            const token = event.headers.get('X-Client-Token');
            this.setAuthToken(token);
          }
        }));
  }

  setAuthToken(token: string) {
    this.auth.setToken(token);
  }
}
