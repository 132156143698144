import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription, timer } from 'rxjs';

@Injectable()
export class SmsService {
  /**
   * Время ожидания перед новым запросом
   */
  DEFAULT_TIMEOUT = 60;

  private readonly $resendTimeoutSubject = new BehaviorSubject<number>(this.DEFAULT_TIMEOUT);
  private $resendTimeoutTimerSubscriber: Subscription;

  /**
   * Декремент таймаута в единицу времени
   */
  resendTimeoutTick() {
    if (this.resendTimeout === 0) {
      return;
    }

    this.resendTimeout -= 1;
  }

  /**
   * Задаёт новое значение для таймаута
   * @param value - значение для таймаута
   */
  set resendTimeout(value: number) {
    this.$resendTimeoutSubject.next(value);
  }

  /**
   * Возвращает текущее значение таймаута
   * @return {Number}
   */
  get resendTimeout(): number {
    return this.$resendTimeoutSubject.value;
  }

  /**
   * Возвращает текст для кпоки "Прислать код ещё раз"
   * @return {string}
   */
  get resendBtnText(): string {
    if (this.resendTimeout > 0) {
      const timeout = this.resendTimeout < 10 ? `0${this.resendTimeout}` : this.resendTimeout;

      return `Прислать через 00:${timeout}`;
    }

    return 'Прислать код ещё раз';
  }

  /**
   * Сбрасывает значение таймаута на значение по-умолчанию
   */
  resendTimeoutReset() {
    this.resendTimeout = this.DEFAULT_TIMEOUT;
  }

  /**
   * Инициализация таймера на основе значения таймаута
   */
  initTimer() {
    if (this.$resendTimeoutTimerSubscriber) {
      this.$resendTimeoutTimerSubscriber.unsubscribe();
      this.$resendTimeoutTimerSubscriber = null;
    }

    this.$resendTimeoutTimerSubscriber = timer(
      this.resendTimeout, 1000
    ).subscribe(() => this.resendTimeoutTick());
  }
}
