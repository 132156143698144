import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OnlyNumbersDirective } from './onlyNumbers.directive';
import { CurrencyInputDirective } from './currency-input.directive';
import { AutocompleteOffDirective } from './autocomplete-off.directive';
import { UnderlineFocusDirective } from './underline-focus.directive';

@NgModule({
  declarations: [
    OnlyNumbersDirective,
    CurrencyInputDirective,
    AutocompleteOffDirective,
    UnderlineFocusDirective,
  ],
  exports: [
    OnlyNumbersDirective,
    CurrencyInputDirective,
    AutocompleteOffDirective,
    UnderlineFocusDirective,
  ],
  imports: [
    CommonModule
  ]
})
export class DirectivesModule { }
