<div class="head-wrapper">
  <header class="head-nav">
    <div class="logos">
      <div class="logos__logo">
        <img src="../../../assets/images/svg/logo-auto.svg" alt="Авто Финанс Банк" class="logo-rn"
             [ngClass]="!isInactive('/credits', true) ? 'no-cursor': ''" (click)="goToCredits()">
      </div>
      <div class="logos__logo" *ngIf="userBrands.indexOf('Nissan') != -1">
        <img src="../../../assets/images/svg/nissan_black.svg"
             alt="Nissan Finance" class="logo-nissan">
      </div>
      <div class="logos__logo" *ngIf="userBrands.indexOf('Infiniti') != -1">
        <img src="../../../assets/images/svg/logo-infiniti.svg"
             alt="Finance Infiniti" class="logo-infiniti">
      </div>
      <div class="logos__logo" *ngIf="userBrands.indexOf('Datsun') != -1">
        <img src="../../../assets/images/svg/logo-datsun.svg"
           alt="Datsun Finance" class="logo-datsun">
      </div>
<!--      <div class="logos__logo" >-->
<!--        <img src="../../../assets/images/svg/logo-renault.svg"-->
<!--             alt="Renault Finance" class="logo-renault">-->
<!--      </div>-->
      <div class="logos__logo" *ngIf="userBrands.indexOf('Lada') != -1">
        <img src="../../../assets/images/svg/logo-lada.svg"
             alt="LADA" class="logo-lada">
      </div>
    </div>
    <ul class="nav">
      <li>
        <a routerLink="/credits"
           routerLinkActive="active"
        >
          Кредит
        </a>
      </li>
<!--      <li>-->
<!--        <a routerLink="/deposits"-->
<!--           routerLinkActive="active"-->
<!--        >-->
<!--          Депозиты-->
<!--        </a>-->
<!--      </li>-->
      <li *ngIf="!isEsingRequired">
        <a routerLink="/accounts"
           routerLinkActive="active"
        >
          Счет
        </a>
      </li>
      <li *ngIf="!isEsingRequired">
        <a routerLink="/payments"
           routerLinkActive="active"
        >
          Пополнение и автоплатежи
        </a>
      </li>
      <li *ngIf="!isEsingRequired">
        <a [routerLink]="['/repayments']"
           [queryParams]="{ activeRequest: isActiveRequest }"
           routerLinkActive="active"
           [ngClass]="{remind: isActiveRequest}"
        >
          Погашение
        </a>
      </li>
      <li *ngIf="!isEsingRequired">
        <a [routerLink]="['/offers']"
           [routerLinkActive]="'active'"
        >
          Предложения
        </a>
      </li>
      <li *ngIf="!isEsingRequired">
        <a routerLink="/certificates"
           routerLinkActive="active"
        >
          Справки
        </a>
      </li>
      <li *ngIf="!isEsingRequired">
        <a routerLink="/documents"
           routerLinkActive="active"
        >
          Документы
        </a>
      </li>
      <li *ngIf="!isEsingRequired">
        <a routerLink="/request"
           routerLinkActive="active"
        >
          Запрос в банк
        </a>
      </li>
      <li #dropMenuElem class="nav-more" (click)="dropMenu()">
        <a>Еще</a>
        <div *ngIf="isOpenDropMenu" class="nav-more__drop">
          <div class="nav-more__drop-items">
            <div *ngIf="!isEsingRequired">
              <a routerLink="/notifications"
                 routerLinkActive="active"
                 class="nav-more__drop-item"
              >
                Уведомления
              </a>
            </div>
<!--            <div *ngIf="!isEsingRequired && showAccountClosure">-->
<!--              <a routerLink="/account-closure"-->
<!--                 routerLinkActive="active"-->
<!--                 class="nav-more__drop-item"-->
<!--              >-->
<!--                Перевод и закрытие счета-->
<!--              </a>-->
<!--            </div>-->
            <div >
              <a routerLink="/settings"
                 routerLinkActive="active"
                 class="nav-more__drop-item"
              >
                Настройка
              </a>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <div class="btn-exit">
      <a (click)="logout(); $event.preventDefault()">
        <img src="../../../assets/images/svg/i-exit.svg">
      </a>
    </div>
  </header>
</div>
