<div *ngFor="let request of requests" class="form">
  <div *ngIf="request.request.TYPE_OPER === operationTypes.PARTIAL_REPAYMENT">
    <div class="info__items">
      <div class="info__item">
        <span class="info__title">Оформлена заявка на частичное <br/> погашение кредита {{ getBeatifyDate(request.request.DATE_OP).slice(0, -5) }}</span>
        <div class="form__row text-aline-center"
             *ngIf="!(+request?.credit?.OVERDUE_AM > 0) && !(+request?.credit?.OVERDUE_AM > 0)"
        >
          <span class="form__title margin-right">
              Сумма к оплате
          </span>
          <span class="form__text font-size-big">{{ +request.request.SUM_OP | moneySpaces }} ₽</span>
        </div>
      </div>
    </div>
    <div class="info__buttons">
      <button class="btn btn-secondary"
              (click)="showCallInformation()"
              type="button"
      >
        Удалить заявку
      </button>
      <button class="btn btn-main"
              [routerLink]="['/','repayments']"
              [queryParams]="{ activeRequest: false }"
              (click)="newRequest()"
              type="button"
      >
        Новая заявка
      </button>
    </div>
  </div>
  <div *ngIf="request.request.TYPE_OPER === operationTypes.FULL_REPAYMENT && !!request.request.pdp_details">
    <div class="info__items">
      <div class="info__item">
        <span class="info__title">Заявка на досрочное погашение</span>
        <div class="form">
          <div class="form__row">
            <span class="form__title">Договор</span>
            <div class="form__elem">
              {{ request.credit.PRODUCT_NAME }} · {{ request.credit.CONTRACT_NUMBER }}
            </div>
          </div>
          <ng-container>
            <ng-container>
              <div class="form__row">
                <span class="form__title">Цель</span>
                <div class="form__elem">Полное погашение кредита</div>
              </div>
            </ng-container>
            <ng-container>
              <div class="form__row">
                <span class="form__title">Дата платежа</span>
                <div class="form__elem">
                  {{ getBeatifyDate(request.request.DATE_OP) }}
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="request.request.pdp_details.overdue &&
                                 (request.request.pdp_details.overdue.body === 0 ||
                                  request.request.pdp_details.overdue.percents === 0)">
              <div class="form__row text-aline-center">
                <span class="form__title">Сумма</span>
                <div class="form__elem">
                  <span class="form__text font-size-big">{{ request.request.pdp_details.total_amount / 100 | moneySpaces }} ₽</span>
                </div>
              </div>
            </ng-container>

            <ng-container>
              <div class="info__items">
                <ng-container *ngIf="request.request.pdp_details.overdue &&
                                    (request.request.pdp_details.overdue.body === 0 ||
                                     request.request.pdp_details.overdue.percents === 0)">
                  <span class="info__title">Из чего состоит сумма</span>
                  <ng-container *ngIf="request.request?.pdp_details?.schedules?.length > 0">
                    <div class="form__row">
                      <span class="form__title ">{{ getStringOfMonth(request.request.pdp_details.schedules) }} <br/> <span>({{ createListOfMonth(request.request.pdp_details.schedules) }})</span></span>
                      <!--                    <div class="form__elem">-->
                      <span class="form__text">{{ +calculateSumForMonths(request.request.pdp_details.schedules) | moneySpaces }} ₽</span>
                      <!--                    </div>-->
                    </div>
                  </ng-container>
                  <div class="form__row">
                      <span class="form__title">
                        Оставшаяся сумма кредита
                      </span>
                  </div>
                  <div class="form__row">
                        <span class="form__title pd-left">
                          Основной долг
                        </span>
                    <span class="form__text">{{ request.request.pdp_details.pdp.body / 100 | moneySpaces }} ₽</span>
                  </div>
                  <div class="form__row">
                        <span class="form__title pd-left">
                          Проценты
                        </span>
                    <span class="form__text">{{ request.request.pdp_details.pdp.percents / 100 | moneySpaces }} ₽</span>
                  </div>
                </ng-container>

                <ng-container *ngIf="request.request.pdp_details.overdue &&
                                    (request.request.pdp_details.overdue.body > 0 ||
                                     request.request.pdp_details.overdue.percents > 0)">
                  <div class="form__row form__row--column">
                      <span class="form__title">
                        Просроченная задолженность
                      </span>
                  </div>
                  <div class="form__row" *ngIf="request.request.pdp_details.overdue.body > 0">
                        <span class="form__title pd-left">
                          Основной долг
                        </span>
                    <span class="form__text">{{ request.request.pdp_details.overdue.body / 100 | moneySpaces }} ₽</span>
                  </div>
                  <div class="form__row" *ngIf="request.request.pdp_details.overdue.percents > 0">
                        <span class="form__title pd-left">
                          Проценты
                        </span>
                    <span class="form__text">{{ request.request.pdp_details.overdue.percents / 100 | moneySpaces }} ₽</span>
                  </div>
                  <div class="form__row" *ngIf="request.request.pdp_details.overdue.percents > 0">
                        <span class="form__title pd-left">
                          Пени
                        </span>
                    <span
                      class="form__text"
                      *ngIf="request.request.pdp_details.overdue.penalty"
                    >{{ request.request.pdp_details.overdue.penalty / 100 | moneySpaces }} ₽</span>
                  </div>
                </ng-container>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="info__buttons">
      <button class="btn btn-secondary"
              (click)="showCallInformation()"
              type="button"
      >
        Удалить заявку
      </button>
      <button class="btn btn-main"
              [routerLink]="['/','repayments']"
              [queryParams]="{ activeRequest: false }"
              (click)="newRequest()"
              type="button"
      >
        Новая заявка
      </button>
    </div>
  </div>
</div>
