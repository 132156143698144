<ng-container *ngIf="screen === 'code'">
  <div class="wrap">
    <div class="btn-back btn-back--arrow" (click)="returnToSend()">
      <div class="btn-back--icon">
        <mat-icon style="width: 21px; height: 21px" svgIcon="back-arrow"></mat-icon>
      </div>
      <span class="info__descr">Пополнение и автоплатежи</span>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="confirmationModel">
  <app-confirmation [buttonTitle]="'Подтвердить'"
                    [hidden]="screen !== 'code'"
                    [confirmationModel]="confirmationModel"
                    (waiter)="showWaiter($event)"
                    (errorEvent)="errorProcessing($event)"
  ></app-confirmation>
</ng-container>
