<div class="spinner">
  <!-- for others -->
  <div class="spinner-big"
       *ngIf="type === 'big'"
  ></div>
  <!-- for request -->
  <div class="spinner-small"
       *ngIf="type === 'small'"
  ></div>
</div>
