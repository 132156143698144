<div class="custom-selector"
     [ngClass]="{'active-drop': isShowList, 'disabled': isDisabledSelect, 'error': false}"
>
    <div class="underline">
        <div class="custom-selector__val"
             (click)="toggleSelectList()"
        >
          <span class="custom-selector__title">
            {{ selectedOption?.text }}
          </span>
          <span *ngIf="!isDisabledSelect"
                class="custom-selector__icon"
                [ngClass]="{'rotate' : false}"
          >
             <mat-icon svgIcon="double-arrow-selector"></mat-icon>
          </span>
        </div>
        <div class="custom-selector__drop">
            <ul class="custom-selector__items noscroll">
                <li *ngFor="let option of options"
                    class="custom-selector__item"
                    [ngClass]="{'active': option.isSelected}"
                    (click)="selectOption(option)"
                >
                  <div class="custom-selector__item-info">
                    {{ option.text }}
                  </div>
                  <div class="custom-selector__item-info">
                    {{ option.amount }} ₽
                  </div>
                </li>
            </ul>
        </div>
    </div>
</div>
