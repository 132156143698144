import { CardConditionsModel } from './card-conditions.model';

export class CardInsuranceModel {
  constructor(
    public status?: 'APPROVED' | 'NOT_APPROVED' | 'APPROVED_CONDITIONALLY',
    public transaction_upper_bound?: number,
    public conditions: CardConditionsModel = new CardConditionsModel(),
    public limit_upper_bound?: number,
  ) {}
}
