import { Directive, ElementRef, HostListener, OnInit, Renderer2 } from '@angular/core';

@Directive({ selector: 'input[underline-focus]' })
export class UnderlineFocusDirective implements OnInit {
  constructor(private _elRef: ElementRef, private _renderer: Renderer2) { }

  ngOnInit() {
  }

  @HostListener('focus')
  onFocus() {
    this._renderer.addClass(this._elRef.nativeElement.parentNode, 'underline-focus');
  }

  @HostListener('focusout')
  onFocusOut() {
    this._renderer.removeClass(this._elRef.nativeElement.parentNode, 'underline-focus');
  }
}

