import { Compiler, Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { get } from 'lodash';
import * as sha256 from 'sha256';

import { UserService } from './user.service';
import { environment } from '../../../environments/environment';
import { ErrorService } from '../error.service';
import { BehaviorSubject } from 'rxjs';
import { ServerConstantsValuesModel } from '../../models/server-constants.model';
import { TokenModel } from '../../models';
import { AuthModel } from '../../models';
import { ConfirmModel } from '../../models';
import { LocalStorageConstant } from '../../constants/local-storage.constant';
import { ServerTypeEnum } from '../../models/storage';
import { AuthApiService } from './auth-api.service';

@Injectable()
export class AuthService {

  /**
   * Переменная для хранения адреса api-host.
   */
  private _apiHost = '';

  /**
   * Флаг залогинен пользователь или нет.
   */
  isLoggedIn = false;

  /**
   * Поле для списка брендов пользователя.
   */
  userBrands: string[] = [];

  /**
   * Результат проверки токена.
   */
  checkPromise: Promise<boolean>;

  /**
   * Поле для хранения url при перебросе на окно авторизации.
   */
  redirectUrl = '/credits';

  /**
   * Ключи в локалсторейдж не удаляемые при выходе из ИБ.
   */
  private _preventRemovedKeys = [
    'rn_device_cookie',
    'sl_hashes', // sentry errors
  ];

  /**
   * Имеет ли пользователь открытые заявки на досрочное погашение
   */
  $hasPendingPdp: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  _serverConstants: ServerConstantsValuesModel = new ServerConstantsValuesModel();
  private client: string;

  constructor(
    private router: Router,
    private userService: UserService,
    private compiler: Compiler,
    private errorService: ErrorService,
    private apiService: AuthApiService,
  ) {
    this.initApiHost();
  }

  set tempToken(token: string) {
    localStorage.setItem('tmp_token', token);
  }

  get tempToken(): string {
    return localStorage.getItem('tmp_token') || '';
  }

  /**
   * Сеттер для api-host.
   * @param {string} value
   */
  set apiHost(value: string) {
    localStorage.setItem('rn_api_host', value || '');
    this._apiHost = value;
  }

  /**
   * Геттер для получения api-host.
   * @returns {string}
   */
  get apiHost() {
    return this._apiHost;
  }

  /**
   * Инициализация апи хоста из конфигурации (.env файл).
   */
  initApiHost() {
    if (environment.production) {
      this.initProductionServer();
      return;
    }
    const server = localStorage.getItem(LocalStorageConstant.SERVER_TYPE) ?? 'demo';

    switch (server) {
      case ServerTypeEnum.DEMO:
        this.apiHost = environment.apiHostDemo;
        break;
      case ServerTypeEnum.UAT:
        this.apiHost = environment.apiHostUat;
        break;
      case ServerTypeEnum.PROD:
      case ServerTypeEnum.TEST:
        this.apiHost = environment.apiHost;
        break;
    }
  }

  initProductionServer() {
    if (!this.client?.length) {
      this.apiHost = environment.apiHost;
      return;
    }
    this.apiHost = this.client.length === 6 ? environment.apiHostDemo : environment.apiHost;
    localStorage.setItem(
      LocalStorageConstant.SERVER_TYPE, this.client.length === 6
        ? ServerTypeEnum.DEMO
        : ServerTypeEnum.PROD
    );
  }

  /**
   * Логин/разлогин пользователя. Установка флага usLoggedIn и сброс токена.
   * @param {boolean} isLoggedIn
   */
  toggleLogin(isLoggedIn: boolean) {
    this.isLoggedIn = isLoggedIn;
    this.checkPromise = Promise.resolve(this.isLoggedIn);

    if (!isLoggedIn) {
      localStorage.removeItem('rn_token');
      localStorage.removeItem('rn_auth_step');
    }
  }

  private isDemo(length = 0): boolean {
    return length === 6 || (this.isLoggedIn && this.userService.userInfo.CLIENT.length === 6);
  }

  /**
   * При успешной проверке токена логиним.
   * @returns {Promise<boolean>}
   */
  private onAuthSuccess(auth: TokenModel): Promise<boolean> {
    localStorage.setItem('rn_demo', String(this.isDemo()));

    this.initUser(auth);

    this.toggleLogin(true);

    if (!this.userService.isEmailFill && this.lastStep === 'activation') {
      this.isLoggedIn = false;
      this.router.navigate(['/', 'auth']);

      return Promise.resolve(true);
    }

    return Promise.resolve(this.isLoggedIn);
  }

  /**
   * При неудачной проверке токена сбрасываем логин.
   * @returns {Promise<boolean>}
   */
  private onAuthCheckError(): Promise<boolean> {
    this.toggleLogin(false);

    return Promise.resolve(this.isLoggedIn);
  }

  /**
   * Обработка ошибок при авторизации/активации.
   * @param {HttpErrorResponse} error если это ошибка, у которой существует код(error_code), то ищем сообщение по коду,
   * в противоположном случае выбрасываем "Ошибка выполнения запроса".
   * @returns {Promise<string>}
   */
  private onLoginError(error: HttpErrorResponse): Promise<string> {
    this.toggleLogin(false);

    const errorMutate = this.getErrorMsgFromResponse(error);

    if ('retryTime' in errorMutate) {
      return Promise.reject(+errorMutate.retryTime);
    }

    return Promise.reject(errorMutate.msg);
  }

  getErrorMsgFromResponse(error: HttpErrorResponse): { errorCode: string | number, msg: string | number } | {
    retryTime: number
  } {
    const errorCode = get(error, 'error.error_code', '');
    const retryTime = +get(error, 'error.retry_after_minutes', 40);
    const msg = this.errorService.getHttpErrorMsg(error);

    if (errorCode && +errorCode === 1020) {
      return {
        retryTime,
      };
    }

    return { errorCode, msg };
  }

  calculateHash(userLogin: string, smsCode: string) {
    return sha256(userLogin.trim() + 'salt' + smsCode);
  }

  /**
   * Проверка токена.
   * @param {boolean} isInitial - флаг true если это вызов начальный вызов.
   * @returns {Promise<boolean>}
   */
  check(isInitial = false): Promise<boolean> {
    if (isInitial) {
      this.checkPromise = this.makeCheckPromise();
    }

    return this.checkPromise;
  }

  /**
   * Обращение к Апи для проверки актуальности/наличия токена в local storage..
   * @returns {Promise<boolean>}
   */
  makeCheckPromise(): Promise<boolean> {
    return this.apiService.authCheck()
      .then((auth: TokenModel) => this.onAuthSuccess(auth))
      .catch(this.onAuthCheckError.bind(this));
  }

  /**
   * Обращение к Апи авторизации пользователя.
   * @param {AuthModel} auth данные с формы авторизации (логин и пароль).
   * @returns {Promise<any>} Promise с данными телефона, соотвествующему этому логину.
   */
  login(auth: AuthModel): Promise<any> {
    return this.apiService.login(auth)
      .catch(this.onLoginError.bind(this));
  }

  /**
   * Обращение к Апи подтверждения по смс.
   * @param {ConfirmModel} confirm - данные с формы подтверждения.
   * (при активации и авторизации используются разные url для подтверждения).
   * @returns {Promise<any>} Promise с токеном
   */
  confirm(confirm: ConfirmModel): Promise<any> {
    return this.apiService.confirm(confirm)
      .then((res: TokenModel) => {
        if (res.account === null || !res.account) {
          return res;
        }

        this.initUser(res);
        this.toggleLogin(true);
        return res;
      })
      .catch(this.onLoginError.bind(this));
  }

  activate(confirm: ConfirmModel): Promise<any> {
    return this.apiService.activateLogin(confirm)
      .then((res: TokenModel) => {
        if (res.account === null || !res.account) {
          return res;
        }

        this.initUser(res);
        return res;
      });
  }

  /**
   * Инициализациия данных пользователя.
   */
  initUser(auth: TokenModel) {
    this.userService.setAccount(auth.account);
    this.userService.setUserInfo(auth.user_info);
    this.userService.setInsuranceType('');
    this.userService.setTokenModel(auth);


    this.hasPendingPdp = auth.has_pending_pdp;
    this.userBrands = auth.all_brands;

    localStorage.setItem('rn_user_active', 'true');
    localStorage.setItem('rn_user_id', auth.user_info.CLIENT);
  }

  set hasPendingPdp(status: boolean) {
    this.$hasPendingPdp.next(status);
  }

  get hasPendingPdp() {
    return this.$hasPendingPdp.value;
  }

  /**
   * Обращение к апи активации аккаунта.
   * @param {string} login логин пользователя.
   * @returns {Promise<any>} Promise с номером телефона для этого логина.
   */
  init(login: string): Promise<any> {
    this.client = login;
    this.initApiHost();

    return this.apiService.init(login, this.deviceCookie)
      // required_features: environment.required_features,
      .then((res: {device_cookie: string}) => {
        if ('device_cookie' in res) {
          this.setDeviceCookie(res.device_cookie);
        }

        return res;
      })
      .catch(err => {
        return err;
      })
      .catch(this.onLoginError.bind(this));
  }

  /**
   * Сбрасываем логин в false и переводим на форму авторизации..
   * @param {boolean} redirectNeeded нужен ли редирект после сброса логина..
   * @param logoutNeeded
   * @returns {Promise<void>}
   */
  async logout(redirectNeeded = true, logoutNeeded = true): Promise<void> {
    if (logoutNeeded) {
      try {
        await this.apiService.logout();
      } catch (e) {
      }
    }

    const logoutPromiseStub = Promise.resolve(true);

    this.toggleLogin(false);

    if (redirectNeeded) {
      await this.redirectToLoginForm();
      this.compiler.clearCache();

      this.clearStorage();
    }

    await logoutPromiseStub;
  }

  /**
   * Обращение к Апи для установки пароля.
   * @param {string} password пароль, который будет установлен текущему пользователю.
   * @param token
   * @param toggleLogin
   * @returns {Promise<void>}
   */
  async setPassword(password: string, token: string, toggleLogin = true) {
    return await this.apiService.setPassword(password, token)
      .then(() => toggleLogin ? this.toggleLogin(true) : true);
  }

  /**
   * Запись токена в local storage для того, чтобы после обновления страницы не выбивало на форму логина.
   * @param {string} newToken новый токен.
   */
  setToken(newToken: string) {
    localStorage.setItem('rn_token', newToken);
  }

  /**
   * Получение токена из local storage.
   * @returns {string} токен авторизации.
   */
  getToken(): string {
    const token = localStorage.getItem('rn_token');

    if (token !== null && token !== 'null') {
      return localStorage.getItem('rn_token');
    }

    return this.tempToken;
  }

  /**
   * Переход к форме логина.
   */
  async redirectToLoginForm() {
    await this.router.navigate(['/auth']);
  }

  set lastStep(step: 'activation' | 'authorization' | string) {
    localStorage.setItem('rn_auth_step', step);
  }

  get lastStep(): 'activation' | 'authorization' | string {
    return localStorage.getItem('rn_auth_step');
  }

  setDeviceCookie(deviceCookie: string) {
    localStorage.setItem('rn_device_cookie', deviceCookie);
  }

  get deviceCookie(): string {
    return localStorage.getItem('rn_device_cookie');
  }

  private clearStorage() {
    Object.keys(localStorage)
      .filter(key => this._preventRemovedKeys.indexOf(key) > 1)
      .forEach(key => localStorage.removeItem(key));
  }

  async getServerConstants() {
    const constants = await this.apiService.getServerConstants();
    this.serverConstants = constants.values;
  }

  get serverConstants() {
    return this._serverConstants
  }

  set serverConstants(values: ServerConstantsValuesModel) {
    this._serverConstants = values;
  }
}
