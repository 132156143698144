import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CustomSelectOptionModel } from '../../models/custom-select-option.model';

@Component({
  selector: 'app-custom-select',
  templateUrl: './custom-select.component.html',
  styleUrls: ['./custom-select.component.scss']
})
export class CustomSelectComponent implements OnInit {


  // получает массив сущностей с пунктами селекта
  @Input() options: CustomSelectOptionModel[];
  // получает флаг о дизейбле селекта
  @Input() isDisabledSelect = false;
  // поле с выбранным по умолчанию значением
  @Input() selectedValue: any;

  // передает событие об изменении значении селекта
  @Output() changeEvent = new EventEmitter();
  // передает сущность выбранного пункта селекта
  @Output() selectEvent = new EventEmitter<CustomSelectOptionModel>();

  // выбранный пункт селекта
  selectedOption: CustomSelectOptionModel;
  // флаг отображения списка селекта
  isShowList = false;

  constructor() { }

  ngOnInit() {
    this.initSelect();
  }

  initSelect() {
    this.options.forEach((item) => {
      if (this.selectedValue) {
        if (item.value === this.selectedValue) {
          item.isSelected = true;
          this.selectedOption = item;
        }
      } else if (item.isSelected) {
        this.selectedOption = item;
      }
    });
  }

  selectOption(option: CustomSelectOptionModel) {
    this.selectedOption = option;

    this.options.forEach((item) => {
      item.isSelected = false;
    });

    option.isSelected = true;

    this.changeEvent.emit();
    this.selectEvent.emit(option);

    this.isShowList = false;
  }

  toggleSelectList() {
    if (!this.isDisabledSelect) {
      this.isShowList = !this.isShowList;
    }
  }

}
